import type { AnyProperty } from '@/utilities.types';
import type { TableColumns } from '@/vantage/types/TableConfig.types';

export const findStreamIDs = (columnsJSON: TableColumns): string[] => {
  const streamIDs: string[] = [];
  Object.keys(columnsJSON).forEach((columnName, index) => {
    if ((columnsJSON as AnyProperty)[columnName]?.meta?.type.toString().toLowerCase() === 'stream_id') {
      // keep the name of the stream_id column
      streamIDs.push(columnName);
    }
  });

  return streamIDs;
};

export const findStreams = (columnsJSON: TableColumns, streamIDs: string[]): string[] => {
  const streams: string[] = [];
  Object.keys(columnsJSON).forEach((columnName, index) => {
    const column = (columnsJSON as AnyProperty)[columnName];
    if (column?.meta?.type.toString().toLowerCase() === 'reference') {
      if (streamIDs.includes(column?.meta?.referenced)) {
        // keep the name of the condition/steam column that references stream_id
        streams.push(columnName);
      }
    }
  });

  return streams;
};
