import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';
import { sqDurationStore } from '@/core/core.stores';
import _ from 'lodash';
import { AnyProperty } from '@/utilities.types';
import { Capsule } from '@/utilities/datetime.constants';

export class PartialLeastSquaresStore extends BaseToolStore {
  static readonly storeName = 'sqPartialLeastSquaresStore';
  type = TREND_TOOLS.PARTIAL_LEAST_SQUARES;
  parameterDefinitions = {
    condition: {
      predicate: ['name', 'condition'],
    },
    restrictToCondition: {
      predicate: ['name', 'restrictToCondition'],
    },
    targetSignal: { predicate: ['name', 'target'] },
    inputSignals: {
      predicate: (parameter: { name: string }) =>
        !_.includes(['target', 'condition', 'restrictToCondition'], parameter.name),
      multiple: true,
    },
  };

  initialize() {
    this.state = this.immutable({
      ...BASE_TOOL_COMMON_PROPS,
      windowStart: sqDurationStore.displayRange.start.valueOf(),
      windowEnd: sqDurationStore.displayRange.end.valueOf(),
      window: this.monkey(['windowStart'], ['windowEnd'], (startTime, endTime) => ({ startTime, endTime })),
      modelPropertiesExpanded: false,
      color: '',
      numComponents: 1,
      scale: true,
      maxIterations: 500,
      tolerance: 1e-6,
    });
  }

  get inputSignals() {
    return this.state.get('inputSignals');
  }

  get targetSignal() {
    return this.state.get('targetSignal');
  }

  get condition() {
    return this.state.get('condition');
  }

  get restrictToCondition() {
    return this.state.get('restrictToCondition');
  }

  get window() {
    return this.state.get('window');
  }

  get color(): string {
    return this.state.get('color');
  }

  get modelPropertiesExpanded(): boolean {
    return this.state.get('modelPropertiesExpanded');
  }

  get numComponents(): number {
    return this.state.get('numComponents');
  }

  get scale(): boolean {
    return this.state.get('scale');
  }

  get maxIterations(): number {
    return this.state.get('maxIterations');
  }

  get tolerance(): number {
    return this.state.get('tolerance');
  }

  dehydrate() {
    return this.state.serialize();
  }

  rehydrate(dehydratedState: AnyProperty) {
    this.state.merge(dehydratedState);
  }

  setTrainingWindow(payload: { window: Capsule }) {
    this.state.set('windowStart', _.get(payload.window, 'startTime'));
    this.state.set('windowEnd', _.get(payload.window, 'endTime'));
  }

  setModelPropertiesExpanded(payload: { modelPropertiesExpanded: boolean }) {
    this.state.set('modelPropertiesExpanded', payload.modelPropertiesExpanded);
  }

  setScale(payload: { scale: boolean }) {
    this.state.set('scale', payload.scale);
  }

  setMaxIterations(payload: { maxIterations: number }) {
    this.state.set('maxIterations', payload.maxIterations);
  }

  setNumComponents(payload: { numComponents: number }) {
    this.state.set('numComponents', payload.numComponents);
  }

  setTolerance(payload: { tolerance: number }) {
    this.state.set('tolerance', payload.tolerance);
  }

  setColor(payload: { color: string }) {
    this.state.set('color', payload.color);
  }

  modifyConfigParams(config: any) {
    return _.omit(config, ['modelPropertiesExpanded']);
  }

  protected readonly handlers = {
    ...this.baseHandlers,
    PLS_SET_TRAINING_WINDOW: this.setTrainingWindow,
    PLS_SET_MODEL_PROPERTIES_EXPANDED: this.setModelPropertiesExpanded,
    PLS_SET_NUM_COMPONENTS: this.setNumComponents,
    PLS_SET_SCALE: this.setScale,
    PLS_SET_MAX_ITERATIONS: this.setMaxIterations,
    PLS_SET_TOLERANCE: this.setTolerance,
    PLS_SET_COLOR: this.setColor,
  };
}
