import { DatasourcesStatusOutputV1 } from '@/sdk/model/DatasourcesStatusOutputV1';
import { PersistenceLevel, Store } from '@/core/flux.service';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';

export class DatasourcesStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqDatasourcesStore';

  initialize() {
    this.state = this.immutable({
      datasources: undefined,
    });
  }

  get datasources(): DatasourcesStatusOutputV1 {
    return this.state.get('datasources');
  }

  protected readonly handlers = {
    DATASOURCES_SET_DATASOURCES: ({ datasources }: { datasources: DatasourcesStatusOutputV1 }) => {
      this.state.set('datasources', datasources);
    },
  };
}

export class AdvancedPropertyStore extends BaseToolStore {
  static readonly storeName = 'sqConfToolStore';
  initialize() {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state = this.immutable({ ...BASE_TOOL_COMMON_PROPS });
  }
}
