import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core/dist/types/src/rendering/cellRenderers/iCellRenderer';

export interface UserNameCellProps {
  first: string;
  last: string;
}

/**
 * This cell-renderer renders a circle with the first letter of the first and last name of the user as well as the
 * user's name
 */
export const UserNameCellRenderer: React.FunctionComponent<ICellRendererParams<any, UserNameCellProps>> = (props) => {
  if (!props?.value) return null;
  const { first, last } = props.value;
  return (
    <div className="flexColumnContainer flexAlignCenter">
      <div className="userNameCircle flexColumnContainer flexCenter mr5">
        {first?.substring(0, 1)}
        {last?.substring(0, 1)}
      </div>
      {first && first} {last && last}
    </div>
  );
};
