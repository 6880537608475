import React, { useCallback, useRef, useState } from 'react';
import { Icon, Modal, Tabs } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';
import { useDidMount } from 'rooks';
import { IssueSummaryOutputV1, ReleaseNotesOutputV1, sqSystemApi } from '@/sdk';
import { ColDef, ColumnVisibleEvent, ICellRendererParams } from '@ag-grid-community/core';
import { AgGridAsync } from '@/core/tableUtilities/AgGridAsync';
import { IssueTypeEnum } from '@/sdk/model/IssueSummaryOutputV1';
import { AgGridModules } from '@/core/tableUtilities/tableUtilities.types';
import _ from 'lodash';
import { formatTime } from '@/datetime/dateTime.utilities';
import { sqWorkbenchStore } from '@/core/core.stores';
import { logError } from '@/utilities/logger';

interface ReleaseNotesModalProps {
  onClose: () => void;
}

enum ReleaseNotesTab {
  UPCOMING = 'upcoming',
  CURRENT = 'current',
  PAST = 'past',
}

const TAB_TRANSLATION_KEYS = {
  [ReleaseNotesTab.UPCOMING]: 'RELEASE_NOTES.UPCOMING',
  [ReleaseNotesTab.CURRENT]: 'RELEASE_NOTES.CURRENT',
  [ReleaseNotesTab.PAST]: 'RELEASE_NOTES.PAST',
};

export const ReleaseNotesModal: React.FunctionComponent<ReleaseNotesModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNotesOutputV1 | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<ReleaseNotesTab>(ReleaseNotesTab.CURRENT);
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const columnDefs = useRef<ColDef[]>([
    {
      field: 'key',
      headerName: t('RELEASE_NOTES.ISSUE'),
      width: 100,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
    },
    {
      field: 'issueType',
      headerName: t('RELEASE_NOTES.TYPE'),
      valueGetter: ({ data }) =>
        data.issueType === IssueTypeEnum.FEATURE ? t('RELEASE_NOTES.FEATURE') : t('RELEASE_NOTES.BUGFIX'),
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value === t('RELEASE_NOTES.FEATURE') ? (
          <>
            <Icon icon="fa-book-sparkles" type="success" /> {value}
          </>
        ) : (
          <>
            <Icon icon="fa-bug" type="danger" /> {value}
          </>
        );
      },
      width: 150,
      filter: 'agSetColumnFilter',
      floatingFilter: true,
    },
    {
      field: 'summary',
      headerName: t('RELEASE_NOTES.SUMMARY'),
      flex: 1,
      minWidth: 250,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
    },
    {
      field: 'installedOn',
      headerName: t('RELEASE_NOTES.INSTALLED_ON'),
      width: 200,
      filter: false,
      hide: true,
    },
    {
      field: 'version',
      headerName: t('RELEASE_NOTES.VERSION'),
      width: 200,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      hide: true,
    },
  ]);

  useDidMount(async () => {
    setIsFetching(true);
    setErrorMessage('');
    try {
      const { data } = await sqSystemApi.getReleaseNotes();
      setReleaseNotes(data);
    } catch (e) {
      logError(e as any);
      setErrorMessage('RELEASE_NOTES.ERROR_FETCHING');
    } finally {
      setIsFetching(false);
    }
  });

  const syncColumnDefs = useCallback((event: ColumnVisibleEvent) => {
    columnDefs.current = columnDefs.current.map((column) =>
      column.field === (event.column! as any).colId ? { ...column, hide: !event.visible } : column,
    );
  }, []);

  const getReleaseNotesForTab = (tab: ReleaseNotesTab): IssueSummaryOutputV1[] => {
    if (!releaseNotes) return [];
    switch (tab) {
      case ReleaseNotesTab.UPCOMING:
        return releaseNotes.upcomingReleaseNotes;
      case ReleaseNotesTab.CURRENT:
        return releaseNotes.currentReleaseNotes;
      case ReleaseNotesTab.PAST:
        return releaseNotes.pastReleaseNotes;
    }
  };

  return (
    <Modal
      open={true}
      size="6xl"
      onClose={onClose}
      onPointerDownOutside={onClose}
      testId="releaseNotesModal"
      title={t('RELEASE_NOTES.TITLE')}
      titleIcon="fa-memo-circle-info"
      hideFooterButtons={true}>
      <div className="p24 height-800 flexFill flexRowContainer">
        <Tabs
          testId="releaseNotesTabs"
          extraClassNames="width-maximum"
          activeTab={activeTab}
          onTabSelect={(tab: string) => {
            setActiveTab(tab as ReleaseNotesTab);
          }}
          tabs={Object.values(ReleaseNotesTab)
            .filter(
              (tab) =>
                tab === ReleaseNotesTab.CURRENT ||
                (tab === ReleaseNotesTab.PAST && !_.isEmpty(getReleaseNotesForTab(tab))) ||
                (tab === ReleaseNotesTab.UPCOMING && !_.isNil(releaseNotes?.nextUpgradeTime)),
            )
            .map((tab) => ({
              id: tab,
              testId: `${tab}ReleaseNotesTab`,
              label: t(TAB_TRANSLATION_KEYS[tab]),
              tabContentExtraClassNames: 'link-no-underline tw-h-full',
              tabExtraClassNames: 'link-no-underline',
              content: isFetching ? (
                <div data-testid="loadingIndicator" className="flexRowContainer flexCenter pt50 pb50">
                  <Icon icon="fa-spinner" iconPrefix="fa-solid fa-5x" extraClassNames="fa-spin-pulse" />
                  <h3>{t('RELEASE_NOTES.LOADING')}</h3>
                </div>
              ) : (
                <>
                  {tab === ReleaseNotesTab.UPCOMING ? (
                    <div className="alert alert-info mt10 mb0">
                      {t('RELEASE_NOTES.UPCOMING_NOTE')}{' '}
                      {t('RELEASE_NOTES.UPCOMING_MAINTENANCE', {
                        nextUpgradeTime: formatTime(releaseNotes!.nextUpgradeTime, sqWorkbenchStore.timezone),
                        nextVersion: releaseNotes!.nextVersion,
                      })}
                    </div>
                  ) : null}
                  {errorMessage && <div className="alert alert-danger mt10 mb0">{t(errorMessage)}</div>}
                  {tab === ReleaseNotesTab.CURRENT && getReleaseNotesForTab(tab).length === 0 ? (
                    <div className="alert alert-info mt10 mb0">{t('RELEASE_NOTES.NONE_AVAILABLE')}</div>
                  ) : null}
                  <div
                    className="ag-theme-quartz width-maximum overflowAuto flexFillOverflow mt10"
                    data-testid="releaseNotesTable">
                    <AgGridAsync
                      modules={[
                        AgGridModules.SetFilterModule,
                        AgGridModules.MenuModule,
                        AgGridModules.ColumnsToolPanelModule,
                        AgGridModules.ClipboardModule,
                        AgGridModules.RangeSelectionModule,
                      ]}
                      columnDefs={columnDefs.current}
                      rowData={getReleaseNotesForTab(tab)}
                      accentedSort={true}
                      enableRangeSelection={true}
                      rowSelection="multiple"
                      copyHeadersToClipboard={true}
                      onColumnVisible={syncColumnDefs}
                    />
                  </div>
                </>
              ),
            }))}
        />
      </div>
    </Modal>
  );
};
