import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core/dist/types/src/rendering/cellRenderers/iCellRenderer';
import { Icon } from '@seeqdev/qomponents';

export interface UseCaseNameCellProps {
  name: string;
  worksheetId: string;
  isReportBinder: boolean;
}

/**
 * This cell-renderer renders a little "bubble" icon next to the use case name to indicate there is more to see and
 * encourage users to click on the cell to see more details.
 * It also includes the workbench icon that links to the associated worksheet in view mode.
 */
export const UseCaseNameCellRenderer: React.FunctionComponent<ICellRendererParams<any, UseCaseNameCellProps>> = (
  props,
) => {
  if (!props?.value) return null;
  const { value } = props;
  if (!value) return null;

  const handleClick = () => {
    window.open(`/view/${value.worksheetId}`, '_blank');
  };

  return value.name ? (
    <div className="flexColumnContainer flexAlignCenter">
      <div className="flexFill">
        <Icon
          icon={value.isReportBinder ? 'fc-report' : 'fc-analysis'}
          onClick={handleClick}
          extraClassNames="workbookIcon workbenchDisplay mr5"
        />
        {value.name}
      </div>
      <Icon icon="fa-comment-dots" extraClassNames="ml5 detailsBubble" />
    </div>
  ) : null;
};
