import React, { forwardRef } from 'react';
import { FormBuilder, FormBuilderProps } from '@/formbuilder/FormBuilder.page';

type SaveAndContinueFormBuilderProps = FormBuilderProps & {
  extraClassNames?: string;
};

/**
 * SaveAndContinueFormBuilder generates forms based on the provided form definition Array and supports a "Wizard"
 * like user experience.
 * In addition to a Save and Cancel button, this component also includes a Continue button that when clicked submits
 * that form but does not close it.
 **/
export const SaveAndContinueFormBuilder: React.FunctionComponent<SaveAndContinueFormBuilderProps> = forwardRef(
  function SaveAndContinueFormBuilder(props, ref) {
    return <FormBuilder {...props} ref={ref} saveAndCancel={true} extraClassNamesUnwrapped={props.extraClassNames} />;
  },
);
