import { flux } from '@/core/flux.module';
import { ValueCaptureCategory, ValueCaptureDashboardContent } from '@/valueCapture/valueCapture.types';
import { PUSH_WORKBENCH } from '@/core/flux.service';

export function setValueCaptureTableId(tableId: string) {
  flux.dispatch('VALUE_CAPTURE_SET_TABLE_ID', { tableId });
}

export function setValueCaptureCategories(categories: ValueCaptureCategory[] | undefined) {
  flux.dispatch('VALUE_CAPTURE_SET_CATEGORIES', { categories });
}

export function setValueCaptureStartDate(startDate: Date) {
  flux.dispatch('VALUE_CAPTURE_SET_START_DATE', { startDate });
}

export function setValueCaptureEndDate(endDate: Date) {
  flux.dispatch('VALUE_CAPTURE_SET_END_DATE', { endDate });
}

export function setValueCaptureDateRange(startDate: Date, endDate: Date) {
  setValueCaptureStartDate(startDate);
  setValueCaptureEndDate(endDate);
}

export function setValueCaptureLayoutDefinition(layoutDefinition: ValueCaptureDashboardContent[]) {
  flux.dispatch('VALUE_CAPTURE_SET_LAYOUT_DEFINITION', { layoutDefinition }, PUSH_WORKBENCH);
}

export function removeValueCaptureContent(i: string) {
  flux.dispatch('VALUE_CAPTURE_REMOVE_CONTENT', { i }, PUSH_WORKBENCH);
}

export function setForceTableReloadFlag(forceTableReload: boolean) {
  flux.dispatch('VALUE_CAPTURE_SET_FORCE_TABLE_RELOAD', { forceTableReload });
}

export function setAggregationColumn(aggregationColumn: string | undefined) {
  flux.dispatch('VALUE_CAPTURE_SET_AGGREGATION_COLUMN', { aggregationColumn });
}

export function setBiggestImpact(biggestImpact: number) {
  flux.dispatch('VALUE_CAPTURE_SET_BIGGEST_IMPACT', { biggestImpact });
}

export function setUseCaseCount(useCaseCount: number) {
  flux.dispatch('VALUE_CAPTURE_SET_USE_CASE_COUNT', { useCaseCount });
}

export function setOverallImpact(overallImpact: number) {
  flux.dispatch('VALUE_CAPTURE_SET_OVERALL_IMPACT', { overallImpact });
}

export function setFilteredImpact(filteredImpact: number) {
  flux.dispatch('VALUE_CAPTURE_SET_FILTERED_IMPACT', { filteredImpact });
}
