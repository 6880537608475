import React from 'react';
import { Field, useForm } from 'react-final-form';
import _ from 'lodash';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { DurationSelector, DurationValuesByUnit } from '@/core/DurationSelector.atom';
import { DurationTimeUnit } from '@/main/app.constants';

export interface DurationTimeUnitValue {
  unit: DurationTimeUnit;
  value: number;
}

export interface DurationFormComponentProps extends ValidatingFormComponent<DurationTimeUnitValue> {
  component: 'DurationFormComponent';
  durationValuesByUnit?: DurationValuesByUnit;
  isCreatable?: boolean;
}

const defaultDurationValuesByUnit: DurationValuesByUnit = {};

export const DurationFormComponent: React.FunctionComponent<DurationFormComponentProps> = (props) => {
  const {
    name,
    testId = 'duration',
    validation,
    extendValidation,
    customErrorText,
    required = false,
    durationValuesByUnit = defaultDurationValuesByUnit,
    isCreatable,
    warningText,
    label,
  } = props;

  const formState = useForm().getState();
  const showError = _.has(formState.errors, name) && formState.submitFailed && formState.hasValidationErrors;

  const defaultValidation = (value: DurationTimeUnitValue | undefined) => required && (!value?.value || !value?.unit);
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  return (
    <FormFieldWrapper
      label={label}
      wrapperClassNames="flexFill mb20"
      testId={testId}
      showError={showError}
      warningText={warningText}
      customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const { onChange, value } = getFormFieldProps(formState, input, meta, props);

          return (
            <DurationSelector
              unit={value.unit}
              value={value.value}
              onChange={(value, unit) => onChange({ value, unit })}
              onInvalidInput={_.noop}
              durationValuesByUnit={durationValuesByUnit}
              isCreatable={isCreatable}
            />
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
