import { PersistenceLevel, Store } from '@/core/flux.service';
import type { AnyProperty } from '@/utilities.types';
import type { InstanceConfig, TableColumns, TableConfig } from '@/vantage/types/TableConfig.types';
import type { ExtensionSchema } from '@/vantage/types/Extension.types';
import type { SeeqUser } from '@/vantage/utilities';
import { TableDefinitionOutputV1 } from '@/sdk/model/TableDefinitionOutputV1';
import { getTableColumns } from '@/vantage/vantageWorkbook.utilities';
import { findStreamIDs, findStreams } from '@/vantage/components/evidence_table/helpers/streamFinders';

export class VantageWorkbookStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqVantageWorkbook';

  initialize(): void {
    this.state = this.immutable({
      workbookConfig: undefined,
      user: undefined,
      evidenceTableDefinition: undefined,
      tableColumns: this.monkey(
        ['evidenceTableDefinition'],
        ['workbookConfig'],
        (evidenceTableDefinition: TableDefinitionOutputV1 | undefined, workbookConfig: InstanceConfig | undefined) => {
          if (!evidenceTableDefinition || !workbookConfig) {
            return;
          }

          return getTableColumns(evidenceTableDefinition?.columnDefinitions, workbookConfig?.extensions);
        },
      ),
      streamsList: this.monkey(['tableColumns'], (tableColumns: TableColumns | undefined) =>
        !tableColumns ? undefined : findStreams(tableColumns, findStreamIDs(tableColumns)),
      ),
    });
  }

  get workbookConfig(): InstanceConfig | undefined {
    return this.state.get('workbookConfig');
  }

  get extensions(): ExtensionSchema[] | undefined {
    return this.workbookConfig?.extensions;
  }

  get tableConfig(): TableConfig | undefined {
    return this.workbookConfig?.tableConfig;
  }

  get tableIds(): AnyProperty<string> | undefined {
    return this.workbookConfig?.tableIds;
  }

  get workbookId(): string | undefined {
    return this.workbookConfig?.workbookId;
  }

  get user(): SeeqUser | undefined {
    return this.state.get('user');
  }

  get evidenceTableDefinition(): TableDefinitionOutputV1 | undefined {
    return this.state.get('evidenceTableDefinition');
  }

  get tableColumns(): TableColumns | undefined {
    return this.state.get('tableColumns');
  }

  get streamsList(): string[] | undefined {
    return this.state.get('streamsList');
  }

  protected readonly handlers = {
    SET_WORKBOOK_CONFIG: (payload: { workbookConfig: InstanceConfig }) =>
      this.state.set('workbookConfig', payload.workbookConfig),
    SET_USER: (payload: { user: SeeqUser }) => this.state.set('user', payload.user),
    SET_EVIDENCE_TABLE_DEFINITION: (payload: { evidenceTableDefinition: TableDefinitionOutputV1 }) =>
      this.state.set('evidenceTableDefinition', payload.evidenceTableDefinition),
    SET_TABLE_COLUMNS: (payload: { tableColumns: TableColumns }) =>
      this.state.set('tableColumns', payload.tableColumns),
  };
}
