import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { ButtonWithDropdown, Icon } from '@seeqdev/qomponents';
import { sqValueCaptureStore } from '@/core/core.stores';
import { useNavigate, useParams } from 'react-router-dom';
import { ValueCaptureEditModal } from '@/valueCapture/ValueCaptureEditModal.page';
import { getValueCaptureTableId } from '@/valueCapture/valueCapture.utilities';
import { useAsyncEffect } from 'rooks';
import { getImpactReportByWorksheet } from '@/valueCapture/tiles/table.utilities';
import { setForceTableReloadFlag } from '@/valueCapture/valueCapture.actions';
import { AnyProperty } from '@/utilities.types';
import { ValueCaptureDetailModal } from '@/valueCapture/ValueCaptureDetailModal.page';
import { TRACK_IMPACT, ValueCaptureUseCase } from '@/valueCapture/valueCapture.types';
import { doTrack } from '@/track/track.service';

export const ValueCaptureMenu: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { workbookId, worksheetId } = useParams();
  const { t } = useTranslation();
  const [existingReport, setExistingReport] = useState<AnyProperty[] | undefined>(undefined);
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [displayDetailsModal, setDisplayDetailsModal] = useState(false);

  useAsyncEffect(async () => {
    if (displayEditModal) return;
    if (workbookId && worksheetId) {
      let tableId = sqValueCaptureStore.tableId;
      if (!tableId) {
        tableId = await getValueCaptureTableId();
      }
      if (!tableId) return;
      const data = await getImpactReportByWorksheet(tableId, worksheetId, workbookId);
      setExistingReport(data);
    } else {
      setExistingReport(undefined);
    }
  }, [workbookId, worksheetId, displayEditModal]);

  const actions = [
    {
      href: '#',
      iconClass: 'fa-pencil',
      action: () => {
        // if an impact reports exists we open the detail modal, if not we open the edit modal to create a new one
        if (existingReport) {
          doTrack(TRACK_IMPACT, 'View existing Impact Report', 'from Toolbar menu');
          setDisplayDetailsModal(true);
        } else {
          setDisplayEditModal(true);
          doTrack(TRACK_IMPACT, 'Create new Impact Report', 'from Toolbar menu');
        }
      },
      translationKey: existingReport ? 'VALUE_CAPTURE.VIEW' : 'VALUE_CAPTURE.NEW',
    },
    {
      href: '#',
      iconClass: 'fa-lightbulb-dollar',
      action: () => navigate('/value'),
      translationKey: 'VALUE_CAPTURE.VIEW_ALL',
    },
  ];

  const generateDropdownEntry = ({ iconClass = '', translationKey = '', action = _.noop, testId = '' }) => {
    return {
      key: `dropdown_${translationKey}`,
      icon: iconClass,
      containerExtraClassNames: 'sq-force-text-gray forceFont',
      labelTestId: `moreActions_${translationKey}`,
      iconTestId: `moreActionsIcon_${translationKey}`,
      onClick: action,
      label: t(translationKey),
      testId,
    };
  };

  return (
    <>
      <ButtonWithDropdown
        id="valueCaptureMenu"
        keepFocusInsideDropdown={false}
        triggerIcon={
          <Icon
            icon={existingReport ? 'fa-star' : 'fa-lightbulb-dollar'}
            extraClassNames="fa-xlg sq-navbar-default flexNoGrowNoShrink mb1"
            type="white"
            testId="valueCaptureMenu"
            // iconPrefix={existingReport ? 'fa-solid' : ''}
          />
        }
        dropdownItems={actions.map((action) => generateDropdownEntry(action))}
      />
      {displayDetailsModal && (
        <ValueCaptureDetailModal
          closeFn={() => setDisplayDetailsModal(false)}
          details={existingReport as unknown as ValueCaptureUseCase}
          editCallbackFn={() => {
            setDisplayDetailsModal(false);
            setDisplayEditModal(true);
          }}
        />
      )}
      {displayEditModal && (
        <ValueCaptureEditModal
          existingReport={existingReport}
          closeFn={(reload = false) => {
            if (reload && window.location.pathname === '/value') {
              setForceTableReloadFlag(true);
            }
            setDisplayEditModal(false);
          }}
        />
      )}
    </>
  );
};
