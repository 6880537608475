import { SeeqNames } from '@/main/app.constants.seeqnames';
import { VantageNames } from '@/vantage/constants';

export const coreColumns = [
  [VantageNames.Columns.StreamID, 'UUID', SeeqNames.MaterializedTables.ItemIdColumn],
  [VantageNames.Columns.Condition, 'text', VantageNames.Columns.Condition],
  [VantageNames.Columns.DatumID, 'text', SeeqNames.MaterializedTables.DatumIdColumn],
  ['Time Created', 'datetime', 'created at'],
  ['Time Updated', 'datetime', VantageNames.Columns.UpdatedAt],
];

export const excludedColumns = [
  SeeqNames.MaterializedTables.ItemIdColumn,
  SeeqNames.MaterializedTables.DatumIdColumn,
  VantageNames.Columns.DeletedAt,
  VantageNames.Columns.Kind,
  VantageNames.Columns.Mutable,
  VantageNames.Columns.Properties,
];
