import { TableColumnFilter } from '@/core/tableUtilities/tables';
import { API_TYPES } from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { Range } from '@/reportEditor/report.constants';
import { TableColumnOutputV1 } from '@/sdk/model/TableColumnOutputV1';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';
import { Moment } from 'moment';

export type ParametersMap = Record<string, string | number | any>;

export interface RunFormulaInput {
  /**
   * Time range over which to request the data. Not allowed for when typeKey is SCALAR. Start and end of the range.
   * If a moment object it is converted to a UTC timestamp, otherwise it is assumed to be a number not in the time
   * domain.
   */
  range?: { start: number | string | Moment; end: number | Moment | string };
  /**
   * ID of the item on which to run the formula. It should be referenced using $series.
   * May also be the ID of a formula function, in which case the fragments property should contain formula fragments
   * for any unbound parameters.
   */
  id?: string;
  /**
   * A formula fragment object where the keys are the names of unbound formula function variables and the values are
   * the corresponding formula fragments that are used to compute the value of the variable.
   */
  fragments?: Record<string, string>;
  /**
   * Specifies the parameters that the formula can reference.
   */
  parameters?: ParametersMap;
  /**
   * The formula to pass to the Calculation Engine. If not provided the formula will just return the contents of the
   * item without any transformations.
   */
  formula?: string;
  /**
   * Used to run a formula across assets. The ID of the root asset.
   */
  root?: string;
  /**
   * Used when running a formula across assets, a formula that can further reduce the results of each asset result.
   */
  reduceFormula?: string;
  /**
   * A group name that can be used to cancel the requests
   */
  cancellationGroup?: string;
  /**
   * Used to limit the number of results returned
   */
  limit?: number;
  offset?: number;
  /**
   * Used to return only rows with distinct values in a particular column
   */
  distinct?: string;
  /**
   * If true, use POST /formula/run instead of GET /formula/run
   */
  usePost?: boolean;
}

export const FORMULA_RETURN_TYPES = {
  SAMPLE_GROUP: 'Group:Sample',
  SAMPLE_SERIES: 'Signal',
  SAMPLE_AND_STATS: 'SignalAndStats',
  CAPSULE_SERIES: 'Condition',
  CAPSULE: 'Capsule',
  SCALAR: 'Scalar',
  PREDICTION_TABLE: 'RegressionModel',
  TABLE: 'Table',
};

export const FORMULA_RETURN_FIELDS = {
  SAMPLE_GROUP: 'samples',
  SAMPLE_SERIES: 'samples',
  SAMPLE_AND_STATS: ['samples', 'table', 'valueUnitOfMeasure', 'interpolationMethod'],
  CAPSULE_SERIES: 'capsules',
  CAPSULE: 'capsules',
  SCALAR: 'scalar',
  PREDICTION_TABLE: ['regressionOutput', 'table'],
  TABLE: 'table',
};

// The backend's spikecatcher algorithm that is used to downsample the values can return up to 6 data points for
// each pixel. An alternative to hardcoding 6 would be to paginate through the values if more than the limit were
// present, but the downside would be the extra code and requests.
export const SPIKECATCHER_PER_PIXEL = 6;
export const XY_TABLE_PER_PIXEL = 8;

/**
 * describes the sort order(s) for sorting a table using the sort() Formula
 */
export interface TableSortParams {
  /** The key for the primary column to be sorted on */
  sortBy: string;
  /** True to sort the primary column in ascending order, and false to sort in descending order */
  sortAsc: boolean;
  /** Additional <sortBy, sortAsc> pairs, which describe the sort order for any additional columns to sort by. These
   *  should be given in the desired order of sorting.  */
  orderedAdditionalSortPairs?: { sortBy: string; sortAsc: boolean }[];
  /** True if it is a custom column added by a formula snippet */
  isCustomColumn?: boolean;
}

// This is a function because consumers of it add items to the sort pairs array which would modify the original
export const DEFAULT_CONDITION_TABLE_SORT: () => TableSortParams = () => ({
  sortBy: COLUMNS_AND_STATS.startTime.key,
  sortAsc: true,
  orderedAdditionalSortPairs: [],
});

/**
 * Describes a column in a table that is a property of the capsule. Ex: 'Start', 'Reference Capsule',
 * 'Duration', etc.
 */
export interface PropertyColumn extends BackwardsCompatibilityColumn {
  /** key for the property. Ex: 'startTime' is key for Start, used for sorting */
  key: string;
  /** Name for the property, used for referring to the column in Formula input*/
  propertyName: string;
  /** filter for the property column (optional) */
  filter?: TableColumnFilter;
  sort?: ColumnSortCriteria;
}

export interface BackwardsCompatibilityColumn {
  /** Required for backwards compatibility in Capsule Pane, not needed for most uses. Used to force sort(), in
   *  Formula, to sort invalids first. */
  invalidsFirst?: boolean;
}

export interface ColumnSortCriteria {
  columnName: string;
  direction: 'asc' | 'desc';
  /** level represents the sort order */
  level: number;
}

/**
 * Describes a column in a table that is a statistic for a particular signal during a given capsule.
 */
export interface StatColumn extends BackwardsCompatibilityColumn {
  /** statistics key, Ex: statistics.range.{signalId} */
  key: string;
  /** Id for the signal that this statistic is being measured for */
  signalId: string;
  /** the stat Formula. Ex: range(), average(), endValue(true), etc*/
  stat: string;
  /** the column header string used in the backend to refer to a stat Operator. Required for sorting by stat columns. */
  columnSuffix: string;
  /** filter for the stat column (optional) */
  filter?: TableColumnFilter;
  sort?: ColumnSortCriteria;
  referenceSeries?: string;
}

export interface FetchParamsForColumn {
  fetchParams: any;
  columnKeyAndName: { columnKey: string; columnName: string };
}

export type CapsuleFormulaTableRow = {
  capsuleId: string;
  isUncertain: boolean;
  cursorKey: number | null;
  conditionId: string;
  startTime: number;
  endTime: number;
  isReferenceCapsule: boolean | null;
  similarity?: string;
} & { [key: `${'properties' | 'statistics'}.${string}`]: string | number };

/**
 * Represents a table that has been returned from the backend, constructed through Formula
 */
export interface FormulaTable<T = Record<string, string>> {
  /** contains the resulting table and headers*/
  data: {
    /** an array of tableRows where each tableRow consists of key/pair relationships
     * between a column key and the value for that column in the given tableRow */
    table: T[];
    /** the in-order column headers */
    headers: TableColumnOutputV1[];
    /** true if the computed table has more results than there are tableRows */
    hasNextPage: boolean;
    timingInformation: string;
    meterInformation: string;
    warningCount: number;
    warningLogs: [];
  };
}

export type CapsuleFormulaTable = FormulaTable<CapsuleFormulaTableRow>;

export type BuildAdditionalCapsuleTableFormulaCallback = (ids: string[], parameters: ParametersMap) => string;

export type BuildConditionFormulaCallback = (
  ids: string[],
  parameters: ParametersMap,
) => { formula: string; parameters: ParametersMap };

export type BuildStatFormulaCallback = (statColumns: StatColumn[], parameters: ParametersMap) => string;

export const NAME_SEARCH_TYPES = [
  API_TYPES.CALCULATED_CONDITION,
  API_TYPES.CALCULATED_SIGNAL,
  API_TYPES.CALCULATED_SCALAR,
  API_TYPES.TABLE,
  API_TYPES.THRESHOLD_METRIC,
  API_TYPES.DATAFILE,
  SeeqNames.Types.DisplayTemplate,
];

export interface ComputeCapsuleTableParams {
  columns: { propertyColumns: PropertyColumn[]; statColumns: StatColumn[] };
  range: Range;
  itemIds: string[];
  sortParams?: TableSortParams;
  offset: number;
  limit: number;
  buildAdditionalFormula?: BuildAdditionalCapsuleTableFormulaCallback;
  buildConditionFormula?: BuildConditionFormulaCallback;
  buildStatFormula?: BuildStatFormulaCallback;
  root?: undefined | string;
  reduceFormula?: undefined | string;
  cancellationGroup: string;
}
