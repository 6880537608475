import React, { useRef } from 'react';
import _ from 'lodash';
import { InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@seeqdev/qomponents';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { successToast } from '@/utilities/toast.utilities';

/**
 * Renders an input field with a button to copy the contents of the field to the clipboard.
 */
interface InputSelectOnFocusProps {
  /** an identifier to be used in tests */
  testId: string;
  /** true if the field should be read only, false otherwise */
  readOnly: boolean;
  /** string to display in the tooltip when hovering the input field */
  fieldTooltip: string;
  /** string to display in the tooltip when hovering the button */
  buttonTooltip: string;
  /** value to show in the input field */
  value?: string;
  /** notification message sent to user */
  notifyMessage?: string;
  extraButtons?: JSX.Element[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showError?: boolean;
  copyDisabled?: boolean;
  /** custom styling for the input field */
  extraClassNames?: string;
  /** custom styling for the copy button */
  buttonExtraClassNames?: string;
}

export const CopyableInputField: React.FunctionComponent<InputSelectOnFocusProps> = ({
  testId,
  readOnly,
  fieldTooltip,
  buttonTooltip,
  value,
  notifyMessage,
  extraButtons,
  onChange = _.noop,
  showError,
  copyDisabled,
  extraClassNames,
  buttonExtraClassNames,
}) => {
  const { t } = useTranslation();

  const inputValue = useRef<HTMLInputElement>(null);

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const copyToClipboard = () => {
    inputValue.current?.select();
    document.execCommand('copy');
    inputValue.current?.blur();
    if (window.getSelection) {
      window.getSelection?.()?.removeAllRanges();
    }

    if (!_.isNil(notifyMessage)) {
      successToast({ messageKey: notifyMessage });
    }
  };

  return (
    <InputGroup>
      <HoverTooltip text={fieldTooltip}>
        <TextField
          testId={testId}
          autoComplete="off"
          extraClassNames={`displayFlex tableWrapper ${value ? 'btrr0 bbrr0' : ''} ${extraClassNames}`}
          type="text"
          readonly={readOnly}
          ref={inputValue}
          value={value}
          onFocus={onFocus}
          onChange={onChange}
          showError={showError}
        />
      </HoverTooltip>
      {value && (
        <InputGroup.Append className="copyableInputGroup border-0 ml0">
          <InputGroup.Text className="border-0">
            {extraButtons}
            <Button
              label=""
              iconStyle="theme"
              icon="fa-copy"
              tooltip={t(buttonTooltip)}
              extraClassNames={`height-maximum ${buttonExtraClassNames}`}
              tooltipOptions={{ position: 'top' }}
              testId={`${testId}-copyButton`}
              onClick={copyToClipboard}
              disabled={copyDisabled}
            />
          </InputGroup.Text>
        </InputGroup.Append>
      )}
    </InputGroup>
  );
};
