import React from 'react';
import { IMPACT_OWNER, ValueCaptureUseCase } from '@/valueCapture/valueCapture.types';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqValueCaptureStore, sqWorkbenchStore } from '@/core/core.stores';
import { Modal } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';
import { ValueCaptureDetail, ValueCaptureDetailHeader } from '@/valueCapture/ValueCaptureDetail.page';
import { isAdmin } from '@/services/authorization.service';

interface ValueCaptureModalProps {
  closeFn: () => void;
  details: ValueCaptureUseCase;
  editCallbackFn?: () => void;
}

export const ValueCaptureDetailModal: React.FunctionComponent<ValueCaptureModalProps> = ({
  closeFn,
  details,
  editCallbackFn,
}) => {
  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);

  const { t } = useTranslation();
  const categories = useFluxPath(sqValueCaptureStore, () => sqValueCaptureStore.categories);

  // Edit and Delete is only shown for the owner of the impact or admin
  const getOnEditFunction = () => {
    if (details && editCallbackFn && (isAdmin() || details[IMPACT_OWNER] === currentUser.id)) {
      return editCallbackFn;
    }
    return null;
  };

  return (
    <Modal
      title={t('VALUE_CAPTURE.DETAIL')}
      size="4xl"
      submitButtonLabel={t('EDIT')}
      cancelButtonLabel={t('CLOSE')}
      hideFooterButtons={true}
      open={true}
      onSubmit={editCallbackFn ? () => editCallbackFn() : undefined}
      onClose={closeFn}
      customHeader={<ValueCaptureDetailHeader {...details} />}>
      <ValueCaptureDetail
        {...details}
        categories={categories}
        onClose={closeFn}
        onEdit={getOnEditFunction() ?? undefined}
      />
    </Modal>
  );
};
