// @ts-strict-ignore
import _ from 'lodash';
import { PersistenceLevel, Store } from '@/core/flux.service';
import {
  DefaultMargin,
  DOCUMENT_LAYOUT,
  DOCUMENT_PAPER_SIZE,
  DocumentMarginUnits,
  Margin,
  PageMargins,
} from '@/reportEditor/report.constants';
import { AnnotationOutputV1 } from '@/sdk';
import { errorToast } from '@/utilities/toast.utilities';

export class PdfExportStore extends Store {
  initialize() {
    this.state = this.immutable({
      showModal: false,
      layout: DOCUMENT_LAYOUT[0], // Portrait
      paperSize: DOCUMENT_PAPER_SIZE[0], // Letter
      marginLeft: { ...DefaultMargin },
      marginRight: { ...DefaultMargin },
      marginTop: { ...DefaultMargin },
      marginBottom: { ...DefaultMargin },
      margins: this.monkey(
        ['marginLeft'],
        ['marginRight'],
        ['marginTop'],
        ['marginBottom'],
        (marginLeft: Margin, marginRight: Margin, marginTop: Margin, marginBottom: Margin) => {
          return {
            left: marginLeft,
            right: marginRight,
            top: marginTop,
            bottom: marginBottom,
          };
        },
      ),
    });
  }

  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqPdfExportStore';

  get showModal() {
    return this.state.get('showModal');
  }

  get layout(): typeof DOCUMENT_LAYOUT[number] {
    return this.state.get('layout');
  }

  get paperSize(): typeof DOCUMENT_PAPER_SIZE[number] {
    return this.state.get('paperSize');
  }

  get margins(): PageMargins {
    return this.state.get('margins');
  }

  protected readonly handlers = {
    PDF_EXPORT_SET_SHOW_MODAL: (payload: { showModal: boolean }) => {
      this.state.set('showModal', payload.showModal);
    },

    REPORT_SET: (output: AnnotationOutputV1) => {
      this.setAllOptions(output);
    },

    /**
     * Sets the page layout
     *
     * @param {Object} layout - orientation for the PDF, one of DOCUMENT_LAYOUTS
     */
    PDF_EXPORT_SET_LAYOUT: (layout: typeof DOCUMENT_LAYOUT[number]) => {
      this.state.set('layout', layout);
    },

    /**
     * Sets the paper size
     *
     * @param {Object} paperSize - page size, one of DOCUMENT_PAPER_SIZE
     */
    PDF_EXPORT_SET_PAPER_SIZE: (paperSize: typeof DOCUMENT_PAPER_SIZE[number]) => {
      this.state.set('paperSize', paperSize);
    },

    PDF_EXPORT_SET_MARGIN_LEFT: (payload: Margin) => {
      this.state.set('marginLeft', { value: payload.value, units: payload.units });
    },

    PDF_EXPORT_SET_MARGIN_RIGHT: (payload: Margin) => {
      this.state.set('marginRight', { value: payload.value, units: payload.units });
    },

    PDF_EXPORT_SET_MARGIN_TOP: (payload: Margin) => {
      this.state.set('marginTop', { value: payload.value, units: payload.units });
    },

    PDF_EXPORT_SET_MARGIN_BOTTOM: (payload: Margin) => {
      this.state.set('marginBottom', { value: payload.value, units: payload.units });
    },
  };

  private parsePageMargin(pageMargin: string, fallbackMargin: Margin): Margin {
    const numberRegexPattern = /(\d+(\.\d+)?|\.\d+)/g;
    const unitRegexPattern = /[A-Za-z]+/g;
    try {
      const number = pageMargin.match(numberRegexPattern)[0];
      const unit = pageMargin.match(unitRegexPattern)[0] as DocumentMarginUnits;
      return { value: _.toNumber(number), units: unit };
    } catch (e) {
      errorToast({ messageKey: e.message });
      return fallbackMargin;
    }
  }

  private setAllOptions(params: {
    pageSize: string;
    pageOrientation: string;
    marginLeft: string;
    marginRight: string;
    marginTop: string;
    marginBottom: string;
  }) {
    this.state.set('marginTop', this.parsePageMargin(params.marginTop, this.margins.top));
    this.state.set('marginBottom', this.parsePageMargin(params.marginBottom, this.margins.bottom));
    this.state.set('marginLeft', this.parsePageMargin(params.marginLeft, this.margins.left));
    this.state.set('marginRight', this.parsePageMargin(params.marginRight, this.margins.right));
    this.state.set(
      'paperSize',
      _.find(DOCUMENT_PAPER_SIZE, {
        value: params.pageSize,
      }),
    );
    this.state.set('layout', _.find(DOCUMENT_LAYOUT, { value: params.pageOrientation }));
  }
}
