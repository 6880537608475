import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Modal } from '@seeqdev/qomponents';
import { SelectItemSearchWidget, SelectItemSearchWidgetProps } from '@/core/SelectItemSearchWidet.molecule';
import { SelectedSearchItem } from '@/search/search.types';

export interface SelectItemSearchModalProps extends SelectItemSearchWidgetProps {
  onClose: () => void;
  showHeaderCloseButton?: boolean;
  testId?: string;
  isMultiple?: boolean;
  header: {
    title: string;
    isEditable?: boolean;
    isTitleRequired?: boolean;
    titleIcon?: string;
    isInvalid?: boolean;
    placeholder?: string;
    onChange?: (e: any) => void;
  };
  /** HTML that should appear above search widget, but below title */
  body: JSX.Element | HTMLElement | null;
  footer?: JSX.Element;
  selectItems?: SelectedSearchItem[];
  showRecentlyAccessed?: boolean;
  onSubmit?: () => void;
}

export const SelectItemSearchModal: React.FunctionComponent<SelectItemSearchModalProps> = ({
  onClose,
  header,
  body,
  footer,
  showHeaderCloseButton = true,
  onSelect,
  iconPartialTooltipKey,
  selectItems,
  validateItem,
  scopeIds,
  excludeGloballyScoped = false,
  testId = 'selectAssetModal',
  modalId = testId,
  searchTypes,
  isMultiple,
  selectAllCallback = undefined,
  areAllSelected = () => false,
  showPinnedAssets = false,
  showOnlyScopedAssets = false,
  showOnlyConditions = false,
  showSwitchToLocallyScoped = false,
  onSwitchToLocallyScoped = _.noop,
  showRecentlyAccessed = false,
  overrideWorkbookId = undefined,
  onSubmit,
  filterByWorkbook,
}) => {
  return (
    <Modal
      open={true}
      onClose={onClose}
      size="sm"
      dialogClassName="scrollableSearchModal"
      testId={testId}
      title={header.title}
      isTitleEditable={header.isEditable}
      titleRequired={header.isTitleRequired}
      inputExtraClassNames={classNames('width-maximum', {
        'error-border': header.isInvalid,
      })}
      titlePlaceholder={header.placeholder}
      onTitleChanged={header.onChange}
      titleIcon={header.titleIcon}
      hideCloseIcon={!showHeaderCloseButton}
      modalFooter={footer}
      onSubmit={onSubmit}
      disableSubmitButton={!selectItems?.length}>
      <div>
        <>
          {body}
          <SelectItemSearchWidget
            onSelect={onSelect}
            iconPartialTooltipKey={iconPartialTooltipKey}
            selectedItems={selectItems}
            validateItem={validateItem}
            scopeIds={scopeIds}
            excludeGloballyScoped={excludeGloballyScoped}
            modalId={modalId}
            searchTypes={searchTypes}
            isMultiple={isMultiple}
            selectAllCallback={selectAllCallback}
            areAllSelected={areAllSelected}
            showPinnedAssets={showPinnedAssets}
            showOnlyScopedAssets={showOnlyScopedAssets}
            showOnlyConditions={showOnlyConditions}
            showSwitchToLocallyScoped={showSwitchToLocallyScoped}
            onSwitchToLocallyScoped={onSwitchToLocallyScoped}
            showRecentlyAccessed={showRecentlyAccessed}
            overrideWorkbookId={overrideWorkbookId}
            filterByWorkbook={filterByWorkbook}
          />
        </>
      </div>
    </Modal>
  );
};
