import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button, Icon, TextField } from '@seeqdev/qomponents';

interface IrregularScheduleProps {
  schedules: string[];
  onConvertIt: () => void;
}

export const IrregularSchedule: React.FunctionComponent<IrregularScheduleProps> = ({ schedules, onConvertIt }) => {
  const { t } = useTranslation();

  return (
    <div data-testid="live-schedule-config" className="mb15">
      <label>{t('SCHEDULE_TYPE.IRREGULAR_SCHEDULE')}</label>
      {_.map(schedules, (schedule) => (
        <TextField
          key={schedule}
          disabled={true}
          extraClassNames="mb15"
          testId="irregular-schedule-config__cron-schedule"
          type="text"
          value={schedule}
        />
      ))}

      <div className="flexColumnContainer flexAlignStart">
        <Icon icon="fa-info-circle" large={true} extraClassNames="btn-transparent" />
        <span>{t('SCHEDULE_TYPE.IRREGULAR')}</span>
        <Button
          testId="irregular-schedule-config__convert-it"
          extraClassNames="width-250"
          label={t('SCHEDULE_TYPE.IRREGULAR_CONVERT_IT')}
          onClick={onConvertIt}
        />
      </div>
    </div>
  );
};
