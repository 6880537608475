import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Select, TextField } from '@seeqdev/qomponents';
import { DayOfTheWeek, MonthlyScheduleData, MonthlyScheduleTypeName } from '@/schedule/schedule.types';
import { MAX_DAY, MIN_DAY } from '@/schedule/schedule.constants';

export type Option = { value: string; label: string };

interface MonthlyScheduleProps {
  monthlySchedule: MonthlyScheduleData;
  setMonthlySchedule: (monthlyScheduleData: MonthlyScheduleData) => void;
}

const NTH_OPTIONS = [
  {
    value: 1,
    label: 'SCHEDULE_TYPE.MONTHLY.NTH.FIRST',
  },
  {
    value: 2,
    label: 'SCHEDULE_TYPE.MONTHLY.NTH.SECOND',
  },
  {
    value: 3,
    label: 'SCHEDULE_TYPE.MONTHLY.NTH.THIRD',
  },
  {
    value: 4,
    label: 'SCHEDULE_TYPE.MONTHLY.NTH.FOURTH',
  },
];

const BY_DAY_OF_MONTH_ID = 'monthly-schedule-config__by-day-of-month';
const BY_DAY_OF_WEEK_ID = 'monthly-schedule-config__by-day-of-week';

export const MonthlySchedule: React.FunctionComponent<MonthlyScheduleProps> = ({
  monthlySchedule,
  setMonthlySchedule,
}) => {
  const { selectedType } = monthlySchedule;
  const dayOfMonthData = monthlySchedule.data[MonthlyScheduleTypeName.BY_DAY_OF_MONTH];
  const dayOfWeekData = monthlySchedule.data[MonthlyScheduleTypeName.BY_DAY_OF_WEEK];

  const { t } = useTranslation();

  const isByDayOfMonth = selectedType === MonthlyScheduleTypeName.BY_DAY_OF_MONTH;
  const isByDayOfWeek = selectedType === MonthlyScheduleTypeName.BY_DAY_OF_WEEK;
  const dayOfTheWeekOptions = _.map(NTH_OPTIONS, ({ value, label }) => ({ value, label: t(label) }));
  const daySelectOptions = _.map(Object.keys(DayOfTheWeek), (day) => ({
    value: day,
    label: t(`SCHEDULE_TYPE.WEEKLY.${day}`),
  }));

  const selectType = (selectedType: MonthlyScheduleTypeName) => {
    setMonthlySchedule(_.extend({}, monthlySchedule, { selectedType }));
  };

  const updateDayOfMonthDays = (event: React.ChangeEvent<HTMLInputElement>) => {
    const day = _.clamp(_.toInteger(event.target.value), MIN_DAY, MAX_DAY);
    const nextMonthlySchedule = { ...monthlySchedule };
    nextMonthlySchedule.data[MonthlyScheduleTypeName.BY_DAY_OF_MONTH].day = day;
    setMonthlySchedule(nextMonthlySchedule);
  };

  const selectNthDayOfWeek = (option: Option) => {
    const nextMonthlySchedule = { ...monthlySchedule };
    nextMonthlySchedule.data[MonthlyScheduleTypeName.BY_DAY_OF_WEEK].nth = _.toInteger(option.value);
    setMonthlySchedule(nextMonthlySchedule);
  };

  const selectDayOfWeek = (option: Option) => {
    const nextMonthlySchedule = { ...monthlySchedule };
    nextMonthlySchedule.data[MonthlyScheduleTypeName.BY_DAY_OF_WEEK].dayOfWeek = option.value as DayOfTheWeek;
    setMonthlySchedule(nextMonthlySchedule);
  };

  return (
    <div data-testid="monthly-schedule-config" className="flexRowContainer mb0">
      <div className="flexColumnContainer flexNoGrowNoShrink mb15">
        <Checkbox
          id={BY_DAY_OF_MONTH_ID}
          testId={BY_DAY_OF_MONTH_ID}
          type="radio"
          extraClassNames="mt5 mb5 mr5"
          label={t('SCHEDULE_TYPE.MONTHLY.ON_DAY')}
          checked={isByDayOfMonth}
          onChange={() => !isByDayOfMonth && selectType(MonthlyScheduleTypeName.BY_DAY_OF_MONTH)}
        />
        <TextField
          testId="monthly-schedule-config__by-day-of-month__day"
          type="number"
          required={true}
          max={MAX_DAY}
          min={MIN_DAY}
          showError={_.isNaN(dayOfMonthData.day) || dayOfMonthData.day < MIN_DAY || dayOfMonthData.day > MAX_DAY}
          extraClassNames="width-55 noValidationIcons"
          value={dayOfMonthData.day.toString()}
          onChange={updateDayOfMonthDays}
        />
      </div>
      <div className="flexColumnContainer flexNoGrowNoShrink mb15">
        <Checkbox
          id={BY_DAY_OF_WEEK_ID}
          testId={BY_DAY_OF_WEEK_ID}
          type="radio"
          extraClassNames="mt5 mb5 mr5"
          label={t('SCHEDULE_TYPE.MONTHLY.ON_THE')}
          checked={isByDayOfWeek}
          onChange={() => !isByDayOfWeek && selectType(MonthlyScheduleTypeName.BY_DAY_OF_WEEK)}
        />
        <span data-testid="monthly-schedule-config__by-day-of-week__nth">
          <Select
            extraClassNames="width-90 mr5 spec_monthly-schedule-config__by-day-of-week__nth"
            value={_.find(dayOfTheWeekOptions, { value: dayOfWeekData.nth })}
            options={dayOfTheWeekOptions}
            onChange={selectNthDayOfWeek}
          />
        </span>
        <span data-testid="monthly-schedule-config__by-day-of-week__day">
          <Select
            extraClassNames="width-110 spec-monthly-schedule-config__by-day-of-week__day"
            value={_.find(daySelectOptions, { value: dayOfWeekData.dayOfWeek })}
            options={daySelectOptions}
            onChange={selectDayOfWeek}
          />
        </span>
      </div>
    </div>
  );
};
