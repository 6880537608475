// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import utilities from '@/core/utilities';
import { KEY_CODES } from '@/main/app.constants';
import { Portal } from './Portal.molecule';
import { TextField } from '@seeqdev/qomponents';
import { focusAndSelectElement } from '@/utilities/dom.utilities';

interface EditableTextProps {
  value: string;
  onUpdate: (newText: string) => void;
  maxDisplayChars?: number;
  id?: string;
  testId?: string;
  forceEdit?: boolean;
  autoWidth?: boolean;
  allowEditing?: boolean;
  allowEmptyValue?: boolean;
  textClasses?: string; // extra classes to add to the text element (while not in editing mode)
  inputClasses?: string; // extra classes to add to the input element (while in editing mode)
  useDarkerGray?: boolean;
  onBeginEdit?: () => void;
  onEndEdit?: () => void;
  textPrefix?: string;
  placeholder?: string;
  clickRef?: (node: HTMLParagraphElement) => void;
}

/**
 * Input field that displays as text until clicked (see usage, e.g. DateTimeEntry)
 */
export const EditableText: React.FunctionComponent<EditableTextProps> = (props) => {
  const {
    value = '',
    autoWidth = false,
    onUpdate,
    textClasses,
    inputClasses,
    id,
    testId,
    allowEditing = true,
    allowEmptyValue = false,
    forceEdit = undefined,
    maxDisplayChars = -1,
    useDarkerGray = false,
    onBeginEdit,
    onEndEdit,
    textPrefix = '',
    placeholder = '',
    clickRef,
  } = props;

  const [inputWidth, setInputWidth] = useState<number>();
  const [inputHeight, setInputHeight] = useState<number>();
  // do not call setShowInput directly - call wrappedSetShowInput instead to make sure the expected callback
  // functions are triggered
  const [showInput, setShowInput] = useState<boolean>();
  const [newValue, setNewValue] = useState(value);
  const heightRef = useRef<HTMLSpanElement>();
  const inputRef = useRef<HTMLDivElement>();
  const textFieldRef = useRef<HTMLInputElement>();
  const valueRef = useRef<HTMLParagraphElement>();

  const wrappedSetShowInput = (show: boolean) => {
    if (valueRef.current) {
      setInputSize(valueRef.current);
    }
    setShowInput(show);
    if (show) {
      _.isFunction(onBeginEdit) && onBeginEdit();
    } else {
      _.isFunction(onEndEdit) && onEndEdit();
    }
  };

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  useEffect(() => {
    if (autoWidth && inputRef.current) {
      setInputWidth(inputRef.current.clientWidth);
    }
  }, [newValue]);

  useEffect(() => {
    if (clickRef) {
      clickRef(valueRef.current);
    }
  }, [valueRef.current]);

  useEffect(() => {
    if (!_.isUndefined(forceEdit) && allowEditing) {
      wrappedSetShowInput(forceEdit);
    }
  }, [forceEdit, allowEditing]);

  useEffect(() => {
    if (showInput && textFieldRef.current) {
      textFieldRef.current.focus();
      textFieldRef.current.select();
    }
  }, [showInput]);

  function setInputSize(target: HTMLElement) {
    if (heightRef.current?.clientHeight) {
      setInputHeight(heightRef.current.clientHeight);
    } else {
      setInputHeight(target.clientHeight);
    }
    setInputWidth(target.clientWidth);
  }

  const updateInput = () => {
    if (value !== newValue && (!_.isEmpty(newValue) || (_.isEmpty(newValue) && allowEmptyValue))) {
      onUpdate(newValue);
      // If the update fails and the value stays the same, the useEffect won't trigger, so manually set it to the
      // previous value here. If the update is valid, the value will change triggering the useEffect overriding this.
      setNewValue(value);
    } else {
      setNewValue(value);
    }
    wrappedSetShowInput(false);
  };

  const renderValue = (
    <>
      <p
        ref={valueRef}
        data-qtip-text={maxDisplayChars !== -1 && value?.length > maxDisplayChars && value ? value : undefined}
        id={id}
        data-testid={`valueInput_${testId}`}
        className={classNames(
          'mb0 specEditableText',
          { 'cursorText editableText': allowEditing, 'darkerGray': useDarkerGray },
          textClasses,
        )}
        onClick={(e) => {
          if (allowEditing) {
            setInputSize(e.target as HTMLElement);
            wrappedSetShowInput(true);
          }
        }}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.keyCode === KEY_CODES.ENTER && allowEditing) {
            setInputSize(e.target as HTMLElement);
            wrappedSetShowInput(true);
          }
        }}>
        {placeholder && !value && <span className="text-muted">{placeholder}</span>}
        {`${textPrefix}${maxDisplayChars > -1 ? utilities.intelligentlyTruncate(value, maxDisplayChars) : value}`}
      </p>
      {/* This <span> interferes with some tests. The Portal places it outside this component */}
      <Portal>
        <span ref={heightRef} className="editableTextHeight">
          1
        </span>
      </Portal>
    </>
  );

  const renderEditableText = () => {
    const formControl = (
      <TextField
        type="text"
        id={id}
        ref={textFieldRef}
        testId={`editableInput_${testId}`}
        extraClassNames={classNames('specEditableText pr0 pl0', { darkerGray: useDarkerGray }, inputClasses)}
        inputHeight={inputHeight}
        inputWidth={inputWidth}
        value={newValue}
        size="sm"
        onFocus={focusAndSelectElement}
        autoFocus={true}
        onChange={(e) => setNewValue(e.target.value)}
        onBlur={updateInput}
        onKeyDown={(e) => e.keyCode === KEY_CODES.ENTER && e.currentTarget.blur()}
        placeholder={placeholder}
      />
    );

    if (autoWidth) {
      return (
        <div className="flexRowContainer">
          {formControl}
          <div ref={inputRef} className={classNames(inputClasses, 'editableTextResizer')}>
            {textPrefix ? `${textPrefix} ${newValue}` : newValue}
          </div>
        </div>
      );
    }

    return formControl;
  };

  return showInput ? renderEditableText() : renderValue;
};
