import * as Slider from '@radix-ui/react-slider';
import React from 'react';
import { Icon } from '@seeqdev/qomponents';
import { stageIcons, TRACK_IMPACT } from '@/valueCapture/valueCapture.types';
import { doTrack } from '@/track/track.service';

export interface ValueCaptureSliderProps {
  setStageIdx: (stage: number) => void;
  stageIndex: number;
}

/**
 * This component renders the slider that determines how much of the Impact Report form is displayed.
 */
export const ValueCaptureSlider: React.FunctionComponent<ValueCaptureSliderProps> = ({ setStageIdx, stageIndex }) => {
  const handleValueChange = (val: any) => {
    setStageIdx(val[0]);
    doTrack(TRACK_IMPACT, 'Form Slider dragged', val[0]);
  };

  return (
    <div className=" flexRowContainer width-370">
      <Slider.Root
        className="SliderRoot"
        defaultValue={[0]}
        max={3}
        step={1}
        value={[stageIndex]}
        onValueChange={handleValueChange}>
        <Slider.Track className="SliderTrack">
          <Slider.Range className="SliderRange" />
        </Slider.Track>
        <Slider.Thumb className="SliderThumb" aria-label="Stage" />
      </Slider.Root>

      {/* Display corresponding icon for the current step */}
      <div className="slider-legend">
        {stageIcons.map((icon, index) => (
          <div key={index} className={index === stageIndex ? 'icon-active' : 'icon'}>
            <Icon icon={icon} type={index > stageIndex ? 'gray' : 'theme'} />
          </div>
        ))}
      </div>
    </div>
  );
};
