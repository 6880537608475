import React from 'react';
import { Select } from '@seeqdev/qomponents';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { DailySchedule } from '@/schedule/DailySchedule.atom';
import { WeeklySchedule } from '@/schedule/WeeklySchedule.atom';
import { MonthlySchedule } from '@/schedule/MonthlySchedule.atom';
import {
  DailyScheduleType,
  MonthlyScheduleData,
  ScheduleType,
  ScheduleTypeName,
  WeeklyScheduleData,
} from '@/schedule/schedule.types';

interface ScheduleTypeProps {
  scheduleType: ScheduleType;
  setScheduleType: (scheduleType: ScheduleType) => void;
  helpContent?: React.ReactNode;
  scheduleTypeTranslations: ScheduleTypeTranslations;
}

const TRANSLATION_FOR_SCHEDULE_TYPE = {
  [ScheduleTypeName.DAILY]: 'SCHEDULE_TYPE.DAILY.NAME',
  [ScheduleTypeName.WEEKLY]: 'SCHEDULE_TYPE.WEEKLY.NAME',
  [ScheduleTypeName.MONTHLY]: 'SCHEDULE_TYPE.MONTHLY.NAME',
  [ScheduleTypeName.LIVE]: 'SCHEDULE_TYPE.LIVE.NAME',
};

export type ScheduleTypeTranslations = { [key in ScheduleTypeName]?: string };

export const SelectScheduleType: React.FunctionComponent<ScheduleTypeProps> = ({
  scheduleType,
  setScheduleType,
  helpContent,
  scheduleTypeTranslations,
}) => {
  const { selectedType, data } = scheduleType;
  const { t } = useTranslation();

  const selectType = (option: { value: string; label: string }) => {
    const selectedType = option.value as ScheduleTypeName;
    setScheduleType({ ...scheduleType, selectedType });
  };

  const setDailySchedule = (dailyScheduleType: DailyScheduleType) => {
    const nextScheduleType = { ...scheduleType };
    nextScheduleType.data[ScheduleTypeName.DAILY] = dailyScheduleType;
    setScheduleType(nextScheduleType);
  };

  const setWeeklySchedule = (daysOfTheWeek: WeeklyScheduleData) => {
    const nextScheduleType = { ...scheduleType };
    nextScheduleType.data[ScheduleTypeName.WEEKLY] = daysOfTheWeek;
    setScheduleType(nextScheduleType);
  };

  const setMonthlySchedule = (monthlySchedule: MonthlyScheduleData) => {
    const nextScheduleType = { ...scheduleType };
    nextScheduleType.data[ScheduleTypeName.MONTHLY] = monthlySchedule;
    setScheduleType(nextScheduleType);
  };

  const options = _.map(Object.values(ScheduleTypeName), (type) => ({
    value: type,
    label: t(scheduleTypeTranslations[type] ?? TRANSLATION_FOR_SCHEDULE_TYPE[type]),
  }));
  return (
    <div className="mb15">
      <div className="flexRowContainer" data-testid="selectScheduleType">
        <div className="flexRowContainer">
          <div className="text-bold flexAlignCenter">{t('SCHEDULE_TYPE.HOW_OFTEN')}</div>
          <div className="flexColumnContainer flexAlignCenter mr5">
            <div data-testid="select-schedule-type" className="flexNoShrink">
              <Select
                value={_.find(options, { value: selectedType })}
                onChange={selectType}
                options={options}
                menuPortalTarget={document.body}
              />
            </div>
            {helpContent && helpContent}
          </div>
        </div>
        <div className="flexRowContainer">
          <div className="flexColumnContainer flexGrow-4 flexNoShrink">
            {selectedType === ScheduleTypeName.DAILY && (
              <DailySchedule selectedType={data[ScheduleTypeName.DAILY]} onSelect={setDailySchedule} />
            )}
            {selectedType === ScheduleTypeName.WEEKLY && (
              <WeeklySchedule days={data[ScheduleTypeName.WEEKLY]} setDays={setWeeklySchedule} />
            )}
            {selectedType === ScheduleTypeName.MONTHLY && (
              <MonthlySchedule
                monthlySchedule={data[ScheduleTypeName.MONTHLY]}
                setMonthlySchedule={setMonthlySchedule}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
