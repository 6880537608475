import React from 'react';
import { LegendWrapper } from '@/trend/LegendWrapper.atom';
import {
  TrendColorMode,
  TrendColorSettings,
  UsedCapsulePropertyValues,
  UserCapsulePropertyColorSettings,
} from '@/trendData/trendData.constants';
import { Icon } from '@seeqdev/qomponents';
import { getGradientColors } from '@/trendData/color.utilities';
import { ColorPicker } from '@/workbook/ColorPicker.organism';

interface ColorByCapsulePropertyLegendProps {
  trendColorSettings: TrendColorSettings;
  trendCapsulePropertyColors: UserCapsulePropertyColorSettings;
  usedCapsulePropertyValues: UsedCapsulePropertyValues;
  setTrendCapsuleColor: (capsuleProperty: string, capsuleValue: string, color: string) => void;
}

export const ColorByCapsulePropertyLegend: React.FunctionComponent<ColorByCapsulePropertyLegendProps> = ({
  trendColorSettings,
  trendCapsulePropertyColors,
  usedCapsulePropertyValues,
  setTrendCapsuleColor,
}) => {
  const gradientColors =
    trendColorSettings.colorMode === TrendColorMode.CapsulePropertyGradient
      ? getGradientColors(trendCapsulePropertyColors.seeqColorGradient, usedCapsulePropertyValues.length)
      : undefined;
  return (
    <LegendWrapper title={trendColorSettings.colorByCapsuleProperty}>
      {usedCapsulePropertyValues.map((value, index) => {
        const color =
          gradientColors?.[index] ?? trendCapsulePropertyColors[trendColorSettings.colorByCapsuleProperty!]?.[value];
        const icon =
          trendColorSettings.colorMode === TrendColorMode.CapsulePropertyGradient ? (
            <Icon icon="fc-capsule" extraClassNames="pr2" type={color ? 'color' : undefined} color={color} />
          ) : (
            <ColorPicker
              color={color}
              placement="top"
              notifyOnSelect={(_ignored, newColor) =>
                setTrendCapsuleColor(trendColorSettings.colorByCapsuleProperty!, value, newColor)
              }
              buttonComponent={(onColorClick) => (
                <Icon
                  icon="fc-capsule"
                  testId={`colorPickerButton-${value}`}
                  extraClassNames="pr2"
                  type={color ? 'color' : undefined}
                  color={color}
                  onClick={onColorClick}
                />
              )}
            />
          );
        return (
          <div className="mr10 text-nowrap" key={value}>
            {icon}
            {value}
          </div>
        );
      })}
    </LegendWrapper>
  );
};
