import { sqContextApi, sqGraphQLApi, sqItemsApi, sqTableDefinitionsApi, sqUserGroupsApi } from '@/sdk';
import { ColumnDefinitionInputV1, ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import { setValueCaptureCategories, setValueCaptureTableId } from '@/valueCapture/valueCapture.actions';
import {
  ASSIGNED_TO_WORKBOOK,
  ASSIGNED_TO_WORKSHEET,
  availableStages,
  IMPACT,
  IMPACT_ASSET,
  IMPACT_DESCRIPTION,
  IMPACT_DOCUMENT_LINK,
  IMPACT_END_DATE,
  IMPACT_FORMULA,
  IMPACT_FORMULA_PARAM,
  IMPACT_FREQUENCY,
  IMPACT_LESSON_LEARNED,
  IMPACT_NAME,
  IMPACT_OWNER,
  IMPACT_QUANTIFY_AMOUNT,
  IMPACT_REPORT_TOPIC,
  IMPACT_SAVINGS_TYPE,
  IMPACT_SITE,
  IMPACT_START_DATE,
  IMPACT_STATUS,
  IMPACT_UNIT,
  ImpactType,
  MANAGER,
  stageIcons,
  Stages,
  VALUE_CAPTURE_CATEGORY_PREFIX,
  VALUE_CAPTURE_TABLE_NAME,
  ValueCaptureCategory,
} from '@/valueCapture/valueCapture.types';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { t } from 'i18next';
import { getColumnDefinitions } from '@/valueCapture/tiles/table.utilities';
import { SelectOption } from '@/formbuilder/SelectFormComponent';
import { ColDef } from '@ag-grid-community/core';
import { throwError } from '@/core/utilities';
import { ScalarPropertyV1 } from 'sdk/model/ScalarPropertyV1';
import { errorToast } from '@/utilities/toast.utilities';

export function getCustomCategoryTitle(category: ValueCaptureCategory) {
  return category?.category?.name?.substring(VALUE_CAPTURE_CATEGORY_PREFIX.length) ?? '';
}

export function stageDisplayStringByIndex(stageIndex: number) {
  return t(`VALUE_CAPTURE.${availableStages[stageIndex]?.toUpperCase()}`);
}

export function getStageDisplayString(stage: Stages) {
  if (!stage) return '';
  if (t(`VALUE_CAPTURE.${stage.toUpperCase()}`) === `VALUE_CAPTURE.${stage.toUpperCase()}`) return stage;
  return t(`VALUE_CAPTURE.${stage.toUpperCase()}`);
}

export function getImpactStageIcon(stage: Stages) {
  const index = availableStages.indexOf(stage);
  if (index > -1 && index <= stageIcons.length) {
    return stageIcons[availableStages.indexOf(stage)];
  } else {
    return stageIcons[0];
  }
}

export function getCategoryDisplayString(
  categoryId: string,
  categories: { value: string; label: string }[] | undefined,
  fallback = '',
) {
  const category = categories?.find((category) => category.value === categoryId);
  return category ? category.label : fallback;
}

export async function fetchCategories() {
  const { data } = await sqContextApi.findLabelCategories({});
  const categories = data?.categories?.filter((category) => category.name?.startsWith('VCC_'));
  if (!categories) return;
  const allOptions: ValueCaptureCategory[] = [];
  await Promise.all(
    categories.map(async (category) => {
      const labelResponse = await sqContextApi.findLabels({ categoryId: category.id! });
      const labels = labelResponse.data.labels;
      const options = labels?.map((label) => ({ value: label.id!, label: label.name! }));
      allOptions.push({ category, options });
    }),
  );
  return allOptions;
}

export function getStageIndex(stage: Stages) {
  return availableStages.indexOf(stage.toLowerCase().trim() as Stages);
}

export function getAvailableAggregationSelectOptions(
  categories: ValueCaptureCategory[] | undefined,
): SelectOption<string | undefined>[] {
  const options = getColumnDefinitions(categories)
    .filter((column: ColDef<string, string>) => column.context?.canAggregateBy)
    .sort((a: ColDef<string, string>, b: ColDef<string, string>) => {
      if (a.headerName! < b.headerName!) return -1;
      if (a.headerName! > b.headerName!) return 1;
      return 0;
    })
    .map((columnDef) => ({
      label: columnDef.headerName!,
      value: columnDef.field!,
    }));

  return [{ label: t('VALUE_CAPTURE.NONE'), value: 'none' }, ...options];
}

/**
 * This function fetches all the categories that are available to a user to "categorize" their Impact report
 * These categories are Context Labels and we need to ensure we fetch them before we attempt to display the table as
 * we need them to properly create and map the columns for categories.
 */
export async function initializeValueCaptureCategories() {
  const categories = await fetchCategories();
  setValueCaptureCategories(categories);
}

/** Helper function to get column definition for a Materialized Table **/
function getTableColumnDefinition(
  columnName: string,
  columnType = ColumnTypeEnum.TEXT,
  isIndexed = false,
  isGenerated = false,
): ColumnDefinitionInputV1 {
  return {
    columnName,
    columnRules: [
      {
        eventProperty: {
          propertyName: columnName,
        },
      },
    ],
    columnType,
    isGenerated,
    isHidden: false,
    isIndexed,
    sortAscending: true,
    sortIndex: 0,
  };
}

function getManagerColumnDefinition(columnIndexUser: number) {
  const startingDef = getTableColumnDefinition(MANAGER, ColumnTypeEnum.UUID, true, true);
  startingDef.columnRules = [
    {
      manager: {
        columnIndexUser,
      },
    },
  ];
  return startingDef;
}

/**
 * Creates the materialized table definition for the Value Capture table
 */
async function createValueCaptureTable() {
  const columnDefinitions = [
    getTableColumnDefinition(SeeqNames.MaterializedTables.ItemIdColumn, ColumnTypeEnum.UUID, true),
    getTableColumnDefinition(SeeqNames.MaterializedTables.DatumIdColumn, ColumnTypeEnum.TEXT, true),

    // Identify Section
    getTableColumnDefinition(IMPACT_NAME),
    getTableColumnDefinition(IMPACT_DESCRIPTION),
    getTableColumnDefinition(IMPACT_DOCUMENT_LINK),
    getTableColumnDefinition(IMPACT_OWNER, ColumnTypeEnum.UUID, true),
    // NOTE: if you move around column definitions be sure to update this index to be the index of the IMPACT_OWNER
    // column
    getManagerColumnDefinition(6),

    // Categorize Section
    getTableColumnDefinition(IMPACT_SITE),
    getTableColumnDefinition(IMPACT_ASSET),

    // Quantify Section
    getTableColumnDefinition(IMPACT_LESSON_LEARNED),
    getTableColumnDefinition(IMPACT_QUANTIFY_AMOUNT),
    getTableColumnDefinition(IMPACT_UNIT),
    getTableColumnDefinition(IMPACT_SAVINGS_TYPE),
    getTableColumnDefinition(IMPACT_START_DATE, ColumnTypeEnum.TIMESTAMPTZ, true),
    getTableColumnDefinition(IMPACT_END_DATE, ColumnTypeEnum.TIMESTAMPTZ, true),
    getTableColumnDefinition(IMPACT_FORMULA),
    getTableColumnDefinition(IMPACT_FORMULA_PARAM, ColumnTypeEnum.UUID),

    // MONETIZE SECTION
    getTableColumnDefinition(IMPACT),
    getTableColumnDefinition(IMPACT_FREQUENCY),

    // WORKSHEET AND WORKBOOK
    getTableColumnDefinition(ASSIGNED_TO_WORKSHEET, ColumnTypeEnum.UUID, true),
    getTableColumnDefinition(ASSIGNED_TO_WORKBOOK, ColumnTypeEnum.UUID, true),
    getTableColumnDefinition(IMPACT_STATUS, ColumnTypeEnum.TEXT, true),
    getTableColumnDefinition(IMPACT_REPORT_TOPIC, ColumnTypeEnum.BOOLEAN),
  ];

  const tableDefinition = {
    columnDefinitions,
    name: VALUE_CAPTURE_TABLE_NAME,
  };

  const { data } = await sqTableDefinitionsApi.createTableDefinition(tableDefinition);
  const { data: userGroupResponse } = await sqUserGroupsApi.getUserGroups({ nameSearch: 'everyone' });
  if (userGroupResponse.items?.length === 1) {
    const everyoneGroupId = userGroupResponse.items[0].id;
    sqItemsApi.addAccessControlEntry(
      {
        identityId: everyoneGroupId,
        permissions: {
          read: true,
          write: true,
        },
      },
      { id: data.id },
    );
    return data;
  } else throwError('Could not find the "everyone" user group - unable to successfully create the Value Capture Table');
}

/**
 * Fetches the Value Capture Table ID from the server
 * If the table does not exist, it creates it
 */
export async function getValueCaptureTableId(): Promise<string | undefined> {
  const { data } = await sqItemsApi.searchItems({
    filters: [`name==${VALUE_CAPTURE_TABLE_NAME}`],
    types: ['TableDefinition'],
  });
  let tableId;
  if (!data.items || data.items.length === 0) {
    const table = await createValueCaptureTable();
    tableId = table.id;
  }
  if (data.items?.length === 1) {
    tableId = data.items[0].id;
  } else {
    throw new Error('More than one value capture table found');
  }
  setValueCaptureTableId(tableId);
  console.log('Value Capture Table ID:', tableId);
  return tableId;
}

export async function addOrUpdateImpactReport(tableId: string, impactReport: ScalarPropertyV1[]) {
  return sqGraphQLApi.graphql({
    query: 'mutation PublishEvents($events: RowEventsInput!) { publishEvent(rowEvents: $events) }',
    variables: {
      events: { tableDefinitionId: tableId, events: [{ genericEvent: { properties: impactReport } }] },
    },
  });
}

export function formatNumberAsValue(num: number): string {
  if (!Number.isFinite(num)) return '';
  if (num >= 1_000_000) {
    return `$${(num / 1_000_000).toFixed(3)}M`;
  } else if (num >= 1_000) {
    return `$${(num / 1_000).toFixed(0)}K`;
  } else {
    return `$${new Intl.NumberFormat('en-US').format(Math.round(num))}`;
  }
}

export function getImpactTypeDisplayString(impactType: ImpactType) {
  switch (impactType) {
    case 'continuous':
      return t('VALUE_CAPTURE.CONTINUOUS');
    case 'oneTime':
      return t('VALUE_CAPTURE.ONE_TIME');
    case 'customCalc':
      return t('VALUE_CAPTURE.CUSTOM_CALC');
    default:
      return impactType;
  }
}

export function getAllSupportedBoxTypes() {
  return [
    {
      label: t('VALUE_CAPTURE.TOTAL_USE_CASES'),
      value: 'useCaseCountBox',
      title: 'VALUE_CAPTURE.TOTAL_USE_CASES',
      property: 'useCaseCount',
      id: 'useCaseCountBox',
    },
    {
      label: t('VALUE_CAPTURE.TOTAL_IMPACT'),
      value: 'totalImpactBox',
      title: 'VALUE_CAPTURE.TOTAL_IMPACT',
      property: 'overallImpact',
      id: 'totalImpactBox',
    },
    {
      label: t('VALUE_CAPTURE.BIGGEST_SINGLE_IMPACT'),
      value: 'biggestImpactBox',
      title: 'VALUE_CAPTURE.BIGGEST_SINGLE_IMPACT',
      property: 'biggestImpact',
      id: 'biggestImpactBox',
    },
  ];
}

export async function deleteCategory(category: ValueCaptureCategory) {
  try {
    category.options &&
      (await Promise.all(
        category.options.map(async (option) => {
          try {
            await sqContextApi.deleteLabel({ labelId: option.value });
          } catch (e) {
            console.error(e);
            errorToast({ messageKey: 'Failed to delete associated label' });
          }
        }),
      ));
    await sqContextApi.deleteLabelCategory({ categoryId: category.category.id! });
  } catch (error) {
    console.error(error);
    errorToast({ messageKey: 'Failed to delete category' });
  }
  await initializeValueCaptureCategories();
}

export function goToWorksheet(worksheetId: string) {
  window.open(`/view/${worksheetId}`, '_blank');
}
