import React, { useState } from 'react';
import _ from 'lodash';
import { SimpleSaveFormBuilder } from '@/formbuilder/SimpleSaveFormBuilder.page';
import { FormElement } from '@/formbuilder/formBuilder.constants';
import { sqWorkbenchStore } from '@/core/core.stores';
import { setCurrentUser } from '@/workbench/workbench.actions';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { UserInputV1 } from '@/sdk/model/UserInputV1';
import { sqUsersApi } from '@/sdk/api/UsersApi';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import { IdentityPreviewV1 } from 'sdk/model/IdentityPreviewV1';
import { useTranslation } from 'react-i18next';
import { getUserRoleOptions } from '@/administration/users/user.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { useAsyncEffect } from 'rooks';
import { UserOutputV1 } from 'sdk/model/UserOutputV1';

interface EditUserProfileProps {
  closeFn: () => void;
}

export const EditUserProfile: React.FunctionComponent<EditUserProfileProps> = ({ closeFn }) => {
  const [user, setUser] = useState<UserOutputV1>();
  const { currentUser } = useFlux(sqWorkbenchStore);
  const { t } = useTranslation();

  const [editingPassword, setEditingPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');

  useAsyncEffect(async () => {
    const userResponse = await sqUsersApi.getUser({ id: currentUser.id });
    setUser(userResponse.data);
  }, [currentUser]);

  if (!user) {
    return null;
  }

  const isUserSSO = !user.isPasswordSettable;
  const isCancelEnabled = user.firstName && user.lastName && user.email;

  const updateUser = (values: {
    firstName: string;
    lastName: string;
    email: string;
    newPassword: string;
    role: string;
    site: string;
    manager: IdentityPreviewV1;
  }) => {
    const { firstName, lastName, email, newPassword, site, manager, role } = values;
    const name = `${_.trim(firstName)} ${_.trim(lastName)}`;
    const userProps = {
      email,
      firstName,
      lastName,
      name,
      manager: _.isEmpty(manager) ? SeeqNames.Database.Utils.EmptyGuid : manager?.id,
    };

    if (!_.isEmpty(role)) {
      _.assign(userProps, { role });
    } else {
      _.assign(userProps, { role: '' });
    }

    if (!_.isEmpty(site)) {
      _.assign(userProps, { site });
    } else {
      _.assign(userProps, { site: '' });
    }

    /**
     * For SEEQ users we allow them to update their email, but their email is also their username. So, when they
     * update their email, then we have to also update their username (as email and username seem to be one to the end
     * user, at least right now). If a user is however an Active Directory user then they really just edit an email,
     * not their username.
     */
    if (!isUserSSO) {
      _.assign(userProps, { username: email });
    }

    if (editingPassword) {
      return (
        sqUsersApi
          .updatePassword({ currentPassword, newPassword }, { id: user.id })
          // We assume that authProvider is Seeq because only those users can change their password.
          .then(() => successToast({ messageKey: 'USER.PASSWORD_CHANGED' }))
          .then(() => closeFn())
          .catch((error) => errorToast({ httpResponseOrError: error }))
          .finally(() => {
            setEditingPassword(false);
          })
      );
    }

    return sqUsersApi
      .updateUser(userProps as unknown as UserInputV1, { id: user.id })
      .then(() => setCurrentUser())
      .then(() => successToast({ messageKey: 'USER.PROFILE_UPDATED' }))
      .then(() => closeFn())
      .catch((error) => errorToast({ httpResponseOrError: error }));
  };

  const displayEditUser = () => setEditingPassword(false);

  const passwordChangeForm: FormElement = {
    component: 'FormGroup',
    name: 'editPassword',
    includeIf: editingPassword,
    components: [
      {
        component: 'TextFieldFormComponent',
        name: 'currentPassword',
        label: 'USER.CURRENT_PASSWORD',
        value: currentPassword,
        // Typescript doesn't know this, but since type === 'password' the value will be a string
        onChange: (value: any) => setCurrentPassword(value as string),
        size: 'sm',
        includeIf: !user.isAdmin,
        type: 'password',
        testId: 'currentPassword',
      },
      {
        component: 'PasswordGroupFormComponent',
        name: 'passwordFormGroup',
        value: 'passwordFormGroup',
      },
    ],
  };

  const formDefinition: FormElement[] = [
    {
      component: 'FormGroup',
      name: 'userProfile',
      includeIf: !editingPassword,
      components: [
        {
          component: 'TextFieldFormComponent',
          name: 'firstName',
          label: 'USER.FIRST_NAME',
          value: user.firstName,
          required: true,
          onChange: _.noop,
          size: 'sm',
          testId: 'firstName',
        },
        {
          component: 'TextFieldFormComponent',
          name: 'lastName',
          label: 'USER.LAST_NAME',
          value: user.lastName,
          required: true,
          onChange: _.noop,
          size: 'sm',
          testId: 'lastName',
        },
        {
          component: 'TextFieldFormComponent',
          name: 'email',
          // For non-SSO users make it clear that the email field is also the username
          label: isUserSSO ? 'USER.EMAIL' : 'USER.USERNAME_AND_EMAIL',
          // For non-SSO users with no email set (such as 'agent_api_key') show the username to make it clear
          // that editing the field would change the username
          value: isUserSSO ? user.email : user.email || user.username,
          required: true,
          onChange: _.noop,
          size: 'sm',
          testId: 'email',
        },
        {
          component: 'SelectFormComponent',
          name: 'role',
          label: 'USER.ROLE',
          value: user.role ?? '',
          onChange: _.noop,
          placeholder: 'USER.ROLE_PROMPT',
          testId: 'role',
          options: getUserRoleOptions(),
          skipStore: true,
        },
        {
          component: 'LabelFormComponent',
          name: 'managerLabel',
          value: t('USER.MANAGER'),
          testId: 'managerLabel',
        },
        {
          component: 'SelectIdentityFormComponent',
          name: 'manager',
          onChange: _.noop,
          extraClassNames: 'mb8',
          value: user?.manager?.id !== SeeqNames.Database.Utils.EmptyGuid ? user?.manager : undefined,
          allowGroups: false,
          idForLabel: 'identityName',
          includeAllProperties: true,
          startEditable: true,
          placeholder: 'USER.MANAGER_PROMPT',
          skipStore: true,
          clearIdentityWhenEmpty: true,
          skipIdentity: user?.id,
          validation: () => {
            return false;
          },
        },
        {
          component: 'TextFieldFormComponent',
          name: 'site',
          label: 'USER.SITE',
          value: user.site,
          onChange: _.noop,
          placeholder: 'USER.SITE_PROMPT',
          size: 'sm',
          testId: 'site',
          required: false,
        },
        {
          component: 'LabelFormComponent',
          name: 'passwordReset',
          value: '',
          extraClassNames: 'text-bolder',
        },
        {
          component: 'ClickableLinkFormComponent',
          name: 'setPassword',
          includeIf: user.isPasswordSettable,
          onChange: _.noop,
          value: 'USER.CHANGE_PASSWORD',
          icon: 'fa-lock',
          linkAction: () => setEditingPassword(true),
        },
      ],
    },
    passwordChangeForm,
  ];

  return (
    // The close and cancel buttons also get hidden for SSO users that are missing their first name,
    // last name, or email address to require the user to set those attributes
    <div data-testid="editUserProfile" className="editUserProfileShortForm">
      <SimpleSaveFormBuilder
        formDefinition={formDefinition}
        submitFn={updateUser}
        closeFn={editingPassword ? displayEditUser : () => closeFn()}
        hideCancel={!isCancelEnabled}
      />
    </div>
  );
};
