// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
// Sets up some window globals for react-time-input-polyfill
import './timePolyfillHelpers';
import TimeInputPolyfill from 'react-time-input-polyfill';
import { useTranslation } from 'react-i18next';
import { Icon } from '@seeqdev/qomponents';
import { AutoUpdateTimeScheduleEntry } from '@/schedule/schedule.types';

interface AutoUpdateTimeSchedulePropertiesProps {
  isInvalid: boolean;
  showRemoveIcon: boolean;
  entry: AutoUpdateTimeScheduleEntry;
  onChange: (updateTime: AutoUpdateTimeScheduleEntry) => void;
  onClickRemove: () => void;
}

export const AutoUpdateTimeScheduleProperties: React.FunctionComponent<AutoUpdateTimeSchedulePropertiesProps> = ({
  entry,
  onChange,
  isInvalid,
  showRemoveIcon,
  onClickRemove,
}) => {
  const { t } = useTranslation();

  const onChangeTime = (newTime: string) => {
    if (newTime) {
      return onChange({ ...entry, time: newTime });
    }
  };

  return (
    <div className="flexColumnContainer width-150 mb15" data-testid="time-schedule-entry">
      <TimeInputPolyfill
        data-testid="time-schedule-entry__time"
        className={classNames('noValidationIcons width-135 mr5 form-control', isInvalid ? 'is-invalid' : '')}
        value={entry.time}
        onChange={(e: any) => onChangeTime(e.value)}
        required={true}
      />

      {showRemoveIcon && (
        <Icon
          testId="time-schedule-entry__remove"
          extraClassNames="reportAutoUpdateTimeStrategy cursorPointer"
          onClick={onClickRemove}
          icon="fa-sharp fa-regular fa-close removeButton ng-scope sq-fairly-dark-gray"
          large={true}
        />
      )}
    </div>
  );
};
