import { SelectOption } from '@/formbuilder/SelectFormComponent';
import { LabelCategoryOutputV1 } from '@/sdk/model/LabelCategoryOutputV1';
import { AGGridTableTileProps } from '@/valueCapture/tiles/AGGridTableTile.atom';
import { GrayBackgroundTitleAndNumberTileProps } from '@/valueCapture/tiles/GrayBackgroundTitleAndNumberTileProps.atom';
import { AGGridCrossFilterChartTileProps } from '@/valueCapture/tiles/AGGridCrossFilterChartTile.atom';
import { SeeqNames } from '@/main/app.constants.seeqnames';

export const VALUE_CAPTURE_DATE_FORMAT = 'MMM D, YYYY';

export const IMPACT = 'impact';
export const CALCULATED_IMPACT = 'calculatedImpact';
export const IMPACT_NAME = 'Name';
export const IMPACT_DESCRIPTION = 'Description';
export const IMPACT_OWNER = 'Impact Owner';
export const IMPACT_OWNER_FIRSTNAME = 'Impact Owner.properties.First Name';
export const IMPACT_OWNER_LASTNAME = 'Impact Owner.properties.Last Name';
export const OWNER = 'owner';
export const IMPACT_STATUS = 'Status';
export const IMPACT_DOCUMENT_LINK = 'Document Links';
export const IMPACT_FORMULA = 'Formula';
export const IMPACT_SAVINGS_TYPE = 'Savings Type';
export const IMPACT_FREQUENCY = 'Frequency';
export const IMPACT_UNIT = 'Unit';
export const IMPACT_ASSET = 'Asset';
export const IMPACT_START_DATE = 'Impact Start Date';
export const IMPACT_END_DATE = 'Impact End Date';
export const IMPACT_QUANTIFY_AMOUNT = 'Impact Quantify Amount';
export const ASSIGNED_TO_WORKSHEET = 'Worksheet Id';
export const ASSIGNED_TO_WORKBOOK = 'Workbook Id';
export const IMPACT_SITE = 'Impact Site';
export const MANAGER = 'Manager';
export const MANAGER_ID = 'managerId';
export const IS_MANAGER = 'isManager';
export const MANAGER_FIRSTNAME = 'Manager.properties.First Name';
export const MANAGER_LASTNAME = 'Manager.properties.Last Name';
export const IMPACT_LESSON_LEARNED = 'Lesson Learned';
export const IMPACT_FORMULA_PARAM = 'Formula Param';
export const IMPACT_REPORT_TOPIC = 'Based on Topic';
export const VALUE_CAPTURE_CATEGORY_DISPLAY_NAME = 'Category Display Name';
export const VALUE_CAPTURE_LABEL_ID = 'Label Id';

export const TRACK_IMPACT = 'Impact Report';

export interface ValueCaptureTile {
  type: 'chart' | 'table' | 'gauge' | 'box';
  w: number;
  h: number;
  x: number;
  y: number;
  i: string;
  id: string;
}

export const defaultLayoutDefinition = [
  {
    type: 'box',
    id: 'useCaseCountBox',
    i: 'useCaseCountBox',
    w: 1,
    x: 0,
    y: 0,
    h: 22,
    title: 'VALUE_CAPTURE.TOTAL_USE_CASES',
    property: 'useCaseCount',
    format: false,
  },
  {
    type: 'box',
    id: 'totalImpactBox',
    i: 'totalImpactBox',
    w: 1,
    x: 1,
    y: 0,
    h: 22,
    title: 'VALUE_CAPTURE.TOTAL_IMPACT',
    property: 'overallImpact',
    format: true,
  },
  {
    type: 'box',
    id: 'biggestImpactBox',
    i: 'biggestImpactBox',
    w: 1,
    x: 2,
    y: 0,
    h: 22,
    title: 'VALUE_CAPTURE.BIGGEST_SINGLE_IMPACT',
    property: 'biggestImpact',
    format: true,
  },
  {
    type: 'gauge',
    id: 'gauge',
    i: 'gauge',
    w: 3,
    x: 3,
    y: 0,
    h: 22,
  },
  {
    type: 'table',
    id: 'table',
    i: 'table',
    w: 12,
    x: 0,
    y: Number.MAX_SAFE_INTEGER,
    h: 40,
  },
];

export type ValueCaptureChartDefinition = ValueCaptureTile & AGGridCrossFilterChartTileProps & { type: 'chart' };
export type ValueCaptureTableDefinition = ValueCaptureTile & AGGridTableTileProps & { type: 'table' };
export type ValueCaptureBoxTileDefinition = ValueCaptureTile & GrayBackgroundTitleAndNumberTileProps & { type: 'box' };
export type ValueCaptureGaugeTileDefinition = ValueCaptureTile & { type: 'gauge' };

export type ValueCaptureDashboardContent =
  | ValueCaptureChartDefinition
  | ValueCaptureTableDefinition
  | ValueCaptureBoxTileDefinition
  | ValueCaptureGaugeTileDefinition;

export const VALUE_CAPTURE_TABLE_NAME = 'VCC_ImpactTable_v1';
export const VALUE_CAPTURE_CATEGORY_PREFIX = 'VCC_';
export type Stages = 'identify' | 'categorize' | 'quantify' | 'monetize';
export const availableStages: Stages[] = ['identify', 'categorize', 'quantify', 'monetize'];
export const stageIcons = ['fa-lightbulb-on', 'fa-list', 'fa-calculator', 'fa-piggy-bank'];

export type ImpactType = 'oneTime' | 'continuous' | 'customCalc';
export const quantifyUnits = {
  'Time': ['d', 'h'],
  'Currency': ['$', 'M$', 'M£', 'M¤', 'M¥', 'M₩', 'M€', 'k$', 'k£', 'k¤', 'k¥', 'k₩', 'k€', '£', '¤', '¥', '₩', '€'],
  'Energy': [
    'BTU',
    'GJ',
    'GW·h',
    'J',
    'MBTU',
    'MJ',
    'MW·h',
    'Mcal',
    'W·h',
    'W·s',
    'cal',
    'ftlb',
    'kBTU',
    'kJ',
    'kW·h',
    'kcal',
  ],
  'Volume Flow Rate': [
    'L/h',
    'L/min',
    'L/s',
    'bbl/d',
    'bbl/h',
    'bbl/min',
    'cf/d',
    'cf/h',
    'cf/min',
    'cm³/min',
    'cm³/s',
    'gal/d',
    'gal/h',
    'gal/min',
    'gal/s',
    'mL/h',
    'mL/min',
    'mL/s',
    'm³/h',
    'm³/min',
    'm³/s',
  ],
  'Quantity': ['count', 'count/min'],
  'Power': ['BTU/h', 'GW', 'MBTU/d', 'MBTU/h', 'MJ/h', 'MW', 'Mcal/h', 'W', 'cal/s', 'hp', 'kW'],
  'Mass Flow Rate': [
    'Mlb/d',
    'g/min',
    'g/s',
    'kg/d',
    'kg/h',
    'kg/min',
    'kg/s',
    'klb/d',
    'klb/h',
    'lb/s',
    'lton/d',
    'ston/d',
    't/d',
  ],
  'Volume': [
    'L',
    'ML',
    'Mbbl',
    'Mcf',
    'Mgal',
    'bbl',
    'cf',
    'cm³',
    'dL',
    'gal',
    'hL',
    'kL',
    'kbbl',
    'kcf',
    'kgal',
    'mL',
    'm³',
    'nL',
  ],
  'Area': ['acre', 'cm²', 'ft²', 'ha', 'in²', 'km²', 'mi²', 'mm²', 'm²', 'yd²'],
  'Length': ['cm', 'ft', 'in', 'km', 'm', 'mi', 'mm', 'nmi', 'sxi', 'yd', 'µm'],
  'Temperature': ['°C', '°F'],
};

export interface ValueCaptureCategory {
  category: LabelCategoryOutputV1;
  options: SelectOption<string>[] | undefined;
}

export interface ValueCaptureUseCase {
  [IMPACT_OWNER]: string;
  [IMPACT_NAME]: string;
  [IMPACT_OWNER_FIRSTNAME]: string | undefined;
  [IMPACT_OWNER_LASTNAME]: string | undefined;
  [IS_MANAGER]?: boolean;
  [OWNER]?: string;
  [MANAGER_ID]?: string;
  [IMPACT_STATUS]?: Stages;
  [ASSIGNED_TO_WORKSHEET]?: string;
  [ASSIGNED_TO_WORKBOOK]?: string;
  [SeeqNames.MaterializedTables.DatumIdColumn]?: string;
  [SeeqNames.MaterializedTables.ItemIdColumn]?: string;
}

export const instructions =
  '<p>The <strong>Description</strong> field should clearly describe the technical problem or challenge your team is facing. This may include issues with systems, processes, or equipment. Be as specific as possible, focusing on the following points:</p><ol class="ak-ol" data-indent-level="2" start="1"><li><p><strong data-renderer-mark="true">Problem Overview</strong>: Provide a concise explanation of the issue you are trying to solve. Mention the system, process, or equipment involved, and explain the challenge (e.g., pressure fluctuations, overloads, nuisance alarms).</p></li><li><p data-renderer-start-pos="3269"><strong>Impact</strong>: Describe the potential or actual negative impact of the issue. This could include safety risks, financial costs, production delays, or regulatory concerns.</p></li><li><p data-renderer-start-pos="3436"><strong>Specific Technical Details</strong>: Include any technical metrics or specifications related to the issue (e.g., pressure thresholds, system overloads, number of alarms, temperature limits). Be as detailed as possible so the problem is clearly understood.</p></li><li><p><strong data-renderer-mark="true">Action Items</strong>: Outline the steps or actions that will be taken to address the technical challenge. Specify who is responsible for each action, deadlines, and any tools or resources required for resolution or implementation.</p></li></ol>';
