import _ from 'lodash';
import classNames from 'classnames';
import tinycolor from 'tinycolor2';
import React, { useEffect, useRef, useState } from 'react';
import addTreemapModule from 'highcharts/modules/treemap';
import Highcharts, { ColorString, GradientColorObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import useResizableHighchart from '@/core/hooks/useResizableHighchart.hook';
import { formatNumber } from '@/utilities/numberHelper.utilities';
import { AssetOutputV1 } from 'sdk/model/AssetOutputV1';
import { ScalarValueOutputV1 } from 'sdk/model/ScalarValueOutputV1';
import { CHART_FONT_STYLE } from '@/chart/chart.constants';

/**
 * Represents an item in the tree for a Treemap.
 */
export interface TreemapItem {
  asset?: Pick<AssetOutputV1, 'id' | 'name'>;
  color: ColorString;
  displayScalars: TreemapScalar[];
  size?: number;
  isLeafAsset?: boolean;
  isUncertain?: boolean;
  priority?: number;
}

/**
 * Frontend representation of a ScalarValue for a Treemap
 */
interface TreemapScalar extends ScalarValueOutputV1 {
  title?: string;
  signal?: any;
}

/**
 * An extended Highcharts.Point with the extra data that we use for custom functionality in Treemap
 */
interface SeeqTreemapChartPoint extends Highcharts.Point {
  defaultColor: ColorString;
  name: string;
  value: number;
  color: GradientColorObject;
  size?: number;
  isLeafAsset?: boolean;
  isUncertain: boolean;
  displayScalars: TreemapScalar[];
  priority?: number;
  asset?: Pick<AssetOutputV1, 'id' | 'name'>;
}

export interface TreemapChartProps {
  tree: TreemapItem[];
  isContent: boolean;
  onClickNode: (asset: { id: string } | undefined, isLeafNode: boolean) => void;
  afterChartUpdate: () => void;
}

addTreemapModule(Highcharts);

export const TreemapChart: React.FunctionComponent<TreemapChartProps> = (props) => {
  const { tree, isContent, afterChartUpdate, onClickNode } = props;

  const { t } = useTranslation();
  const [chart, setChart] = useState<Highcharts.Chart | null>(null);
  const chartElementRef = useRef(null);
  const [options, setOptions] = useState({} as Highcharts.Options);

  const renderDetails = (displayScalars: TreemapScalar[]) => {
    const formattedDisplayScalars = _.groupBy(displayScalars, 'signal');
    return `
                  <div class="label-details-container">
                    ${_.map(formattedDisplayScalars, (scalars, signal) => {
                      return `<div class="statistic-info">
                        <strong>${signal}</strong>
                        ${_.map(scalars, (scalar: { title: string; value: any; uom: string }) => {
                          return `<div class="statistic-details">
                            ${t(scalar.title)}:
                            ${
                              _.isNumber(scalar.value)
                                ? `${formatNumber(scalar.value)} ${scalar.uom}`
                                : scalar.value ?? ''
                            }
                          </div>`;
                        }).join('')}
                      </div>`;
                    }).join('')}
                  </div>
                `;
  };

  useEffect(() => {
    const newOptions: Highcharts.Options = {
      plotOptions: {
        series: {
          dataLabels: {
            allowOverlap: true,
          },
        },
      },
      series: [
        {
          type: 'treemap',
          animation: false,
          layoutAlgorithm: 'strip',
          cursor: 'pointer',
          borderWidth: 4,
          borderRadius: 5,
          borderColor: '#fff',
          dataLabels: {
            useHTML: true,
            align: 'left',
            verticalAlign: 'top',
            crop: false,
            formatter() {
              const point = this.point as SeeqTreemapChartPoint;
              /* When we use gradient color for the nodes, the label colors ar not correctly set anymore */
              const textColor = tinycolor(point.defaultColor).isLight() ? '#000' : '#fff';

              const displayScalars = point.displayScalars;
              const details = !_.isEmpty(displayScalars) ? renderDetails(displayScalars) : `<span></span>`;

              const labelHeight = _.get(this.point, 'graphic.element.height.baseVal.value');
              const labelWidth = _.get(this.point, 'graphic.element.width.baseVal.value');
              return `
                <div
                  class="label-container"
                  style="color: ${textColor}; height: ${
                labelHeight ? `${labelHeight - 25}px` : 'auto'
              }; overflow-y: auto; width: ${labelWidth - 20}px; margin-left: 10px;"
                  >
                  <h5>${this.point.name}</h5>
                  ${details}
                </div>`;
            },
          },
          events: {
            click: (e) => {
              // we use cloneDeep here in order to avoid it mutating when the chart is redrawn by setView -
              // necessary only for systemTests because there the setView is executed immediately
              const point = _.cloneDeep(e.point as SeeqTreemapChartPoint);
              onClickNode(point.asset, !!point.isLeafAsset);
            },
          },
          data: getFormattedData(),
        },
      ],
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      chart: {
        backgroundColor: 'transparent',
        style: CHART_FONT_STYLE,
      },
    };
    setOptions(newOptions);
    afterChartUpdate();
  }, [tree]);

  const getNodeColor = (nodeData: TreemapItem): GradientColorObject => {
    const nodeColor: GradientColorObject = {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 1 },
      stops: [
        [0.3, `${nodeData.color}ff`], // start
        [1, `${nodeData.color}7f`], // end
      ],
    };

    if (nodeData.isUncertain) {
      let i, count;
      const stopPoints = [];

      for (i = 0, count = 1; i <= 1; i += 0.03, count++) {
        const alphaColor = count % 2 ? 'ff' : '7f';
        // start, end colors
        stopPoints.push([i, `${nodeData.color}${alphaColor}`], [i + 0.03, `${nodeData.color}${alphaColor}`]);
      }
      nodeColor.stops = stopPoints as any;
    }

    return nodeColor;
  };

  const getFormattedData = () =>
    tree &&
    tree.map((data) => ({
      ...data,
      name: data.asset?.name,
      value: data.size,
      id: data.asset?.id,
      className: classNames(`highcharts-point-treemap highcharts-point-${_.kebabCase(data.asset?.name)}`, {
        uncertain: data.isUncertain,
      }),
      defaultColor: data.color,
      // Linear gradient used as a color option
      color: getNodeColor(data),
    }));

  useEffect(() => {
    if (_.get(chart, 'series[0].points', []).length) {
      const pointsGraphics = chart?.series[0].points.map((point: any) => point.graphic.element);
      pointsGraphics?.forEach((elem: any, i: number) => {
        elem.setAttribute('data-testid', `highchart-point-testid-${_.lowerCase(elem.point.name).replace(' ', '-')}`);
      });
    }
  }, [chart?.series]);

  const afterChartCreated = (highchartsChart: Highcharts.Chart) => {
    setChart(highchartsChart);
  };

  useResizableHighchart({
    chart,
    chartElementRef,
    setChart,
    callOnLoad: isContent,
  });

  return (
    <div className="flexFill flexRowContainer treemapContainer" ref={chartElementRef}>
      {!tree && (
        <div className="fs16 p25 flexSelfCenter width-400 text-center">
          <IconWithSpinner spinning={true} />
          {t('LOADING')}
        </div>
      )}
      {tree && !tree.length && (
        <div className="alert alert-info fs16 p25 flexSelfCenter width-400 text-center">
          {t('NO_RESULTS_DISPLAY_RANGE')}
        </div>
      )}
      {tree && !!tree.length && (
        <HighchartsReact
          useHTML={true}
          highcharts={Highcharts}
          options={options}
          callback={afterChartCreated}
          containerProps={{ style: { height: '100%' } }}
        />
      )}
    </div>
  );
};
