import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';
import { sqDurationStore } from '@/core/core.stores';
import _ from 'lodash';
import { OutputType } from '@/tools/isolationForest/isolationForest.constants';
import { AnyProperty } from '@/utilities.types';
import { ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';
import { Capsule } from '@/utilities/datetime.constants';

export const DEFAULT_DURATION = { value: 0, units: 'min' };

export class IsolationForestStore extends BaseToolStore {
  static readonly storeName = 'isolationForestStore';
  type = TREND_TOOLS.ISOLATION_FOREST;
  parameterDefinitions = {
    condition: {
      predicate: ['name', 'condition'],
    },
    restrictToCondition: {
      predicate: ['name', 'restrictToCondition'],
    },
    inputSignals: {
      predicate: (parameter: { name: string }) =>
        parameter.name !== 'condition' && parameter.name !== 'restrictToCondition',
      multiple: true,
    },
  };

  initialize() {
    this.state = this.immutable({
      ...BASE_TOOL_COMMON_PROPS,
      windowStart: sqDurationStore.displayRange.start.valueOf(),
      windowEnd: sqDurationStore.displayRange.end.valueOf(),
      window: this.monkey(['windowStart'], ['windowEnd'], (startTime, endTime) => ({ startTime, endTime })),
      modelPropertiesExpanded: false,
      color: '',
      numEstimators: 10,
      contaminationAuto: true,
      contamination: 0.1,
      bootstrap: false,
      outputType: OutputType.CONDITION,
      cleanCapsules: false,
      capsuleMinDuration: DEFAULT_DURATION,
      capsuleMergeDuration: DEFAULT_DURATION,
    });
  }

  get inputSignals() {
    return this.state.get('inputSignals');
  }

  get condition() {
    return this.state.get('condition');
  }

  get restrictToCondition() {
    return this.state.get('restrictToCondition');
  }

  get window() {
    return this.state.get('window');
  }

  get color(): string {
    return this.state.get('color');
  }

  get modelPropertiesExpanded(): boolean {
    return this.state.get('modelPropertiesExpanded');
  }

  get outputType() {
    return this.state.get('outputType');
  }

  get numEstimators(): number {
    return this.state.get('numEstimators');
  }

  get contaminationAuto(): boolean {
    return this.state.get('contaminationAuto');
  }

  get contamination(): number {
    return this.state.get('contamination');
  }

  get bootstrap(): boolean {
    return this.state.get('bootstrap');
  }

  get cleanCapsules(): boolean {
    return this.state.get('cleanCapsules');
  }

  get capsuleMinDuration() {
    return this.state.get('capsuleMinDuration');
  }

  get capsuleMergeDuration() {
    return this.state.get('capsuleMergeDuration');
  }

  rehydrate(dehydratedState: AnyProperty) {
    this.state.merge(dehydratedState);
  }

  setTrainingWindow(payload: { window: Capsule }) {
    this.state.set('windowStart', _.get(payload.window, 'startTime'));
    this.state.set('windowEnd', _.get(payload.window, 'endTime'));
  }

  setColor(payload: { color: string }) {
    this.state.set('color', payload.color);
  }

  setModelPropertiesExpanded(payload: { modelPropertiesExpanded: boolean }) {
    this.state.set('modelPropertiesExpanded', payload.modelPropertiesExpanded);
  }

  setOutputType(payload: { outputType: OutputType }) {
    this.state.set('outputType', payload.outputType);
  }

  setNumEstimators(payload: { numEstimators: number }) {
    this.state.set('numEstimators', payload.numEstimators);
  }

  setContaminationAuto(payload: { contaminationAuto: boolean }) {
    this.state.set('contaminationAuto', payload.contaminationAuto);
  }

  setContamination(payload: { contamination: number }) {
    this.state.set('contamination', payload.contamination);
  }

  setBootstrap(payload: { bootstrap: boolean }) {
    this.state.set('bootstrap', payload.bootstrap);
  }

  setCleanCapsules(payload: { cleanCapsules: boolean }) {
    this.state.set('cleanCapsules', payload.cleanCapsules);
  }

  setCapsuleMinDuration(payload: { capsuleMinDuration: number }) {
    this.state.set('capsuleMinDuration', payload.capsuleMinDuration);
  }

  setCapsuleMergeDuration(payload: { capsuleMergeDuration: number }) {
    this.state.set('capsuleMergeDuration', payload.capsuleMergeDuration);
  }

  modifyConfigParams(config: any) {
    return _.omit(config, ['modelPropertiesExpanded']);
  }

  protected readonly handlers = {
    ...this.baseHandlers,
    IF_SET_TRAINING_WINDOW: this.setTrainingWindow,
    IF_SET_COLOR: this.setColor,
    IF_SET_MODEL_PROPERTIES_EXPANDED: this.setModelPropertiesExpanded,
    IF_SET_OUTPUT_TYPE: this.setOutputType,
    IF_SET_NUM_ESTIMATORS: this.setNumEstimators,
    IF_SET_CONTAMINATION_AUTO: this.setContaminationAuto,
    IF_SET_CONTAMINATION: this.setContamination,
    IF_SET_BOOTSTRAP: this.setBootstrap,
    IF_SET_CLEAN_CAPSULES: this.setCleanCapsules,
    IF_SET_CAPSULE_MIN_DURATION: this.setCapsuleMinDuration,
    IF_SET_CAPSULE_MERGE_DURATION: this.setCapsuleMergeDuration,
  };
}
