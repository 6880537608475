import { PersistenceLevel, Store } from '@/core/flux.service';
import { FixedListSearchV1, PropertyFilterInputV1, PropertyInputV1 } from '@/sdk';
import _ from 'lodash';
import { base64guid } from '@/utilities/utilities';
import { OperatorEnum } from 'sdk/model/PropertyFilterInputV1';
import { Search } from '@/itemFinder/itemFinder.constants';
import { createSelector } from 'reselect';

const DEFAULT_FIXED_LIST_SEARCH: FixedListSearchV1 = {
  isInclude: true,
  itemIds: [],
  searchType: '' as any,
};

export type PropertiesList = {
  operator: OperatorEnum;
  propertyName: '';
  values: [{ value: string | number }];
  searchId: string;
  propertyId: string;
};

type Value = {
  value: string | number;
  propertyId: string;
  searchId: string | number;
  valueId: string | undefined;
};

export type UpdateSearchPayload = {
  searchId: string | number | undefined;
  index?: number;
  action:
    | 'UPDATE_SEARCH_FIELDS'
    | 'ADD_PROPERTY'
    | 'REMOVE_PROPERTY'
    | 'UPDATE_PROPERTY'
    | 'ADD_VALUE'
    | 'REMOVE_VALUE'
    | 'UPDATE_VALUE';
  propertyId?: string;
  propertyData?: Partial<{
    operator: OperatorEnum;
    propertyName: string;
    values: Value[];
  }>;
  valueId?: string;
  newValue?: Value;
  searchData?: Record<string, unknown>;
};

export type SearchObject = {
  searchId: string;
  searchType: any;
  isCollapsed: boolean;
  itemIds?: string[];
  predicates?: Array<{
    propertyId: string;
    propertyName: string;
    operator: OperatorEnum;
    values: Value[];
  }>;
};

export const INITIAL_SEARCHES: SearchObject[] = [
  {
    ..._.cloneDeep(DEFAULT_FIXED_LIST_SEARCH),
    searchId: base64guid(),
    isCollapsed: false,
    predicates: [],
  },
];

export class ItemFinderStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqItemFinderStore';

  initialize() {
    this.state = this.immutable({
      id: '',
      name: '',
      searches: INITIAL_SEARCHES,
      cronSchedule: [],
    });
  }

  get id(): string {
    return this.state.get('id');
  }

  get name(): string {
    return this.state.get('name');
  }

  get searches(): Search[] {
    return this.getSearches(this.state.get('searches'));
  }

  get cronSchedule(): string[] {
    return this.state.get('cronSchedule');
  }

  private getSearches: (searches: Search[]) => Search[] = createSelector(
    (searches: Search[]) => searches,
    (searches) => searches.map((search, index) => ({ ...search, searchId: search.searchId ?? index })),
  );

  protected readonly handlers = {
    RESET_ITEM_FINDER: () => {
      this.initialize();
    },
    SET_EXISTING_ITEM_FINDER: ({
      value,
    }: {
      value: {
        name: string;
        searches: any[];
        cronSchedule: string[];
      };
    }) => {
      const updatedSearches = value.searches.map((search) => {
        search.searchId = search.searchId ?? base64guid();

        if (Array.isArray(search.predicates)) {
          search.predicates = search.predicates.map(
            (property: { searchId: string; propertyId: string; values: any[] }) => {
              property.propertyId = property.propertyId ?? base64guid();
              property.searchId = search.searchId;

              if (Array.isArray(property.values)) {
                property.values = property.values.map((val) => {
                  val.valueId = val.valueId ?? base64guid();
                  val.propertyId = property.propertyId;
                  val.searchId = search.searchId;
                  return val;
                });
              }
              return property;
            },
          );
        }

        return search;
      });

      const combinedSearches = [...updatedSearches, ...(this.state.get('searches') || [])].filter(
        (search) => search.searchType,
      );

      this.state.set('name', value.name);
      this.state.set('searches', combinedSearches);
      this.state.set('cronSchedule', value.cronSchedule);
    },
    ITEM_FINDER_SET_NAME: ({ name }: { name: string }) => {
      this.state.set('name', name);
    },
    ITEM_FINDER_ADD_SEARCH: () => {
      const searchId = base64guid();
      const newSearch = { ...DEFAULT_FIXED_LIST_SEARCH, searchId };
      this.state.push('searches', newSearch);
    },
    ITEM_FINDER_REMOVE_SEARCH: (index: number) => {
      if (index > -1) {
        this.state.splice('searches', [index, 1]);
      }
    },
    ITEM_FINDER_SET_CRON_SCHEDULE: ({ cronSchedule }: { cronSchedule: string[] }) => {
      this.state.set('cronSchedule', cronSchedule);
    },
    ITEM_FINDER_UPDATE_SEARCH: (payload: UpdateSearchPayload) => {
      const { action, index, searchId, searchData, propertyData, newValue } = payload;

      const searches = this.state.get('searches') as Search[];
      const searchIndex = searchId !== undefined ? searches.findIndex((s) => s.searchId === searchId) : index;
      if (searchIndex === undefined) {
        return;
      }

      const currentSearch = searches[searchIndex];
      let updatedSearch = { ...currentSearch };

      if (currentSearch) {
        switch (action) {
          case 'UPDATE_SEARCH_FIELDS': {
            updatedSearch = {
              ...currentSearch,
              ...searchData,
            };
            break;
          }

          case 'ADD_PROPERTY': {
            const propertyId = base64guid();
            const valueId = base64guid();
            const newProperty = {
              propertyId,
              searchId,
              propertyName: undefined,
              operator: undefined,
              values: [{ value: '', propertyId, searchId, valueId }],
            };

            updatedSearch = {
              ...currentSearch,
              predicates: [...(currentSearch.predicates || []), newProperty],
            };
            break;
          }

          case 'REMOVE_PROPERTY': {
            if (!payload.propertyId) {
              break;
            }

            updatedSearch = {
              ...currentSearch,
              predicates: (currentSearch.predicates || []).filter(
                (p: { propertyId: string }) => p.propertyId !== payload.propertyId,
              ),
            };
            break;
          }

          case 'UPDATE_PROPERTY': {
            if (!payload.propertyId) {
              break;
            }

            updatedSearch = {
              ...currentSearch,
              predicates: (currentSearch.predicates || []).map((p) =>
                p.propertyId === payload.propertyId
                  ? {
                      ...p,
                      ...propertyData,
                    }
                  : p,
              ),
            };
            break;
          }

          case 'ADD_VALUE': {
            if (!payload.propertyId) break;
            const newValueId = base64guid();
            const updatedValue = { value: '', propertyId: payload.propertyId, searchId, valueId: newValueId };

            updatedSearch = {
              ...currentSearch,
              predicates: (currentSearch.predicates || []).map((p) =>
                p.propertyId === payload.propertyId
                  ? {
                      ...p,
                      values: [...p.values, updatedValue],
                    }
                  : p,
              ),
            };
            break;
          }

          case 'REMOVE_VALUE': {
            const { propertyId, valueId } = payload;
            if (!propertyId || !valueId) break;

            updatedSearch = {
              ...currentSearch,
              predicates: (currentSearch.predicates || []).map((p) => {
                if (p.propertyId === propertyId) {
                  const updatedValues = p.values.filter((v: { valueId: string }) => v.valueId !== valueId);
                  return { ...p, values: updatedValues };
                }
                return p;
              }),
            };
            break;
          }

          case 'UPDATE_VALUE': {
            if (!payload.propertyId || !payload.valueId) break;

            updatedSearch = {
              ...currentSearch,
              predicates: (currentSearch.predicates || []).map((p) => {
                if (p.propertyId === payload.propertyId) {
                  const updatedValues = p.values.map((v: { valueId: string }) =>
                    v.valueId === payload.valueId ? { ...v, ...newValue } : v,
                  );
                  return { ...p, values: updatedValues };
                }
                return p;
              }),
            };
            break;
          }

          default:
            return;
        }
      }

      this.state.splice('searches', [searchIndex, 1, updatedSearch]);
    },
    ITEM_FINDER_ADD_PROPERTY_SEARCH: (propertySearch: any) => {
      if (propertySearch.searchType !== 'PROPERTY') {
        return;
      }

      const searchId = base64guid();
      const newSearch = {
        ...propertySearch,
        searchId,
      };

      const updatedSearches = [...(this.state.get('searches') || []), newSearch].filter((search) => search.searchType);
      this.state.set('searches', updatedSearches);

      const updatedPredicates = (propertySearch.predicates || []).map((predicate: PropertyFilterInputV1) => {
        const propertyId = base64guid();

        const updatedValues = (predicate.values || []).map((value: PropertyInputV1) => ({
          ...value,
          valueId: base64guid(),
          propertyId,
          searchId,
        }));

        return {
          ...predicate,
          propertyId,
          searchId,
          values: updatedValues,
        };
      });

      const updatedSearchWithPredicates = { ...newSearch, predicates: updatedPredicates };

      this.state.set(
        'searches',
        updatedSearches.map((search: any) =>
          search.searchId === newSearch.searchId ? updatedSearchWithPredicates : search,
        ),
      );
    },
    ITEM_FINDER_ADD_FIXED_LIST_SEARCH: (fixedListSearch: any) => {
      if (fixedListSearch.searchType !== 'FIXED_LIST') {
        return;
      }

      const searchId = base64guid();
      const newSearch = {
        ...fixedListSearch,
        searchId,
      };
      const combinedSearches = [...(this.state.get('searches') || []), newSearch].filter((search) => search.searchType);
      this.state.set('searches', combinedSearches);
    },
  };
}
