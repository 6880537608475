// @ts-strict-ignore
import _ from 'lodash';
import { PREDICTION } from './prediction.constants';
import { adjustPrincipalComponentsToKeep } from '@/utilities/predictionHelper.utilities';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { sqDurationStore } from '@/core/core.stores';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';

export class PredictionPanelStore extends BaseToolStore {
  static readonly storeName = 'sqPredictionPanelStore';
  type = TREND_TOOLS.PREDICTION;
  parameterDefinitions = {
    targetSignal: { predicate: ['name', 'target'] },
    condition: { predicate: ['name', 'condition'] },
    inputSignals: {
      predicate: (parameter) => !_.includes(['target', 'condition'], parameter.name),
      multiple: true,
    },
  };

  initialize() {
    this.state = this.immutable({
      ...BASE_TOOL_COMMON_PROPS,
      option: PREDICTION.SCALE.LINEAR,
      polynomialValue: 2,
      mustGoThroughZero: false,
      lambda: 0,
      windowStart: sqDurationStore.displayRange.start.valueOf(),
      windowEnd: sqDurationStore.displayRange.end.valueOf(),
      window: this.monkey(['windowStart'], ['windowEnd'], (startTime, endTime) => ({ startTime, endTime })),
      modelExpanded: false,
      regressionMethod: PREDICTION.REGRESSION_METHODS.ORDINARY_LEAST_SQUARES,
      variableSelection: 0.05,
      refreshRate: { value: 30, units: 'min' },
      isRefreshRateEnabled: true,
      variableSelectionEnabled: false,
      color: '',
    });
  }

  get color(): string {
    return this.state.get('color');
  }

  get targetSignal() {
    return this.state.get('targetSignal');
  }

  get condition() {
    return this.state.get('condition');
  }

  get inputSignals() {
    return this.state.get('inputSignals');
  }

  get option() {
    return this.state.get('option');
  }

  get polynomialValue() {
    return this.state.get('polynomialValue');
  }

  get mustGoThroughZero() {
    return this.state.get('mustGoThroughZero');
  }

  get lambda() {
    return this.state.get('lambda');
  }

  get principalComponentsToKeep() {
    return adjustPrincipalComponentsToKeep(
      this.state.get('inputSignals'),
      this.state.get('option'),
      this.state.get('polynomialValue'),
      this.state.get('principalComponentsToKeepByUser'),
    );
  }

  get window() {
    return this.state.get('window');
  }

  get modelExpanded() {
    return this.state.get('modelExpanded');
  }

  get regressionMethod() {
    return this.state.get('regressionMethod');
  }

  get variableSelection() {
    return this.state.get('variableSelection');
  }

  get refreshRate() {
    return this.state.get('refreshRate');
  }

  get isRefreshRateEnabled() {
    return this.state.get('isRefreshRateEnabled');
  }

  get variableSelectionEnabled() {
    return this.state.get('variableSelectionEnabled');
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} State for the store
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Sets the prediction panel state
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  /**
   * Set the prediction option that is used in the form.
   *
   * @param {Object} payload - Object container
   * @param {String} payload.option- The prediction option; one of PREDICTION.SCALE
   */
  setOption(payload) {
    this.state.set('option', payload.option);
  }

  /**
   * Set the polynomial value that is used in the form.
   *
   * @param {Object} payload - Object container
   * @param {Number} payload.polynomialValue - The polynomial value
   */
  setPolynomialValue(payload) {
    this.state.set('polynomialValue', payload.polynomialValue);
  }

  /**
   * Set the 'must go through zero' value that is used in the form.
   *
   * @param {Object} payload - Object container
   * @param {Boolean} payload.mustGoThroughZero - the 'must go through zero' options value
   */
  setMustGoThroughZero(payload) {
    this.state.set('mustGoThroughZero', payload.mustGoThroughZero);
  }

  /**
   * Set the lambda value that is used in the form.
   *
   * @param {Object} payload - Object container
   * @param {Number} payload.lambda - the lambda options value
   */
  setLambda(payload) {
    this.state.set('lambda', payload.lambda);
  }

  /**
   * Set the 'principal components to keep' value that is used in the form.
   *
   * @param {Object} payload - Object container
   * @param {Number} payload.principalComponentsToKeep - the 'principal components to keep' options value
   */
  setPrincipalComponentsToKeepByUser(payload) {
    // We need the state change to always be called in case the saved principalComponentsToKeepByUser value is
    // no longer a valid value for the current inputs.
    this.state.set('principalComponentsToKeepByUser', null);
    this.state.set('principalComponentsToKeepByUser', payload.principalComponentsToKeep);
  }

  /**
   * Set the reference window start value that is used in the form.
   *
   * @param {Object} payload - Object container
   * @param {Number} payload.window.startTime - the start of the reference training window
   * @param {Number} payload.window.endTime - the end of the reference training window
   */
  setTrainingWindow(payload) {
    this.state.set('windowStart', _.get(payload.window, 'startTime'));
    this.state.set('windowEnd', _.get(payload.window, 'endTime'));
  }

  /**
   * Set the model expanded state that is used in the form.
   *
   *
   * @param {Object} payload - Object container
   * @param {Boolean} payload.modelExpanded - the model expanded state
   */
  setModelExpanded(payload) {
    this.state.set('modelExpanded', payload.modelExpanded);
  }

  /**
   * Set the regression method that is used in the form.
   *
   * @param {Object} payload - Object container
   * @param {String} payload.regressionMethod - the regression method (one of REGRESSION_METHODS).
   */
  setRegressionMethod(payload) {
    this.state.set('regressionMethod', payload.regressionMethod);
  }

  setRefreshRate(payload) {
    this.state.set('refreshRate', payload.refreshRate);
  }

  /**
   * Set whether refresh rate is enabled or not.
   */
  setIsRefreshRateEnabled(payload) {
    this.state.set('isRefreshRateEnabled', payload.isRefreshRateEnabled);
  }

  /**
   * Set the variable selection that is used in the form.
   *
   * @param {Object} payload - Object container
   * @param {Number} payload.variableSelection - the variable selection (0-1)
   */
  setVariableSelection(payload) {
    this.state.set('variableSelection', payload.variableSelection);
  }

  /**
   * Set whether variable selection is enabled or not.
   *
   * @param {Object} payload - Object container
   * @param {Boolean} payload.variableSelectionEnabled - true of variable selection is enabled, false otherwise
   */
  setVariableSelectionEnabled(payload) {
    this.state.set('variableSelectionEnabled', payload.variableSelectionEnabled);
  }

  localHandlers = {
    PREDICTION_SET_OPTION: this.setOption,
    PREDICTION_SET_POLYNOMIAL_VALUE: this.setPolynomialValue,
    PREDICTION_SET_MUST_GO_THROUGH_ZERO: this.setMustGoThroughZero,
    PREDICTION_SET_LAMBDA: this.setLambda,
    PREDICTION_SET_PRINCIPAL_COMPONENTS_TO_KEEP: this.setPrincipalComponentsToKeepByUser,
    PREDICTION_SET_TRAINING_WINDOW: this.setTrainingWindow,
    PREDICTION_SET_MODEL_EXPANDED: this.setModelExpanded,
    PREDICTION_SET_REGRESSION_METHOD: this.setRegressionMethod,
    PREDICTION_SET_VARIABLE_SELECTION: this.setVariableSelection,
    PREDICTION_SET_REFRESH_RATE: this.setRefreshRate,
    PREDICTION_SET_IS_REFRESH_RATE_ENABLED: this.setIsRefreshRateEnabled,
    PREDICTION_SET_VARIABLE_SELECTION_ENABLED: this.setVariableSelectionEnabled,
    PREDICTION_SET_COLOR: ({ color }: { color: string }) => {
      this.state.set('color', color);
    },
  };

  handlers = _.assign({}, super.baseHandlers, this.localHandlers);
}
