import { PersistenceLevel, Store } from '@/core/flux.service';
import {
  defaultLayoutDefinition,
  ValueCaptureCategory,
  ValueCaptureDashboardContent,
} from '@/valueCapture/valueCapture.types';
import _ from 'lodash';

interface ValueCaptureState {
  tableId: string | undefined;
  forceTableReload: boolean;
  categories: ValueCaptureCategory[] | undefined;
  startDate: Date | null;
  endDate: Date | null;
  layoutDefinition: ValueCaptureDashboardContent[];
  aggregationColumn: string | undefined;
  biggestImpact: number;
  useCaseCount: number;
  overallImpact: number;
  filteredImpact: number;
}

export class ValueCaptureStore extends Store {
  static readonly storeName = 'sqValueCaptureStore';
  persistenceLevel: PersistenceLevel = 'WORKBENCH';

  get tableId(): string | undefined {
    return this.state.get('tableId');
  }

  get startDate(): Date | undefined {
    return this.state.get('startDate');
  }

  get endDate(): Date | undefined {
    return this.state.get('endDate');
  }

  get categories(): ValueCaptureCategory[] | undefined {
    return this.state.get('categories');
  }

  get layoutDefinition(): ValueCaptureDashboardContent[] {
    return this.state.get('layoutDefinition');
  }

  get forceTableReload(): boolean {
    return this.state.get('forceTableReload');
  }

  get aggregationColumn(): string | undefined {
    return this.state.get('aggregationColumn');
  }

  get biggestImpact(): number {
    return this.state.get('biggestImpact');
  }

  get useCaseCount(): number {
    return this.state.get('useCaseCount');
  }

  get overallImpact(): number {
    return this.state.get('overallImpact');
  }

  get filteredImpact(): number {
    return this.state.get('filteredImpact');
  }

  initialize() {
    this.state = this.immutable({
      tableId: undefined,
      categories: undefined,
      startDate: null,
      endDate: null,
      forceTableReload: false,
      layoutDefinition: defaultLayoutDefinition,
      aggregationColumn: undefined,
      biggestImpact: 0,
      useCaseCount: 0,
      overallImpact: 0,
      filteredImpact: 0,
    });
  }

  dehydrate() {
    return _.pick(this.state.serialize(), ['layoutDefinition']);
  }

  rehydrate(dehydratedState: ValueCaptureState) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    VALUE_CAPTURE_SET_TABLE_ID: (payload: { tableId: string }) => {
      this.state.set('tableId', payload.tableId);
    },
    VALUE_CAPTURE_SET_CATEGORIES: (payload: { categories: ValueCaptureCategory[] }) => {
      this.state.set('categories', payload.categories);
    },
    VALUE_CAPTURE_SET_START_DATE: (payload: { startDate: Date }) => {
      this.state.set('startDate', payload.startDate);
    },
    VALUE_CAPTURE_SET_END_DATE: (payload: { endDate: Date }) => {
      this.state.set('endDate', payload.endDate);
    },
    VALUE_CAPTURE_SET_LAYOUT_DEFINITION: (payload: { layoutDefinition: ValueCaptureDashboardContent[] }) => {
      this.state.set('layoutDefinition', payload.layoutDefinition);
    },
    VALUE_CAPTURE_REMOVE_CONTENT: (payload: { i: string }) => {
      this.state.set(
        'layoutDefinition',
        this.layoutDefinition.filter((content: { i: string }) => content.i !== payload.i),
      );
    },
    VALUE_CAPTURE_SET_FORCE_TABLE_RELOAD: (payload: { forceTableReload: boolean }) => {
      this.state.set('forceTableReload', payload.forceTableReload);
    },
    VALUE_CAPTURE_SET_AGGREGATION_COLUMN: (payload: { aggregationColumn: string }) => {
      this.state.set('aggregationColumn', payload.aggregationColumn);
    },
    VALUE_CAPTURE_SET_BIGGEST_IMPACT: (payload: { biggestImpact: number }) => {
      this.state.set('biggestImpact', payload.biggestImpact);
    },
    VALUE_CAPTURE_SET_USE_CASE_COUNT: (payload: { useCaseCount: number }) => {
      this.state.set('useCaseCount', payload.useCaseCount);
    },
    VALUE_CAPTURE_SET_OVERALL_IMPACT: (payload: { overallImpact: number }) => {
      this.state.set('overallImpact', payload.overallImpact);
    },
    VALUE_CAPTURE_SET_FILTERED_IMPACT: (payload: { filteredImpact: number }) => {
      this.state.set('filteredImpact', payload.filteredImpact);
    },
  };
}
