import { SeeqNames } from '@/main/app.constants.seeqnames';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { SeeqInternalAxiosRequestConfig } from '@/requests/axios.utilities';
import { getOriginLabel, getOriginUrl } from '@/utilities/http.utilities';
import { headlessCaptureMetadata } from '@/utilities/screenshot.utilities';
import { sqWorkbookStore } from '@/core/core.stores';

export function requestOriginInterceptor(config: SeeqInternalAxiosRequestConfig) {
  if (config.headers) {
    let header = '';
    if (headlessRenderMode()) {
      header = headlessCaptureMetadata().category ?? '';
    } else if (sqWorkbookStore.isReportBinder) {
      header = SeeqNames.Consumption.Topic;
    } else {
      header = 'Analysis';
    }
    config.headers[SeeqNames.API.Headers.RequestOrigin] = header;
    config.headers[SeeqNames.API.Headers.RequestOriginURL] = getOriginUrl();
    config.headers[SeeqNames.API.Headers.RequestOriginLabel] = getOriginLabel();
  }
  return config;
}
