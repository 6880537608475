// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { RainbowLegendItems } from '@/trend/RainbowLegendItems.molecule';
import { SignalGradientLegendItems } from '@/trend/SignalGradientLegendItems.molecule';
import { ConditionGradientLegendItems } from '@/trend/ConditionGradientLegendItems.molecule';
import { decorate } from '@/trend/trendViewer/itemDecorator.utilities';
import { useTranslation } from 'react-i18next';
import { getColumnValueAndUOM } from '@/utilities/columnHelper.utilities';
import { CapsuleTimeColorMode, PropertyColumn, TREND_VIEWS } from '@/trendData/trendData.constants';
import { isTimestampVisible } from '@/utilities/utilities';
import { RangeExport } from '@/trendData/duration.store';

interface CapsuleTimeLegendIF {
  view: TREND_VIEWS;
  capsuleTimeColorMode: CapsuleTimeColorMode;
  sortedColumn: PropertyColumn;
  capsules: any[]; // sqTrendCapsuleStore.items
  conditions: { id: string; color: string; name: string }[];
  displayRange: RangeExport;
}

export const CapsuleTimeLegend: React.FunctionComponent<CapsuleTimeLegendIF> = ({
  capsules,
  conditions,
  capsuleTimeColorMode,
  view,
  sortedColumn,
  displayRange,
}) => {
  const { t } = useTranslation();

  const isDisplayed = view === TREND_VIEWS.CAPSULE && capsuleTimeColorMode !== CapsuleTimeColorMode.Signal;
  const title = _.compact([_.get(sortedColumn, 'series.name'), t(sortedColumn.title)]).join(' ');
  const getCapsuleName = (capsule) => getColumnValueAndUOM(sortedColumn, capsule);

  const renderLegendItems = () => {
    const displayStart = displayRange.start.valueOf();
    const displayEnd = displayRange.end.valueOf();
    let filteredCapsules = decorate({
      items: _.reject(capsules, (capsule) => !isTimestampVisible(capsule.startTime, displayStart, displayEnd)),
    }) as any[];

    if (capsuleTimeColorMode !== CapsuleTimeColorMode.ConditionGradient) {
      filteredCapsules = _.reject(
        filteredCapsules,
        (capsule) => _.isUndefined(capsule.childColor) || _.isEmpty(getCapsuleName(capsule)),
      );
    }

    switch (capsuleTimeColorMode) {
      case CapsuleTimeColorMode.Rainbow:
        return <RainbowLegendItems capsules={filteredCapsules} getCapsuleName={getCapsuleName} title={title} />;
      case CapsuleTimeColorMode.SignalGradient:
        return <SignalGradientLegendItems capsules={filteredCapsules} getCapsuleName={getCapsuleName} title={title} />;
      case CapsuleTimeColorMode.ConditionGradient:
        return <ConditionGradientLegendItems capsules={filteredCapsules} conditions={conditions} />;
    }
  };

  return isDisplayed && renderLegendItems();
};
