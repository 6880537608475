import React from 'react';
import { useTranslation } from 'react-i18next';
import { DurationSelector, DurationValuesByUnit } from '@/core/DurationSelector.atom';
import { LiveUpdateRate } from '@/schedule/schedule.types';

interface LiveScheduleProps {
  rate: LiveUpdateRate;
  onChange: (liveUpdateRate: LiveUpdateRate) => void;
  onInvalidInput: (message: string) => void;
  showHelp?: boolean;
  durationValuesByUnit: DurationValuesByUnit;
}

export const LiveSchedule: React.FunctionComponent<LiveScheduleProps> = ({
  rate: { unit, value },
  onChange,
  onInvalidInput,
  showHelp = true,
  durationValuesByUnit,
}) => {
  const { t } = useTranslation();

  return (
    <div data-testid="live-schedule-config" className="mb15">
      <label>{t('SCHEDULE_TYPE.LIVE.RATE.NAME')}</label>
      {showHelp && <p className="text-italic sq-darkish-gray">{t('SCHEDULE_TYPE.LIVE.RATE.DESCRIPTION')}</p>}
      <DurationSelector
        unit={unit}
        value={value}
        onChange={(value, unit) => onChange({ value, unit })}
        onInvalidInput={onInvalidInput}
        durationValuesByUnit={durationValuesByUnit}
      />
    </div>
  );
};
