import { flux } from '@/core/flux.module';
import { RowRulesResultV1, RulesResultV1, TableDefinitionOutputV1 } from '@/sdk';
import {
  MaterializedTableItemColumnPropertyPair,
  MaterializedTableOutput,
  ScalingTableColumnDefinition,
} from '@/tableDefinitionEditor/tableDefinition.types';
import { GridApi } from '@ag-grid-community/core';
import { BatchActionEnum } from 'sdk/model/TableDefinitionInputV1';
import { SearchTypeEnum } from 'sdk/model/PropertySearchV1';

export function setSubscriptionId(id: string | undefined) {
  flux.dispatch('TABLE_DEFINITION_SET_SUBSCRIBER_ID', { subscriberId: id });
}

export function setShouldSearchForItems(shouldSearchForItems: boolean) {
  flux.dispatch('TABLE_DEFINITION_SET_SHOULD_SEARCH_FOR_ITEMS', { shouldSearchForItems });
}

export function setName(name: string) {
  flux.dispatch('TABLE_DEFINITION_SET_NAME', { name });
}

export function setSearchTypeAI(searchTypeAI: SearchTypeEnum | undefined) {
  flux.dispatch('TABLE_DEFINITION_SET_SEARCH_TYPE_AI', { searchTypeAI });
}

export function setDescription(description: string) {
  flux.dispatch('TABLE_DEFINITION_SET_DESCRIPTION', { description });
}

export function setBatchAction(batchAction: BatchActionEnum) {
  flux.dispatch('TABLE_DEFINITION_SET_BATCH_ACTION', { batchAction });
}

export function setTableDefinition(output: TableDefinitionOutputV1) {
  flux.dispatch('TABLE_DEFINITION_SET_TABLE_DEFINITION', { tableDefinition: output });
}

export function resetTableDefinition() {
  flux.dispatch('TABLE_DEFINITION_RESET');
}

export function setMaterializedTable(materializedTable: MaterializedTableOutput | undefined) {
  flux.dispatch('TABLE_DEFINITION_SET_MATERIALIZED_TABLE', { materializedTable });
}

export function setDoTableReload(doTableReload: boolean) {
  flux.dispatch('TABLE_DEFINITION_SET_DO_TABLE_RELOAD', { doTableReload });
}

export function setDisplayPropertyForColumn(uuidColumnToPropertyNamePair: MaterializedTableItemColumnPropertyPair) {
  flux.dispatch('TABLE_DEFINITION_SET_PROPERTY_NAME_FOR_UUID_COLUMN', { uuidColumnToPropertyNamePair });
}

export function addPendingTableId(pendingTableId: string) {
  flux.dispatch('TABLE_DEFINITION_ADD_PENDING_TABLE_ID', { pendingTableId });
}

export function removePendingTableId(pendingTableId: string) {
  flux.dispatch('TABLE_DEFINITION_REMOVE_PENDING_TABLE_ID', { pendingTableId });
}

export function setEvaluationResults(evaluationResults: { [key: string]: RowRulesResultV1[] }) {
  flux.dispatch('TABLE_DEFINITION_SET_EVALUATION_RESULTS', { evaluationResults });
}

export function setShowEvaluationDetailModal(show: boolean) {
  flux.dispatch('TABLE_DEFINITION_SET_SHOW_EVALUATION_DETAIL_MODAL', { show });
}

export function setEvaluationResultsToView(evaluationResults: RulesResultV1[]) {
  flux.dispatch('TABLE_DEFINITION_SET_EVALUATION_RESULTS_TO_VIEW', { evaluationResults });
}

export function setEvaluationDetailColumn(column: ScalingTableColumnDefinition | undefined) {
  flux.dispatch('TABLE_DEFINITION_SET_EVALUATION_DETAIL_COLUMN', { column });
}

export function setCreatedItemFinderId(id: string | undefined) {
  flux.dispatch('TABLE_DEFINITION_SET_CREATED_ITEM_FINDER_ID', { createdItemFinderId: id });
}

export function setAllowHiddenColumnsAsInputs(allowHiddenColumnsAsInputs: boolean) {
  flux.dispatch('TABLE_DEFINITION_SET_ALLOW_HIDDEN_COLUMNS_AS_INPUTS', { allowHiddenColumnsAsInputs });
}

export function setAgGridApi(agGridApi: GridApi) {
  flux.dispatch('TABLE_DEFINITION_SET_AG_GRID_API', { agGridApi });
}
