import { PersistenceLevel, Store } from '@/core/flux.service';
import { AnyProperty } from '@/utilities.types';

export class VantageTrendStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqVantageTrendStore';

  initialize(): void {
    this.state = this.immutable({
      quickTrendOpen: false,
      isSingleAxis: true,
      lookback: 0,
    });
  }

  get quickTrendOpen(): boolean {
    return this.state.get('quickTrendOpen');
  }

  get isSingleAxis(): boolean {
    return this.state.get('isSingleAxis');
  }

  get lookback(): number {
    return this.state.get('lookback');
  }

  dehydrate() {
    return this.state.serialize();
  }

  rehydrate(dehydratedState: AnyProperty) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    SET_VANTAGE_QUICK_TREND_OPEN: (payload: { isOpen: boolean }) => this.state.set('quickTrendOpen', payload.isOpen),
    SET_VANTAGE_TREND_SINGLE_AXIS: (payload: { isSingleAxis: boolean }) =>
      this.state.set('isSingleAxis', payload.isSingleAxis),
    SET_VANTAGE_TREND_LOOKBACK: (payload: { lookback: number }) => this.state.set('lookback', payload.lookback),
  };
}
