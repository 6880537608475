import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core/dist/types/src/rendering/cellRenderers/iCellRenderer';
import { Icon } from '@seeqdev/qomponents';
import { availableStages, stageIcons, Stages } from '@/valueCapture/valueCapture.types';
import { stageDisplayStringByIndex } from '@/valueCapture/valueCapture.utilities';

/**
 * This cell-renderer renders a little icon next to the use case status to indicate the current stage of the use case.
 */
export const UseCaseStatusCellRenderer: React.FunctionComponent<ICellRendererParams<any, string>> = (props) => {
  if (!props?.value) return null;
  const { value } = props;
  if (!value) return null;
  let stageIndex = availableStages.indexOf(value?.trim().toLowerCase() as unknown as Stages);

  if (stageIndex < 0) stageIndex = 0;

  return stageIndex >= 0 ? (
    <div className="flexColumnContainer flexAlignCenter">
      <Icon icon={stageIcons[stageIndex]} extraClassNames="mr5" />
      {stageDisplayStringByIndex(stageIndex)}
    </div>
  ) : null;
};
