import _ from 'lodash';
import {
  TREND_VIEWS,
  TrendColorMode,
  UsedCapsulePropertyValues,
  UserCapsulePropertyColorSettings,
} from '@/trendData/trendData.constants';
import { sqTrendCapsuleStore, sqTrendConditionStore, sqTrendStore, sqWorksheetStore } from '@/core/core.stores';
import { PUSH_IGNORE } from '@/core/flux.service';
import { flux } from '@/core/flux.module';
import { exposedForTesting } from '@/trendData/trend.actions';
import { CapsuleItem } from '@/trendData/trendData.types';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { getGradientColors } from '@/trendData/color.utilities';

const DEFAULT_FROM_COLOR = '#FFC107';
const DEFAULT_TO_COLOR = '#1E88E5';

/**
 * Colors the capsules in the trend capsule store based on the provided mode and property.
 * If the mode is set to CapsuleProperty, the capsules will be colored based on the provided property, either
 * picking a random color or using the already existing one based on the user's previously generated/set colors.
 * If the mode is set to Item, the capsules will be colored based on the condition they are associated with.
 *
 * Note: this has to be called after fetching the capsules to ensure that we populate the colors correctly on load
 *
 * @param colorMode - The mode we're coloring under
 * @param colorByCapsuleProperty - The property we're coloring by, if set
 * @param skipRecalculation - Whether to skip recalculation of the colors.
 */
export function setTrendColorMode(
  colorMode: TrendColorMode = sqTrendStore.trendColorSettings.colorMode,
  colorByCapsuleProperty: string | undefined = sqTrendStore.trendColorSettings.colorByCapsuleProperty,
  skipRecalculation = false,
) {
  if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TREND || sqTrendStore.view === TREND_VIEWS.CAPSULE) {
    return;
  }

  // If we're coloring by item mode we only need to update the capsule colors if we're transitioning out of another
  // color mode. This lets us skip the expensive capsule processing.
  if (colorMode === TrendColorMode.Item && sqTrendStore.trendColorSettings.colorMode === TrendColorMode.Item) {
    return;
  }

  const colorSettings = sqTrendStore.trendColorSettings;
  const pushMode =
    colorMode === colorSettings.colorMode && colorSettings.colorByCapsuleProperty === colorByCapsuleProperty
      ? PUSH_IGNORE
      : undefined;

  flux.dispatch('TREND_SET_TREND_COLOR_SETTINGS', { colorMode, colorByCapsuleProperty }, pushMode);
  if (skipRecalculation || (colorMode !== TrendColorMode.Item && colorByCapsuleProperty === undefined)) {
    // Haven't selected a capsule property yet, just abort for simplicity
    return;
  }
  const capsules = sqTrendCapsuleStore.items;

  const capsulePropertyColors = _.cloneDeep(sqTrendCapsuleStore.trendCapsulePropertyColors);

  const allValues = new Set<string | number | boolean>();

  let gradientColorMap: Record<string, string> | undefined;
  if (colorMode === TrendColorMode.CapsulePropertyGradient) {
    if (!capsulePropertyColors[colorByCapsuleProperty!]) {
      capsulePropertyColors[colorByCapsuleProperty!] = {};
    }

    if (!capsulePropertyColors.seeqColorGradient) {
      capsulePropertyColors.seeqColorGradient = {
        from: DEFAULT_FROM_COLOR,
        to: DEFAULT_TO_COLOR,
      };
    }

    const gradientColors = capsulePropertyColors.seeqColorGradient!;
    const valuesForCurrentProperty = sqTrendCapsuleStore.getValuesForProperty(colorByCapsuleProperty!);
    gradientColorMap = _.zipObject(
      valuesForCurrentProperty,
      getGradientColors(gradientColors, valuesForCurrentProperty.length),
    );
  }

  const computeColor = (capsule: CapsuleItem): string | undefined => {
    switch (colorMode) {
      case TrendColorMode.Item:
        return undefined;
      case TrendColorMode.CapsuleProperty:
        if (!colorByCapsuleProperty) {
          throw new Error('No capsule property specified for capsule property color mode');
        } else if (capsule.properties?.[colorByCapsuleProperty] !== undefined) {
          const capsulePropertyValue = capsule.properties[colorByCapsuleProperty].toString();
          allValues.add(capsulePropertyValue);
          if (!capsulePropertyColors[colorByCapsuleProperty]) {
            capsulePropertyColors[colorByCapsuleProperty] = {};
          }

          if (!capsulePropertyColors[colorByCapsuleProperty][capsulePropertyValue]) {
            capsulePropertyColors[colorByCapsuleProperty][capsulePropertyValue] = sqTrendCapsuleStore.getItemColor();
          }

          return capsulePropertyColors[colorByCapsuleProperty][capsulePropertyValue];
        }

        // Fallback, use the condition color since no properties match
        return sqTrendConditionStore.findItem(capsule.capsuleSetId).color;
      case TrendColorMode.CapsulePropertyGradient:
        if (!colorByCapsuleProperty) {
          throw new Error('No capsule property specified for capsule property color mode');
        } else if (capsule.properties?.[colorByCapsuleProperty] !== undefined) {
          const capsulePropertyValue = capsule.properties[colorByCapsuleProperty];
          allValues.add(capsulePropertyValue);
          return gradientColorMap![capsulePropertyValue.toString()];
        }
        // Fallback, use the condition color since no properties match
        return sqTrendConditionStore.findItem(capsule.capsuleSetId).color;
    }
  };

  _.forEach(capsules, (capsule) => {
    exposedForTesting.setTrendItemColor(capsule.id, computeColor(capsule));
  });

  flux.dispatch('TREND_RECOMPUTE_CHART_CAPSULES');
  if (colorMode === TrendColorMode.CapsuleProperty || colorMode === TrendColorMode.CapsulePropertyGradient) {
    setTrendCapsulePropertyColors(capsulePropertyColors);
    setUsedCapsulePropertyValues(
      _.chain([...allValues])
        .sortBy()
        .map((value) => value.toString())
        .value(),
    );
  }
}

export function setTrendCapsulePropertyColors(trendCapsulePropertyColors: UserCapsulePropertyColorSettings): void {
  flux.dispatch('SET_TREND_CAPSULE_PROPERTY_COLORS', { trendCapsulePropertyColors });
}

export function setUsedCapsulePropertyValues(usedCapsulePropertyValues: UsedCapsulePropertyValues): void {
  flux.dispatch('SET_USED_CAPSULE_PROPERTY_VALUES', { usedCapsulePropertyValues });
}

export function setTrendCapsuleColor(capsuleProperty: string, capsuleValue: string, color: string) {
  const colors = _.cloneDeep(sqTrendCapsuleStore.trendCapsulePropertyColors);
  colors[capsuleProperty] = colors[capsuleProperty] || {};
  colors[capsuleProperty][capsuleValue] = color;
  setTrendCapsulePropertyColors(colors);
  setTrendColorMode();
}
