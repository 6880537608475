// @ts-strict-ignore
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { getYAxisConfig } from '@/trendData/yAxis.actions';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import { getSeriesIdsByCalculationType } from '@/trend/trendDataHelper.utilities';
import { getCapsuleDuration } from '@/utilities/utilities';
import {
  DASH_STYLES,
  ITEM_CHILDREN_TYPES,
  ITEM_DATA_STATUS,
  ITEM_TYPES,
  PREVIEW_ID,
  PREVIEW_PREFIX,
  SAMPLE_OPTIONS,
  Y_AXIS_TYPES,
} from '@/trendData/trendData.constants';
import { getDefaultCompareViewPropertyValue } from '@/services/compareView.utilities';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { sqDurationStore, sqTrendCapsuleStore, sqTrendStore } from '@/core/core.stores';
import { BaseItemStore } from './baseItem.store';
import { COMMON_PROPS } from './baseItem.constants';
import {
  createStringEnum,
  createUniqueCapsuleSeriesId,
  getDataProps,
  getMinTime,
  transformDataPoints,
} from './trendSeries.utilities';
import { SeriesItem } from '@/trendData/trendData.types';

/**
 * A store for containing timeseries which can be displayed on the chart. A series is composed of an array of data
 * points and their associated timestamps.
 *
 * This store is augmented with additional functionality from BaseItemStore.
 */
export class TrendSeriesStore extends BaseItemStore<SeriesItem> {
  static readonly storeName = 'sqTrendSeriesStore';

  initialize() {
    this.state = this.immutable(
      _.defaults(
        {
          // Avoid clearing state that is not dehydrated when the store is re-initialized. Async calls will repopulate
          // these properties as needed
          previewChartItem: this.state ? this.state.get('previewChartItem') : undefined,
          // all series that are not converted from capsules
          primarySeries: this.monkey(['items'], (items) => _.filter(items, (item) => !item.isChildOf)),
          itemsAndPreview: this.monkey(
            ['items'],
            ['editingId'],
            ['previewSeriesDefinition'],
            ['previewChartItem'],
            (items: SeriesItem[], editingId: string, previewSeriesDefinition: any[], previewChartItem: any) => {
              let allSeries: any[];
              const series = _.clone(items);
              const displayPreview = !_.isEmpty(previewSeriesDefinition);

              if (!_.isUndefined(editingId) && !_.isNull(editingId) && displayPreview) {
                const index = _.findIndex(series, { id: editingId });
                series.splice(index, 1, previewChartItem);
                allSeries = series;
              } else if (displayPreview) {
                allSeries = series.concat(previewChartItem);
              } else {
                allSeries = series;
              }

              allSeries = _.chain(allSeries).flatten().compact().value();

              return allSeries;
            },
          ),
        },
        COMMON_PROPS,
      ),
    );
  }

  /** Generates capsule series.
   *
   * Use the `useCapsuleSeries` hook in components to minimize re-renders */
  get capsuleSeries() {
    return this.getCapsuleSeries();
  }

  get nonCapsuleSeries() {
    return this.getNonCapsuleSeries();
  }

  get primarySeries() {
    return this.state.get('primarySeries');
  }

  /**
   * Gets the longest capsule series duration
   *
   * @return The longest capsule series duration or zero if there are no capsule series.
   */
  get longestCapsuleSeriesDuration(): number {
    // Be sure to include in progress capsules
    let longestInProgress = 0;
    const inProgressCapsuleSeries = _.filter(this.getCapsuleSeries(), (series) => _.isNil(series.duration));
    if (!_.isEmpty(inProgressCapsuleSeries)) {
      const earliestStart: Moment = _.min(_.map(inProgressCapsuleSeries, 'startTime'));
      longestInProgress = moment.duration(this.getAlternateEndTime() - earliestStart.valueOf()).asMilliseconds();
    }
    return _.max([longestInProgress, _.get(_.maxBy(this.getCapsuleSeries(), 'duration'), 'duration', 0)]);
  }

  /**
   * Returns the Id of the series that is being edited.
   */
  get editingId() {
    return this.state.get('editingId');
  }

  /**
   * Returns an Object describing a series (so that it can be updated upon scrolling)
   */
  get previewSeriesDefinition() {
    return this.state.get('previewSeriesDefinition');
  }

  /**
   * Returns the Store.nonCapusleSeries with the preview added
   */
  get nonCapsuleSeriesAndPreview() {
    return this.addPreview(this.getNonCapsuleSeries());
  }

  /**
   * Returns all the Store.items with the preview added
   */
  get itemsAndPreview() {
    return this.state.get('itemsAndPreview');
  }

  /**
   * Returns the preview series
   */
  get previewChartItem() {
    return this.state.get('previewChartItem');
  }

  /**
   * Updates series from uncertain capsules based on the input map of old uncertain capsule ids to new replace
   * capsules. Id, start, end, and duration of capsules will be updated.
   *
   * @param {Object} map - A map of uncertain capsule ids to their replacement capsules
   */
  syncUncertainCapsuleSeries(map) {
    _.forEach(map, (newCapsule, oldCapsuleId) => {
      const series = _.filter(this.state.get('items'), ['capsuleId', oldCapsuleId]);
      _.forEach(series, (singleSeries) => {
        const index = this.findItemIndex(singleSeries.id);
        if (index > -1) {
          this.state.merge(['items', index], {
            id: createUniqueCapsuleSeriesId(newCapsule.id, singleSeries.interestId),
            capsuleId: newCapsule.id,
            startTime: newCapsule.startTime,
            endTime: newCapsule.endTime,
            duration: getCapsuleDuration(newCapsule.startTime, newCapsule.endTime),
            otherChildrenOf: [newCapsule.isChildOf, newCapsule.id],
          });
        }
      });
    });
  }

  protected readonly handlers = {
    ...super.baseHandlers,
    TREND_ADD_SERIES: this.addSeries,
    TREND_ADD_SERIES_FROM_CAPSULE: this.addSeriesFromCapsule,
    TREND_UNSELECT_ALL_CAPSULES: this.unselectAllCapsuleSeries,
    TREND_REMOVE_SERIES_FROM_CAPSULE: this.removeSeriesFromCapsule,
    TREND_SERIES_RESULTS_SUCCESS: this.addData,
    TREND_MULTIPLE_SERIES_DATA_RESULTS_SUCCESS: this.addSignalSegmentsData,
    TREND_SERIES_CLEAR_DATA: this.clearData,
    TREND_SET_EDITING_SERIES_ID: this.setEditingId,
    TREND_SET_CHART_SERIES_PREVIEW: this.setPreviewChartItem,
    TREND_REMOVE_CHART_SERIES_PREVIEW: this.removePreviewChartItem,
    TREND_REMOVE_ALL_CAPSULE_SERIES: this.removeAllCapsuleSeries,
  };

  /**
   * Adds a series item.
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.id - ID of the new item
   * @param {String} payload.name - Name of the new item
   * @param {String} payload.lane - Lane of the new item
   * @param {String} payload.alignment - Alignment of the new item
   * @param {String} [payload.color] - Color hex code (e.g. #CCCCCC)
   */
  private addSeries(payload) {
    this.state.push(
      'items',
      this.createSeries(
        payload.id,
        payload.name,
        payload.lane,
        payload.alignment,
        _.pick(payload, ['color', 'interpolationMethod.value']),
      ),
    );
  }

  /**
   * Private helper function to add a series to the store using the specified properties.
   *
   * @param {String} id - ID to use for the new series
   * @param {String} name - Name to use for the new series
   * @param {String} lane - Lane to use for the new series
   * @param {String} alignment - Alignment to use for the new series
   * @param {any} props - Object containing properties to apply to the new series
   *
   * @returns {Object} Newly created series object.
   */
  private createSeries(id: string, name: string, lane: string, alignment: string, props: any) {
    return this.createItem(
      id,
      name,
      ITEM_TYPES.SERIES,
      _.assign(
        {
          data: [],
          samples: [],
          isStringSeries: false,
          yAxisConfig: {},
          yAxisType: Y_AXIS_TYPES.LINEAR,
          dashStyle: _.get(props, 'dashStyle', DASH_STYLES.SOLID),
          statistics: {},
          lane,
          axisAlign: alignment,
          axisVisibility: _.get(props, 'axisVisibility', true),
          axisAutoScale: _.get(props, 'axisAutoScale', true),
          lineWidth: _.get(props, 'lineWidth', 1),
          sampleDisplayOption: props.sampleDisplayOption || SAMPLE_OPTIONS.LINE,
          dataStatus: ITEM_DATA_STATUS.INITIALIZING,
          statusMessage: '',
        },
        props,
      ),
    );
  }

  /**
   * Creates a series item from a capsule item, and adds the new series item to store. The created
   * series item is created with a new guid and the name property as the capsule name concatenated
   * with the interest (i.e. series) name. Properties from the capsule and other properties copied
   * from the capsule and interest.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Object} payload.capsule - Capsule from which to create a series.
   * @param {String} payload.capsule.id - ID of the capsule, to use as the ID of the created series.
   * @param {String} payload.capsule.name - Name of the capsule, to use as the name of the created series.
   * @param {String} payload.capsule.similarity - Profile search capsule property to copy to
   *   the created series.
   * @param {Object} payload.interest - Interest item from the capsule to use as a source for some properties.
   * @param {String} payload.interest.id - ID of the interest.
   */
  private addSeriesFromCapsule(payload) {
    this.state.push(
      'items',
      this.createSeries(
        createUniqueCapsuleSeriesId(payload.capsule.id, payload.interest.id),
        payload.interest.name,
        payload.interest.lane,
        payload.interest.axisAlign,
        {
          startTime: payload.capsule.startTime,
          // Without this, series with no endTime were displayed “invisibly” on the chart. Hover would show points,
          // but the trend line would not appear. Though we had tried to avoid using this big hammer, in the end it
          // was necessary. CRAB-18916
          endTime: payload.capsule.endTime ? payload.capsule.endTime : this.getAlternateEndTime(),
          duration: payload.capsule.duration,
          capsuleId: payload.capsule.id,
          interestId: payload.interest.id,
          isChildOf: payload.interest.id,
          column: getDefaultCompareViewPropertyValue(payload.capsule.properties?.[sqTrendStore.separateByProperty]),
          colorBy: getDefaultCompareViewPropertyValue(payload.capsule.properties?.[sqTrendStore.colorByProperty]),
          otherChildrenOf: [payload.capsule.isChildOf, payload.capsule.id],
          childType: ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE,
          assets: payload.interest.assets,
          selected: payload.capsule.selected,
          capsuleSegmentData: [],
          color: payload.interest.color,
          formatOptions: payload.interest.formatOptions,
          sampleDisplayOption: payload.interest.sampleDisplayOption,
          dashStyle: payload.interest.dashStyle,
          axisVisibility: payload.interest.axisVisibility,
          axisAutoScale: payload.interest.axisAutoScale,
          lineWidth: payload.interest.lineWidth,
          rightAxis: payload.interest.rightAxis,
          showDataLabels: payload.interest.showDataLabels,
        },
      ),
    );
  }

  /**
   * Handles unselecting all capsuleSeries. This is done because selection status of capsuleSeries is kept in sync
   * with capsules.
   */
  private unselectAllCapsuleSeries() {
    _.forEach(this.getCapsuleSeries(), (item) => this.setProperty(item.id, 'selected', false));
  }

  /**
   * Removes any series that were added from a capsule item and interest series.
   *
   * @param {Object} payload - Object container for arguments
   * @param payload.seriesFromCapsules - an array of series from capsules to be removed
   */
  private removeSeriesFromCapsule(payload: { seriesFromCapsules: { capsuleId: string; interestId: string }[] }) {
    const items = _.filter(this.getCapsuleSeries(), (seriesFromCapsule) =>
      _.some(payload.seriesFromCapsules, {
        capsuleId: seriesFromCapsule.capsuleId,
        interestId: seriesFromCapsule.interestId,
      }),
    );
    this.removeItems({ items });
  }

  /**
   * Adds series data points to a series item.
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.id - ID of the item on which to add samples
   * @param {Object[]} payload.samples - Array of data points
   * @param {Number} payload.samples[].key - x-value of a data point
   * @param {Number} payload.samples[].value - y-value of a data point
   * @param {Boolean} payload.samples[].isUncertain - true if this data point is uncertain
   * @param {String} payload.valueUnitOfMeasure - Unit of Measure to be displayed
   * @param {Number} payload.warningCount - the count of warnings that will be passed to the base item store
   * @param {Object[]} payload.warningLogs - the log of warnings that will be passed to the base item store
   * @param setDataStatus - when false, it skips the data status set. Useful to avoid multiple calls when many
   * capsules are added.
   */
  private addData(payload, setDataStatus = true) {
    const cursor = this.getItemCursor(payload.id);
    let minTime;

    if (!cursor.exists()) {
      return;
    }

    const hasBounds = !_.isNil(cursor.get('shadedAreaLower')) && !_.isNil(cursor.get('shadedAreaUpper'));

    if (setDataStatus) {
      this.setDataStatusPresent(_.pick(payload, ['id', 'warningCount', 'warningLogs']));
    }

    minTime = getMinTime(cursor);
    cursor.merge(
      getDataProps({
        payload: _.assign(payload, { color: cursor.get('color') }),
        sampleDisplayOption: cursor.get('sampleDisplayOption'),
        minTime,
        hasBounds,
      }),
    );

    // Ensure that the trend is displayed within it's lane when it first appears on the chart
    if (_.find(this.addPreview(this.getNonCapsuleSeries()), ['id', payload.id])) {
      cursor.merge(getYAxisConfig(payload.id));
    }
  }

  /**
   * Analyzes the payload, extracts the data for each capsule series and updates the store. The complete data for
   * each capsule is taken from the payload, even if the capsules overlaps. The formula used to retrieve the data
   * is created using {@code buildSignalSegmentsFormula}.
   * @param {Capsule[]} requestCapsules - Information about the capsules used to request data.
   * @param {Object[]} samples - Array of data points
   */
  private addSignalSegmentsData({
    id,
    requestCapsules,
    samples: allSamples,
    valueUnitOfMeasure,
    timingInformation,
    meterInformation,
    interpolationMethod,
  }) {
    // Apply data for segments to main signal
    this.addData({
      id,
      valueUnitOfMeasure,
      timingInformation,
      meterInformation,
      interpolationMethod,
      samples: allSamples,
    });

    const capsuleSeries = this.getCapsuleSeries();
    const stringEnum = createStringEnum(allSamples);

    _.forEach(requestCapsules, ({ interestId, capsuleId, startTime, endTime }) => {
      const capsule: any = _.find(capsuleSeries, { interestId, capsuleId });
      if (!_.isEmpty(capsule)) {
        let startIndex = _.sortedIndexBy(
          allSamples,
          {
            key: startTime * NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
          },
          'key',
        );
        startIndex += _.has(allSamples[startIndex], 'value') ? 0 : 1;

        let endIndex = _.sortedIndexBy(
          allSamples,
          { key: endTime * NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND },
          'key',
        );

        // "endIndex + 1" because we want the element from the endIndex position
        const samples = allSamples.slice(startIndex, endIndex + 1);
        const payload = _.assign({
          id: capsule.id,
          samples,
          valueUnitOfMeasure,
          timingInformation,
          meterInformation,
          interpolationMethod,
          stringEnum,
        });

        // addData without updating the capsule alignment. We do it once at the end
        this.addData(payload, false);
      }
    });
  }

  /**
   * Clears series data.
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.id - ID of the item to clear
   */
  private clearData(payload) {
    const cursor = this.getItemCursor(payload.id);
    if (cursor.exists()) {
      cursor.set('data', []);
    }
  }

  /**
   * Sets the id of the Series being edited.
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.id - the id.
   */
  private setEditingId(payload) {
    this.state.set('editingId', payload.id);
    this.state.set('previewSeriesDefinition', {});
  }

  /**
   * Sets the preview series so they can be displayed. Also stores an Object that defines how to generate those
   * preview series so they can be updated when the users scrolls.
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.formula - formula used to create the series
   * @param {Object[]} payload.parameters - Object array describing the parameters used to create the series
   * @param {String} payload.id - ID of the existing item
   * @param {String} payload.name - Name to use for the new series
   * @param {Object[]} payload.data - Array of data points
   * @param {Number} payload.samples[].key - x-value of a data point
   * @param {Number} payload.samples[].value - y-value of a data point
   * @param {Boolean} payload.samples[].isUncertain - true if this data point is uncertain
   * @param {String} payload.valueUnitOfMeasure - Unit of Measure to be displayed
   * @param {String} payload.lane - Lane of the new item
   * @param {String} payload.alignment - Alignment of the new item
   * @param {String} payload.color - Color hex code (e.g. #CCCCCC)
   */
  private setPreviewChartItem(payload) {
    const previewPayloadId = _.startsWith(payload.id, PREVIEW_PREFIX) ? payload.id : PREVIEW_PREFIX + payload.id;
    const previewId = payload.id && payload.id !== PREVIEW_ID ? previewPayloadId : PREVIEW_ID;
    const sampleDisplayOption = payload.sampleDisplayOption || SAMPLE_OPTIONS.LINE;
    if (!_.isEmpty(payload.samples)) {
      this.state.set('previewSeriesDefinition', {
        formula: payload.formula,
        parameters: payload.parameters,
        id: previewId,
        color: payload.color,
        sampleDisplayOption,
      });

      const props = getDataProps({ payload, sampleDisplayOption });

      if (!_.isNull(payload.color)) {
        _.set(props, 'color', payload.color);
      }

      const previewItem = this.createSeries(previewId, payload.name, payload.lane, payload.alignment, props);
      this.state.set('previewChartItem', previewItem);
      this.state.merge('previewChartItem', getYAxisConfig(previewId));
    } else {
      this.state.set('previewSeriesDefinition', {});
    }
  }

  /**
   * Removes the previewChartItem.
   */
  private removePreviewChartItem() {
    const previewChartItem = this.state.get('previewChartItem');
    if (previewChartItem) {
      this.removeColor(previewChartItem.color);
      this.state.merge({
        previewChartItem: undefined,
        previewSeriesDefinition: undefined,
      });
    }
  }

  /**
   * Handles removing all capsuleSeries.
   */
  private removeAllCapsuleSeries() {
    this.removeItems({ items: this.getCapsuleSeries() });
  }

  /**
   * Returns all series that are converted from a capsule
   */
  private getCapsuleSeries() {
    const hideUnselectedItems = sqTrendStore.hideUnselectedItems;
    const referenceInterestIds = getSeriesIdsByCalculationType(TREND_TOOLS.REFERENCE, this);

    let capsuleStoreItems = sqTrendCapsuleStore.items;
    const someCapsulesSelected = _.some(capsuleStoreItems, ['selected', true]);
    const longestCapsuleFromStore: any = _.maxBy(capsuleStoreItems, 'duration');
    if (someCapsulesSelected) {
      capsuleStoreItems = _.filter(capsuleStoreItems, ['selected', true]);

      // When we aren't dimming, we want to make sure to keep the longest overall capsule so that when we filter out
      // the reference profiles, we show the longest slightly transparent and the selected one with full opacity
      if (!hideUnselectedItems) {
        capsuleStoreItems.push(longestCapsuleFromStore);
      }
    }

    const capsuleIds = _.map(capsuleStoreItems, 'id');

    const [referenceCapsuleSeries, nonReferenceCapsuleSeries] = _.chain(this.state.get('items'))
      .filter((item) => item.isChildOf && item.childType === ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE)
      .uniqBy('id')
      .partition((item) => _.includes(referenceInterestIds, item.isChildOf))
      .value();

    const filteredAndGroupedReferenceCapsuleSeries = _.chain(referenceCapsuleSeries)
      .filter((item) => _.includes(capsuleIds, item.capsuleId))
      .groupBy('idChildOf')
      .map((group) => _.groupBy(group, 'id'))
      .value();

    const referenceCapsuleSeriesToKeep = [];

    _.forEach(filteredAndGroupedReferenceCapsuleSeries, (referenceGroup) =>
      _.forEach(referenceGroup, (capsuleGroup) => {
        // If our longest capsule isn't selected and we aren't in dimming mode, remove it and keep it so
        // below we can figure out the longest of the selected capsules only
        if (someCapsulesSelected && !hideUnselectedItems && !longestCapsuleFromStore.selected) {
          const longestOverall = _.remove(capsuleGroup, (series) => series.capsuleId === longestCapsuleFromStore.id);
          if (longestOverall.length > 0) {
            referenceCapsuleSeriesToKeep.push(longestOverall[0]);
          }
        }
        const max = _.maxBy(capsuleGroup, (series) => series.duration || series.endTime - series.startTime);
        if (max) {
          referenceCapsuleSeriesToKeep.push(max);
        }
      }),
    );

    return _.concat(nonReferenceCapsuleSeries, referenceCapsuleSeriesToKeep);
  }

  /**
   * Returns all series that are not converted from capsules
   */
  private getNonCapsuleSeries() {
    return _.filter(
      this.state.get('items'),
      (item) => !item.isChildOf || item.childType !== ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE,
    );
  }

  private getAlternateEndTime() {
    const displayRangeEnd = sqDurationStore.displayRange.end.valueOf();
    const now = Date.now();

    return now < displayRangeEnd ? now : displayRangeEnd;
  }

  /**
   * This function manages the correct inclusion of preview series. If a new Search is performed then the
   * resulting preview series are simply appended. If an existing search is edited then the original series are
   * omitted and replaced with the preview series.
   */
  private addPreview(items: any[]) {
    let allSeries: any[];
    const series = _.clone(items);
    const editingId = this.state.get('editingId');
    const previewSeriesDef = this.state.get('previewSeriesDefinition');
    const displayPreview = !_.isEmpty(previewSeriesDef);

    if (!_.isUndefined(editingId) && !_.isNull(editingId) && displayPreview) {
      const index = _.findIndex(series, { id: editingId });
      series.splice(index, 1, this.state.get('previewChartItem'));
      allSeries = series;
    } else if (displayPreview) {
      allSeries = series.concat(this.state.get('previewChartItem'));
    } else {
      allSeries = series;
    }

    allSeries = _.chain(allSeries).flatten().compact().value();

    return allSeries;
  }

  /**
   * Overrides the behavior of TREND_SET_COLOR to take zones and series from capsule into account when changing color
   *
   * Called when the color for an item changes, should set the color on the item as necessary
   *
   * @param {Object} item - serialized item object
   * @param {Object} color - color that should be set
   * @param {string} [parentId] - what was the id of the parent that triggered the change
   */
  protected onSetColor(item: any, color: any, parentId?: string) {
    if (parentId && item.childType === ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE && parentId !== item.interestId) {
      return; // Colors for signal from capsule come from the signal, not the condition
    }

    const cursor = this.getItemCursor(item.id);
    cursor.set('color', color);

    _.forEach(item.zones, (zone, index) => {
      if (_.has(zone, 'color')) {
        cursor.set(['zones', index, 'color'], color);
      }
    });
  }

  /**
   * Overrides the behavior of TREND_SET_SELECTED so that selection from capsules determine the selection of
   * series from capsules
   *
   * Called when the selection for an item change, should set the color on the item as necessary
   *
   * @param {Object} item - serialized item object
   * @param {Object} selected - selection status
   * @param {string} [parentId] - what was the id of the parent that triggered the change
   */
  protected onSetSelected(item: any, selected: any, parentId?: string) {
    if (parentId && item.childType === ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE && parentId !== item.capsuleId) {
      return; // Selection status for series from capsule come from the capsule, not the condition
    }

    this.state.select('items', this.findItemIndex(item.id)).set('selected', selected);
    if (this.isPreviewItem(item.id)) {
      this.state.select('previewChartItem').set('selected', selected);
    }
  }

  /**
   * This changes the behavior of TREND_SET_CUSTOMIZATIONS to handle changes to the sample display
   *
   * This function ensures that the data property is updated as needed (if signals are displayed
   * as bar charts their data is "prepared" differently than if the signal is visualized as a
   * line - different "compression" and optimizations are applied).
   *
   * Called when the customizations for an item change, should set the customizations on the item as necessary
   *
   * @param {Object} item - serialized item object
   * @param {Object} customizations - customize properties being set
   * @param {String} customizations.sampleDisplayOption - one of SAMPLE_OPTIONS
   * @param {string} [parentId] - what was the id of the parent that triggered the change
   */
  protected onSetCustomizations(item: any, customizations: any, parentId?: string) {
    const cursor = this.getItemCursor(item.id);
    cursor.merge(customizations);

    if (customizations.sampleDisplayOption) {
      const hasBounds = !_.isNil(item.shadedAreaLower) && !_.isNil(item.shadedAreaUpper);
      cursor.merge(
        transformDataPoints({
          rawSamples: item.samples,
          color: item.color,
          minTime: getMinTime(cursor),
          sampleDisplayOption: customizations.sampleDisplayOption,
          hasBounds,
          stringEnum: item.stringEnum,
        }),
      );
    }
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @returns {Object} The dehydrated items.
   */
  dehydrate() {
    const extraPropsToDehydrate = ['displayedAncillaryItemIds', 'interpolationMethod'];

    return {
      items: _.chain(this.state.get('items'))
        .filter(this.shouldDehydrateItem)
        .map((item) => this.pruneDehydratedItemProps(item, extraPropsToDehydrate))
        .value(),
      editingId: this.state.get('editingId'),
      previewSeriesDefinition: this.state.get('previewSeriesDefinition'),
    };
  }

  /**
   * Re-creates the series. Calls the action to rehydrate the data points.
   *
   * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
   *
   * @return {Object} A promise that is fulfilled when items are completely rehydrated.
   */
  rehydrate(dehydratedState) {
    this.state.set(
      'items',
      _.map(dehydratedState.items, (item) =>
        this.createSeries(
          item.id,
          item.name,
          item.lane,
          item.alignment,
          _.omit(item, ['id', 'name', 'lane', 'alignment']),
        ),
      ),
    );

    this.state.set('editingId', dehydratedState.editingId);
    this.state.set('previewSeriesDefinition', dehydratedState.previewSeriesDefinition);
  }
}
