import React, { useState } from 'react';
import { sqWorkbooksApi } from '@/sdk';
import { FakeLink } from '@/core/FakeLink';
import { goToWorksheet } from '@/valueCapture/valueCapture.utilities';
import { useAsyncEffect } from 'rooks';

interface SelectedWorksheetDisplayAndLinkProps {
  worksheetId: string;
  workbookId: string;
}
export const SelectedWorksheetDisplayAndLink: React.FunctionComponent<SelectedWorksheetDisplayAndLinkProps> = ({
  workbookId,
  worksheetId,
}) => {
  const [selectedWorksheetDisplayName, setSelectedWorksheetDisplayName] = useState('');

  useAsyncEffect(async () => {
    if (!workbookId || !worksheetId) return;
    const workbookResponse = await sqWorkbooksApi.getWorkbook({ id: workbookId });
    const worksheetResponse = await sqWorkbooksApi.getWorksheet({ workbookId, worksheetId });
    setSelectedWorksheetDisplayName(`${workbookResponse.data?.name} - ${worksheetResponse.data?.name}`);
  }, [worksheetId, workbookId]);

  return (
    <FakeLink onClick={() => goToWorksheet(worksheetId)} extraClassNames="truncateWSLink">
      {selectedWorksheetDisplayName}
    </FakeLink>
  );
};
