import tinygradient from 'tinygradient';

/**
 * Creates a gradient of colors between the from and to colors, with [count] colors.
 * @param gradientColors
 * @param count
 */
export function getGradientColors(gradientColors: { from: string; to: string } | undefined, count: number): string[] {
  if (gradientColors === undefined || count === 0) {
    return [];
  } else if (count === 1) {
    return [gradientColors.to];
  } else {
    return tinygradient(gradientColors.from, gradientColors.to)
      .rgb(count)
      .map((color) => color.toHexString().toUpperCase());
  }
}
