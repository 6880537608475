import _ from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@seeqdev/qomponents';
import { SearchWidget, SearchWidgetSharedProps } from '@/search/SearchWidget.organism';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { isAsset } from '@/utilities/utilities';
import { onEventPreventPropagation } from '@/core/onEnterKeypress.util';
import { SelectedSearchItem } from '@/search/search.types';

export interface SelectItemSearchWidgetProps extends SearchWidgetSharedProps {
  onSelect: (item: SelectedSearchItem) => void;
  iconPartialTooltipKey: string;
  selectedItems?: SelectedSearchItem[];
  validateItem: (item: SelectedSearchItem) => Promise<boolean>;
  modalId?: string;
  isMultiple?: boolean;
  showRecentlyAccessed?: boolean;
}

export const SelectItemSearchWidget: React.FunctionComponent<SelectItemSearchWidgetProps> = ({
  onSelect,
  iconPartialTooltipKey,
  selectedItems,
  validateItem,
  scopeIds,
  excludeGloballyScoped = false,
  modalId,
  searchTypes,
  isMultiple = false,
  selectAllCallback = undefined,
  areAllSelected = () => false,
  showPinnedAssets = false,
  showOnlyScopedAssets = false,
  showOnlyConditions = false,
  showSwitchToLocallyScoped = false,
  onSwitchToLocallyScoped = _.noop,
  showRecentlyAccessed = false,
  overrideWorkbookId = undefined,
  filterByWorkbook,
}) => {
  const { t } = useTranslation();

  const isSelectingAssets = searchTypes.includes(SeeqNames.Types.Asset);

  const searchResultIcons = useCallback(
    async (item: SelectedSearchItem): Promise<React.ReactNode> => {
      const onlySearchingAssets = isSelectingAssets && searchTypes.length === 1;
      if (!isAsset(item) && onlySearchingAssets) {
        return Promise.resolve(null);
      }

      const isValid = await validateItem(item);
      const isSelected = selectedItems?.some((selectedItem) => selectedItem.id === item.id);

      return isValid ? (
        <Checkbox
          type={isMultiple ? 'checkbox' : 'radio'}
          testId="itemSelect"
          tooltip={t(`${iconPartialTooltipKey}.ASSET_TOOLTIP`)}
          tooltipPlacement="top"
          checked={isSelected}
          onClick={onEventPreventPropagation(() => onSelect(item as SelectedSearchItem))}
        />
      ) : (
        <Checkbox
          type={isMultiple ? 'checkbox' : 'radio'}
          testId="itemSelectInvalid"
          extraClassNames="disabled mr3 p3"
          tooltip={t(`${iconPartialTooltipKey}.ASSET_TOOLTIP_INVALID`)}
          onClick={onEventPreventPropagation(_.noop)}
          disabled={true}
        />
      );
    },
    [selectedItems, validateItem, iconPartialTooltipKey, onSelect],
  );

  return (
    <SearchWidget
      isSelectingAsset={isSelectingAssets}
      iconClasses="sq-fairly-dark-gray"
      pane="modal"
      searchTypes={searchTypes}
      searchResultIcons={searchResultIcons}
      allowAssetReplacement={false}
      showOnlyResults={
        !showRecentlyAccessed && !(searchTypes.includes(SeeqNames.Types.Asset) && searchTypes.length > 2)
      }
      showPinnedAssets={showPinnedAssets}
      scopeIds={scopeIds}
      excludeGloballyScoped={excludeGloballyScoped}
      modalId={modalId}
      predefinedSearchTypes={searchTypes}
      onSelectItem={onSelect}
      selectAllCallback={selectAllCallback}
      areAllSelected={areAllSelected}
      showOnlyScopedAssets={showOnlyScopedAssets}
      showOnlyConditions={showOnlyConditions}
      showSwitchToLocallyScoped={showSwitchToLocallyScoped}
      onSwitchToLocallyScoped={onSwitchToLocallyScoped}
      overrideWorkbookId={overrideWorkbookId}
      filterByWorkbook={filterByWorkbook}
    />
  );
};
