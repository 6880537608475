import _ from 'lodash';
import { flux } from '@/core/flux.module';
import { DocumentLayout, DocumentMarginUnits, DocumentPaperSize, Margin } from '@/reportEditor/report.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { formatMessage } from '@/utilities/logger.utilities';
import { sqItemsApi } from '@/sdk';
import { errorToast } from '@/utilities/toast.utilities';

export function setShowModal(showModal: boolean) {
  flux.dispatch('PDF_EXPORT_SET_SHOW_MODAL', { showModal });
}

export async function setPageLayout(layout: DocumentLayout, itemId: string | undefined) {
  await updatePDFSettingAndDispatch('PDF_EXPORT_SET_LAYOUT', SeeqNames.Properties.Orientation, layout, itemId);
}

export async function setPaperSize(paperSize: DocumentPaperSize, itemId: string | undefined) {
  await updatePDFSettingAndDispatch('PDF_EXPORT_SET_PAPER_SIZE', SeeqNames.Properties.PageSize, paperSize, itemId);
}

export async function setTopMargin(value: number | string, unit: DocumentMarginUnits, itemId: string | undefined) {
  await updatePDFSettingAndDispatch(
    'PDF_EXPORT_SET_MARGIN_TOP',
    SeeqNames.Properties.MarginTop,
    {
      value,
      units: unit,
    } as Margin,
    itemId,
  );
}

export async function setBottomMargin(value: number | string, unit: DocumentMarginUnits, itemId: string | undefined) {
  await updatePDFSettingAndDispatch(
    'PDF_EXPORT_SET_MARGIN_BOTTOM',
    SeeqNames.Properties.MarginBottom,
    {
      value,
      units: unit,
    } as Margin,
    itemId,
  );
}

export async function setLeftMargin(value: number | string, unit: DocumentMarginUnits, itemId: string | undefined) {
  await updatePDFSettingAndDispatch(
    'PDF_EXPORT_SET_MARGIN_LEFT',
    SeeqNames.Properties.MarginLeft,
    {
      value,
      units: unit,
    } as Margin,
    itemId,
  );
}

export async function setRightMargin(value: number | string, unit: DocumentMarginUnits, itemId: string | undefined) {
  await updatePDFSettingAndDispatch(
    'PDF_EXPORT_SET_MARGIN_RIGHT',
    SeeqNames.Properties.MarginRight,
    {
      value,
      units: unit,
    } as Margin,
    itemId,
  );
}

function validatePageMargin(value: number | string, unit: DocumentMarginUnits) {
  const num = _.isString(value) ? parseFloat(value) : value;
  if (!_.isFinite(num) || num < 0) {
    throw new TypeError(formatMessage`'${value}' is not a valid margin value`);
  }
  const margin = value + unit;
  return {
    stringValue: margin,
    margin: { value: num, units: unit } as Margin,
  };
}

function isMargin(potentialMargin: Margin | DocumentPaperSize | DocumentLayout): potentialMargin is Margin {
  return !_.isUndefined((potentialMargin as Margin).units);
}

async function updatePDFSettingAndDispatch(
  dispatchEvent: string,
  propertyName: string,
  pageSetting: Margin | DocumentPaperSize | DocumentLayout,
  itemId: string | undefined,
) {
  let dispatchValue: Margin | DocumentPaperSize | DocumentLayout = pageSetting;
  let propertyValue: string;

  if (isMargin(pageSetting)) {
    const { stringValue, margin } = validatePageMargin(pageSetting.value, pageSetting.units);
    dispatchValue = margin;
    propertyValue = stringValue;
  } else {
    propertyValue = pageSetting.value;
  }
  if (itemId) {
    try {
      await sqItemsApi.setProperty({ value: propertyValue }, { id: itemId, propertyName }, { ignoreLoadingBar: true });
    } catch (e) {
      errorToast({ httpResponseOrError: e });
    }
  }
  flux.dispatch(dispatchEvent, dispatchValue);
}

/** this function is used to set the layout to Landscape for Dashboards
 * It does NOT trigger a PDF creation request and is done every time a Dashboard is opened.
 */
export function setDefaultPageLayout(layout: DocumentLayout) {
  flux.dispatch('PDF_EXPORT_SET_LAYOUT', layout);
}
