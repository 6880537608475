import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { setOpeningAndLoadingItemId } from '@/workbench/workbench.actions';
import { formatTime, TIME_FORMAT_NUMERICAL_SHORT } from '@/datetime/dateTime.utilities';
import { WorksheetThumbnail } from '@/worksheets/WorksheetThumbnail.atom';
import { getWorkbook } from '@/utilities/homescreen.utilities';
import { sqWorksheetStore } from '@/core/core.stores';
import { StoreWorkbookWithWorksheets } from '@/worksheet/worksheet.types';
import { useDidMount } from 'rooks';

interface SelectWorksheetProps {
  workbookId: string;
  worksheetIds: string[];
  workstepIds: string[];
  setWorksheetIds: (ids: string[]) => void;
  setWorkstepIds: (ids: string[]) => void;
  setTotalWorksheets: (totalWorksheets: number) => void;
  isLoading?: boolean;
}

export const SelectWorksheet: React.FunctionComponent<SelectWorksheetProps> = (props) => {
  const {
    isLoading = false,
    workbookId,
    worksheetIds,
    workstepIds,
    setWorksheetIds,
    setWorkstepIds,
    setTotalWorksheets,
  } = props;

  const [worksheets, setWorksheets] = useState<JSX.Element[]>([]);
  const [workbook, setWorkbook] = useState<StoreWorkbookWithWorksheets | undefined>(undefined);

  const setSelectedWorksheets = useCallback(
    (worksheetId: string, workstepId: string, totalWorksheets: number) => {
      _.includes(worksheetIds, worksheetId)
        ? setWorksheetIds(_.without(worksheetIds, worksheetId))
        : setWorksheetIds(worksheetIds.concat(worksheetId));
      _.includes(workstepIds, workstepId)
        ? setWorkstepIds(_.without(workstepIds, workstepId))
        : setWorkstepIds(workstepIds.concat(workstepId));
      setTotalWorksheets(totalWorksheets);
    },
    [setTotalWorksheets, setWorksheetIds, setWorkstepIds, worksheetIds, workstepIds],
  );

  useDidMount(() => {
    getWorkbook(workbookId, { includeWorkstepId: true })
      .then((workbook) => {
        if ((_.isEmpty(worksheetIds) || _.first(worksheetIds) === undefined) && workbook?.worksheets?.length === 1) {
          const worksheet = _.first(workbook.worksheets) as any;
          setSelectedWorksheets(worksheet.worksheetId, worksheet.workstepId, 1);
        }
        setWorkbook(workbook);
      })
      .finally(() => setOpeningAndLoadingItemId(null));
  });

  useEffect(
    () =>
      workbook &&
      setWorksheets(
        _.map(workbook.worksheets, (worksheet) => (
          <div
            data-testid={`${worksheet.worksheetId}_selectWorksheet`}
            key={worksheet.worksheetId}
            className={classNames(
              'worksheetContainer cursorPointer text-center mr15 mb15',
              _.includes(worksheetIds, worksheet.worksheetId) ? 'active-worksheet' : '',
            )}
            onClick={() =>
              setSelectedWorksheets(
                worksheet.worksheetId as string,
                worksheet.workstepId as string,
                workbook.worksheets.length,
              )
            }
            id={worksheet.worksheetId}>
            <WorksheetThumbnail
              workbookId={workbookId}
              worksheetId={worksheet.worksheetId as string}
              isActive={false}
              updatedAt={undefined}
            />
            <div className="mt5">
              {`${worksheet.name} `}
              {formatTime(worksheet.updatedAt as number, sqWorksheetStore.timezone, TIME_FORMAT_NUMERICAL_SHORT)}
            </div>
          </div>
        )),
      ),
    [setSelectedWorksheets, workbook, workbookId, worksheetIds, workstepIds],
  );

  return (
    <div className="flexRowContainer" data-testid="report-content-worksheet">
      <div className="report-worksheets-list flexColumnContainer flexFill flexWrap flexCenter">
        {_.isEmpty(worksheets) || isLoading ? (
          <Icon
            icon="fa-spinner"
            large={true}
            iconPrefix="fa-solid"
            extraClassNames="sq-fairly-dark-gray fa-spin-pulse"
          />
        ) : (
          worksheets
        )}
      </div>
    </div>
  );
};
