import React from 'react';
import { Button, Icon, Modal } from '@seeqdev/qomponents';
import { VALUE_CAPTURE_CATEGORY_PREFIX, ValueCaptureCategory } from '@/valueCapture/valueCapture.types';
import {
  deleteCategory,
  getCustomCategoryTitle,
  initializeValueCaptureCategories,
} from '@/valueCapture/valueCapture.utilities';
import { FormElement } from '@/formbuilder/formBuilder.constants';
import { SimpleSaveFormBuilder } from '@/formbuilder/SimpleSaveFormBuilder.page';
import { sqContextApi } from '@/sdk';
import { useTranslation } from 'react-i18next';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import { randomInt } from '@/utilities/utilities';

interface ManageCustomCategoriesModalProps {
  categories: ValueCaptureCategory[] | undefined;
}

interface AddEditCategoryProps {
  category: ValueCaptureCategory | undefined;
  onClose: () => void;
}
export const AddEditCategory: React.FunctionComponent<AddEditCategoryProps> = ({ category, onClose }) => {
  const [options, setOptions] = React.useState(category?.options || []);
  const [deletedOptions, setDeletedOptions] = React.useState<string[]>([]);
  const [newOptionValue, setNewOptionValue] = React.useState('');
  const [categoryName, setCategoryName] = React.useState(category ? getCustomCategoryTitle(category) : '');
  const { t } = useTranslation();

  const getOptionFields = (): FormElement[] => {
    return options
      ? options.map((option) => ({
          component: 'FormRow',
          name: `optionRow${option.value}`,
          extraClassNames: 'flexAlignCenter',
          components: [
            {
              component: 'TextFieldFormComponent',
              label: '',
              name: option.value,
              value: option.label,
              size: 'sm',
              wrapperClassNames: 'width-maximum',
              onChange: (newLabelValue) => {
                const index = options.findIndex((opts) => option.value === opts.value);
                if (index > -1) {
                  const toUpdate = { ...options[index] };
                  toUpdate.label = newLabelValue as string;
                  setOptions(options.map((opts, i) => (i === index ? toUpdate : opts)));
                }
              },
              required: true,
            },
            {
              component: 'DisplayOnlyFormElementWrapper',
              name: `delete${option.value}`,
              children: (
                <div className="ml10 mb2">
                  <Button
                    icon="fa-close"
                    onClick={() => {
                      setOptions(options.filter((opts) => option.value !== opts.value));
                      setDeletedOptions([...deletedOptions, option.value]);
                    }}
                    variant="outline"
                    extraClassNames="mr20"
                  />
                </div>
              ),
            },
          ],
        }))
      : [];
  };

  const formDefinition: FormElement[] = [
    {
      component: 'TextFieldFormComponent',
      label: 'Category Name',
      name: 'categoryName',
      value: categoryName,
      size: 'sm',
      onChange: (newValue) => {
        setCategoryName(newValue as string);
      },
      required: true,
    },
    {
      component: 'LabelFormComponent',
      name: 'optionLabel',
      value: 'Options',
    },
    ...getOptionFields(),
    {
      component: 'FormRow',
      name: 'addNewOptionRow',
      extraClassNames: 'flexColumnContainer flexAlignCenter',
      components: [
        {
          component: 'TextFieldFormComponent',
          label: '',
          name: `addNewOption`,
          wrapperClassNames: 'width-maximum',
          value: newOptionValue,
          size: 'sm',
          onChange: (value) => {
            setNewOptionValue(value as string);
          },
        },
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: `addOption`,
          children: (
            <div className="ml10 mb2">
              <Button
                icon="fa-plus"
                onClick={() => {
                  setOptions([
                    ...options,
                    {
                      value: `new_${randomInt()}`,
                      label: newOptionValue,
                    },
                  ]);
                  setNewOptionValue('');
                }}
                variant="outline"
                extraClassNames="mr20"
              />
            </div>
          ),
        },
      ],
    },
  ];

  const updateCustomCategory = async () => {
    let categoryId = category?.category?.id;

    if (category && categoryId) {
      if (categoryName !== getCustomCategoryTitle(category)) {
        try {
          await sqContextApi.updateLabelCategory(
            { name: VALUE_CAPTURE_CATEGORY_PREFIX + categoryName },
            { categoryId },
          );
        } catch (e) {
          errorToast({ messageKey: 'Failed to update category name' });
        }
      }
    } else {
      // ADD new category
      try {
        const { data } = await sqContextApi.createLabelCategory({
          name: VALUE_CAPTURE_CATEGORY_PREFIX + categoryName,
        });
        categoryId = data.id;
      } catch (e) {
        errorToast({ messageKey: 'Failed to create category' });
      }
    }

    if (category) {
      // update existing label with new text
      await Promise.all(
        options.map(async (option) => {
          if (option.value.startsWith('new_')) return;
          if (!category.options?.find((opt) => opt.label === option.label)) {
            try {
              await sqContextApi.updateLabel({ name: option.label, categoryId }, { labelId: option.value });
            } catch (e) {
              console.error(e);
            }
          }
        }),
      );
      // delete options
      await Promise.all(
        deletedOptions.map(async (option) => {
          try {
            await sqContextApi.deleteLabel({ labelId: option });
          } catch (e) {
            errorToast({ messageKey: 'Failed to delete option' });
          }
        }),
      );
    }

    // add new options:
    const newOptions = options.filter((opts) => opts.value.startsWith('new_'));

    await Promise.all(
      newOptions.map(async (option) => {
        try {
          await sqContextApi.createLabel({ name: option.label, categoryId });
        } catch (e) {
          errorToast({ messageKey: 'Failed to delete option' });
        }
      }),
    );

    await initializeValueCaptureCategories();
    onClose();
    successToast({ messageKey: 'Category Updated' });
  };

  return (
    <SimpleSaveFormBuilder
      formDefinition={formDefinition}
      submitFn={updateCustomCategory}
      cancelBtnLabel={t('BACK')}
      closeFn={onClose}
    />
  );
};

export const ManageCustomCategoriesModal: React.FunctionComponent<ManageCustomCategoriesModalProps> = ({
  categories,
}) => {
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = React.useState(false);
  const [editingCategory, setEditingCategory] = React.useState<ValueCaptureCategory | undefined>(undefined);

  return (
    <>
      <Icon icon="fa-cog" onClick={() => setDisplayModal(true)} type="theme" extraClassNames="cursorPointer" />
      {displayModal && (
        <Modal
          hideFooterButtons={true}
          open={true}
          size="2xl"
          onClose={() => {
            setEditingCategory(undefined);
            setDisplayModal(false);
          }}
          title={t('VALUE_CAPTURE.MANAGE_CUSTOM_CATEGORIES')}>
          <div className="valueCapture valueCaptureModalHeight">
            {!editingCategory ? (
              <div className="flexRowContainer">
                <div className="flexColumnContainer flexJustifyEnd">
                  <Button
                    icon="fa-plus"
                    onClick={() => {
                      setEditingCategory({
                        category: {},
                        options: [],
                      });
                    }}
                    variant="theme"
                    label={t('VALUE_CAPTURE.ADD_CATEGORY')}
                  />
                </div>
                <hr />

                <table className="table table-condensed table-striped">
                  <thead>
                    <tr>
                      <th>{t('VALUE_CAPTURE.MANAGE_CUSTOM_CATEGORY')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories?.map((category) => (
                      <tr key={category.category.id}>
                        <td>{getCustomCategoryTitle(category)}</td>
                        <td className="textAlignRight">
                          <Button
                            icon="fa-close"
                            onClick={async () => await deleteCategory(category)}
                            variant="outline"
                            label={t('DELETE')}
                            extraClassNames="mr10"
                          />
                          <Button
                            icon="fa-pencil"
                            onClick={() => {
                              setEditingCategory(category);
                            }}
                            variant="outline"
                            label={t('EDIT')}
                            extraClassNames="mr10"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <AddEditCategory
                category={editingCategory}
                onClose={() => {
                  setEditingCategory(undefined);
                }}
              />
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
