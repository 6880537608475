import { SeeqNames } from '@/main/app.constants.seeqnames';

export const MAX_POSTGRES_EPOCH_MILLISEC = 9223372036854; // Math.floor(9223372036854775807 / 1e6)

export const EVIDENCE_LIMIT = 80000; // Picked based on when the app started getting sluggish doing aggregation
export const EVIDENCE_CONTEXT_LIMIT = EVIDENCE_LIMIT * 10; // Each row can have several pieces of context

export const VantageNames = {
  Columns: {
    Asset: 'Asset',
    AssetUUID: 'Asset UUID',
    CapsuleID: 'Capsule ID',
    Condition: 'Condition',
    DatumID: 'Datum ID',
    DeletedAt: 'Deleted at',
    Duration: 'Duration',
    End: 'End',
    Flagged: 'Flagged',
    Formula: 'Formula',
    Kind: 'Kind',
    Labels: 'Labels',
    Mutable: 'Mutable',
    Path: 'Path',
    Properties: 'Properties',
    Reviewed: 'Reviewed',
    Start: 'Start',
    StreamID: 'Stream ID',
    Suppressed: 'Suppressed',
    UpdatedAt: 'Updated At',
    IsUncertain: SeeqNames.CapsuleProperties.IsUncertain,
    LastCapsuleState: 'Last Capsule State',
  },
  ContextColumns: {
    ContextCommentsComment: 'context_comments.comment',
    ContextCommentsId: 'context_comments.id',
    ContextLabelsCategoryId: 'context_labels.category_id',
    ContextLabelsEndTime: 'context_labels.end_time',
    ContextLabelsId: 'context_labels.id',
    ContextLabelsLabelName: 'context_labels.label_name',
    ContextLabelsStartTime: 'context_labels.start_time',
    ContextOpaqueArchived: 'context_opaque.archived',
    ContextOpaqueCreatedAt: 'context_opaque.created_at',
    ContextOpaqueData: 'context_opaque.data',
    ContextOpaqueEndTime: 'context_opaque.end_time',
    ContextOpaqueId: 'context_opaque.id',
    ContextOpaqueKey: 'context_opaque.key',
    ContextOpaqueStartTime: 'context_opaque.start_time',
    ContextOpaqueUpdatedAt: 'context_opaque.updated_at',
  },
  DerivedColumns: {
    Extinct: 'Extinct',
  },
  Extensions: {
    Flag: 'flag',
    Labels: 'labels',
    ReasonCodes: 'reason_codes',
    Reviewed: 'reviewed',
    Suppression: 'suppression',
  },
  Kinds: {
    Asset: 'Asset',
    Capsule: 'Capsule',
    Condition: 'Condition',
  },
};

export const ColumnToDerivedColumn: Record<string, string> = {
  [VantageNames.Columns.LastCapsuleState]: VantageNames.DerivedColumns.Extinct,
};

export const GraphQL = {
  FilterTypes: {
    GreaterThan: 'GREATER_THAN',
    GreaterThanOrEqual: 'GREATER_THAN_OR_EQUAL',
    IsNotNull: 'IS_NOT_NULL',
    IsNull: 'IS_NULL',
    LessThan: 'LESS_THAN',
    LessThanOrEqual: 'LESS_THAN_OR_EQUAL',
  },
  Operations: {
    And: 'AND',
    Or: 'OR',
  },
};

export const configUneditableColumns = [
  SeeqNames.MaterializedTables.ItemIdColumn,
  SeeqNames.CapsuleProperties.ConditionId,
  SeeqNames.MaterializedTables.DatumIdColumn,
  ...Object.values(VantageNames.Columns).filter(
    (column) => column !== VantageNames.Columns.Asset && column !== VantageNames.Columns.Path,
  ),
];

export const configHiddenColumns = [
  SeeqNames.MaterializedTables.ItemIdColumn,
  SeeqNames.MaterializedTables.DatumIdColumn,
  SeeqNames.CapsuleProperties.ConditionId,
  VantageNames.Columns.AssetUUID,
  VantageNames.Columns.UpdatedAt,
  VantageNames.Columns.DeletedAt,
  VantageNames.Columns.Kind,
  VantageNames.Columns.Mutable,
  VantageNames.Columns.Properties,
  VantageNames.Columns.Suppressed,
];

export const PROTECTED_CATEGORIES = [
  VantageNames.Extensions.Flag,
  VantageNames.Extensions.Labels,
  VantageNames.Extensions.Reviewed,
  VantageNames.Extensions.Suppression,
];
