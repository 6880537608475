import { flux } from '@/core/flux.module';
import { AIPayload, Search } from '@/itemFinder/itemFinder.constants';
import { UpdateSearchPayload } from '@/itemFinder/itemFinder.store';

export function setName(name: string) {
  flux.dispatch('ITEM_FINDER_SET_NAME', { name });
}

export function setCronSchedule(cronSchedule: string[]) {
  flux.dispatch('ITEM_FINDER_SET_CRON_SCHEDULE', { cronSchedule });
}

export function addSearch() {
  flux.dispatch('ITEM_FINDER_ADD_SEARCH');
}

export function removeSearch(index: number) {
  flux.dispatch('ITEM_FINDER_REMOVE_SEARCH', index);
}

export function updateSearch(payload: UpdateSearchPayload) {
  flux.dispatch('ITEM_FINDER_UPDATE_SEARCH', payload);
}

export function resetItemFinder() {
  flux.dispatch('RESET_ITEM_FINDER', {});
}

export function setItemFinder({
  value: { name, searches, cronSchedule },
}: {
  value: { name: string; searches: Search[] | undefined; cronSchedule: string[] | undefined };
}) {
  flux.dispatch('SET_EXISTING_ITEM_FINDER', { value: { name, searches, cronSchedule } });
}

export function setPotentialSearchesPayload(payload: AIPayload) {
  if (payload.propertySearches) {
    payload.propertySearches.forEach((propertySearch) => {
      flux.dispatch('ITEM_FINDER_ADD_PROPERTY_SEARCH', propertySearch);
    });
  }
  if (payload.fixedListSearches) {
    payload.fixedListSearches.forEach((propertySearch) => {
      flux.dispatch('ITEM_FINDER_ADD_FIXED_LIST_SEARCH', propertySearch);
    });
  }
}
